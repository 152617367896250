/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./auctionReports.styles.scss";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import { SyncOutlined } from "@ant-design/icons";

import moment from "moment";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getAuctionReports } from "../../services/auction-services";

import {
  getHubsService,
  getSlotIdsService,
} from "../../services/common-services";

import { Table, message, Button, Spin, Progress, Alert } from "antd";
import { Link } from "react-router-dom";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectSelectedCrop,
  selectCrops,
  selectBranches,
  selectVarieties,
  selectCurrentPage,
  selectViewAuctionPage,
  selectSelectedBranch,
  selectHubs,
  selectSelectedMultiBranch,
} from "../../redux/common/common.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";

import Axios from "axios";
// import API from "../../api";
import { Dispatch } from "redux";
import { selectUserData } from "../../redux/user/user.selectors";
import { dataConstants } from "../../constants/data-constants";

import { Layout } from "antd";
import {
  setSelectedBranch,
  setSelectedMultiBranch,
} from "../../redux/common/common.actions";

const { Content, Footer } = Layout;
const FileDownLoad = require("js-file-download");

// const status = [
//   { name: "Accepted", id: 1 },
//   { name: "Rejected", id: 2 },
//   { name: "Not Processed", id: 3 },
// ];

const AuctionReport: React.FC = (props: any) => {
  const {
    branches,
    varietiesList,
    selectedCropId,
    // userData,
    crops,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setSelectedBranchIndex,
    hubs,
  } = props;
  // const [hubs, setHubs] = useState();
  // const [slots, setSlots] = useState();
  // const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id);
  const [selectedVariety, setSelectedVariety] = useState();
  const [selectedDates, setSelectedDates] = useState([
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [selectedHub, setSelectedHub] = useState();
  const [isTableVisible, setisTableVisible] = useState(true);
  // const [selectedSlot, setSelectedSlot] = useState();
  // const [selectedStatus, setSelectedStatus] = useState();
  const [auctionReportData, setAuctionReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedCropId, setselectedCropId] = useState<any>(selectedCropId);
  // const [varietiesList, setVarietiesData] = useState<any>([]);
  // const [isSpinning, setIsSpinning] = useState(false);
  const [isProgressClicked, setisProgressClicked] = useState(false);
  const [downloaddata, setdownloaddata] = useState<any>();
  const [isSpinnerOnProgress, setIsSpinnerOnProgress] = useState(false);
  const columns = [
    {
      title: "Auction ID",
      key: "auction_num",
      sorter: (a, b) => a.auctionNumber - b.auctionNumber,
      children: [
        {
          dataIndex: "auctionNumber",
          key: "auction_num2",
          width: 90,
        },
      ],
    },
    {
      title: "Auction Date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      children: [
        {
          dataIndex: "date",
          key: "date2",
          width: 90,
        },
      ],
    },
    {
      title: "Auction Time",
      sorter: (a, b) => a.time.localeCompare(b.time),
      children: [
        {
          dataIndex: "time",
          key: "time2",
          width: 140,
        },
      ],
    },
    {
      title: "Hub",
      sorter: (a, b) => a.hub.localeCompare(b.hub),
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          // align: "center",
          width: 110,
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          width: 90,
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          width: 50,
          render: (value: string) => {
            return (
              <div className="claim-container">
                {value === "Yes" ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Rebate ID",
      children: [
        {
          dataIndex: "rebate_name",
          key: "rebate_name",
          width: 110,
        },
      ],
    },
    {
      title: "Division",
      sorter: (a, b) => a.division.localeCompare(b.division),
      children: [
        {
          dataIndex: "division",
          key: "division2",
          width: 100,
          align: "center",
          render: (value: number) => {
            return <div>{value}</div>;
          },
        },
      ],
    },
    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudaType",
          key: "saudaType2",
          render: (value: string) => {
            return <div>{value}</div>;
          },
          width: 100,
        },
      ],
    },
    {
      title: "Auction Status",
      children: [
        {
          dataIndex: "auction_status",
          key: "auction_status",
          width: 100,
          render: (value: string) => {
            return (
              <div
                className={`sauda-status ${
                  value === "Accept" ? "green" : value === "Reject" ? "red" : ""
                }`}
              >
                {value}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "No Of Bids",
      key: "no_of_bids",
      children: [
        {
          dataIndex: "no_of_bids",
          key: "no_of_bids2",
          width: 60,
        },
      ],
    },
    {
      title: "Bidders With Qty Allocated",
      key: "bidders_with_qty_allocated",
      children: [
        {
          dataIndex: "bidders_with_qty_allocated",
          key: "bidders_with_qty_allocated2",
          width: 90,
        },
      ],
    },
    {
      title: "Max Price Entered",
      children: [
        {
          title: "MT",
          dataIndex: "maxPrice",
          width: 90,
          key: "maxPrice",
        },
      ],
    },
    {
      title: "Qty Required",
      children: [
        {
          title: "MT",
          dataIndex: "qtyReqd",
          width: 90,
          key: "qtyReqd2",
        },
      ],
    },
    {
      title: "First Bid Rate",
      children: [
        {
          title: "₹/MT",
          dataIndex: "firstBidRate",
          width: 90,
          key: "firstBidRate2",
        },
      ],
    },
    {
      title: "Lowest Rate",
      children: [
        {
          title: "₹/MT",
          dataIndex: "minPrice",
          key: "minPrice2",
          width: 90,
        },
      ],
    },
    {
      title: "W. Average Rate",
      children: [
        {
          title: "MT",
          dataIndex: "avg_price",
          width: 90,
          key: "avg_price",
        },
      ],
    },
    {
      title: "Qty Fulfilled",
      children: [
        {
          title: "MT",
          dataIndex: "qtyFulfilled",
          width: 90,
          key: "qtyFulfilled2",
        },
      ],
    },
  ];

  // useEffect(() => {
  //   setselectedCropId(selectedCropId);
  // }, [selectedCropId]);

  useEffect(() => {
    if (currentpage === "6" && viewAuctionPage === "60") {
      getAuctionReportData();
    }
    // onBranchChange(selectedBranch, []);
  }, [
    currentpage,
    viewAuctionPage,
    selectedVariety,
    selectedDates,
    selectedBranch,
    selectedCropId,
  ]);

  // useEffect(() => {
  //   onBranchChange(branches[branchIndex].id, []);
  // }, [branchIndex]);

  useEffect(() => {
    setSelectedVariety(undefined);
  }, [varietiesList]);

  const getAuctionReportData = async (hubId?: any) => {
    setIsLoading(true);
    setisTableVisible(true);
    const params = {
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      crop_id: selectedCropId,
      branch_id: selectedBranch,
      download: false,
      hub_id: hubId ? (hubId.length ? hubId : undefined) : selectedHub,
      variety_id: selectedVariety,
    };
    try {
      const response = await getAuctionReports(params);
      if (
        response.data.status &&
        response.data.data &&
        response.data.data.length
      ) {
        const data = response.data.data.map((item: any, index: number) => {
          return {
            key: index,
            auctionNumber: item.auction_id,
            date: item.auction_date,
            crop_id: selectedCropId,
            time: item.auction_time,
            branch: item.branch_name,
            hub: item.hub_name,
            variety: item.variety_name,
            claim: item.claim,
            division: item.division,
            saudaType: item.sauda_type,
            auction_status: item.auction_status,
            firstBidRate:
              item.first_bid_price === null ? 0 : item.first_bid_price,
            maxPrice: item.max_price,
            minPrice: item.min_bid_price === null ? 0 : item.min_bid_price,
            qtyReqd: item.qty_rqd,
            rebate_name: item.rebate_name,
            no_of_bids: item.no_of_bids === null ? 0 : item.no_of_bids,
            bidders_with_qty_allocated:
              item.bidders_with_qty_allocated === null
                ? 0
                : item.bidders_with_qty_allocated,
            avg_price: item.avg_price === null ? 0 : item.avg_price,
            qtyFulfilled:
              item.qty_fullfilled === null ? 0 : item.qty_fullfilled,
          };
        });
        setAuctionReportData(data);
      } else {
        setAuctionReportData([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.warning("Something went wrong. Try agin ..!");
      setAuctionReportData([]);
    }
  };

  // const onBranchChange = (value: string | number | any, option: any) => {
  //   setSelectedBranchIndex(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   setSelectedHub(undefined);
  //   setSelectedSlot(undefined);
  //   getSlotsFn(value);
  //   getHubsFn(value);
  //   let start = moment(selectedDates[0]);
  //   let end = moment(selectedDates[1]);
  //   let differenceInDays = end.diff(start, "days");

  //   if (differenceInDays > 14) {
  //     return setisTableVisible(false);
  //   }
  //   setisTableVisible(true);
  //   getAuctionReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     value,
  //     selectedVariety,
  //     selectedHub
  //   );
  // };

  // const onVarietyChange = (value: string | number | any, option: any) => {
  //   if (value === selectedVariety) {
  //     setSelectedVariety(undefined);
  //   } else setSelectedVariety(value);
  //   getAuctionReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     value === selectedVariety ? null : value,
  //     selectedHub
  //   );
  // };

  // const onHubChange = (value: string | number | any, option: any) => {
  //   if (value === selectedHub) {
  //     setSelectedHub(undefined);
  //   } else setSelectedHub(value);
  //   getAuctionReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     value === selectedHub ? null : value
  //   );
  // };

  // const onStatusChange = (value: string | number | any, option: any) => {
  //   if (value === selectedStatus) {
  //     setSelectedStatus(undefined);
  //   } else setSelectedStatus(value);
  //   getAuctionReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     selectedHub
  //   );
  // };
  const onDateChange = (value: string | number | any, timeString: any) => {
    setSelectedDates(timeString);

    let start = moment(timeString[0]);
    let end = moment(timeString[1]);
    let differenceInDays = end.diff(start, "days");

    if (differenceInDays > 14) {
      return setisTableVisible(false);
    }
    setisTableVisible(true);
  };

  // const getHubsFn = async (branchId: number) => {
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) {}
  // };
  // const getSlotsFn = async (branchId: number) => {
  //   try {
  //     const response = await getSlotIdsService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.slot_id, name: item.slot_name };
  //     });
  //     setSlots(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fileDownload = async () => {
    setIsSpinnerOnProgress(true);
    const body = {
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      branch_id: selectedBranch,
      hub_id: selectedHub,
      variety_id: selectedVariety,
      download: true,
      crop_id: selectedCropId,
    };

    // const fileresponse = await getAuctionReports(body);
    // console.log("file response ", fileresponse);
    // if (fileresponse) {
    //   if (fileresponse.status === 200) {
    //     console.log(
    //       "file response1 ",
    //       fileresponse.data,
    //     );
    //#region file download
    Axios({
      url: dataConstants.auctionReportsUrl,
      method: "POST",
      data: body,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setdownloaddata(progress);
      },
    })
      .then((response: any) => {
        FileDownLoad(
          response.data,
          `Auctionwise_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        message.success("File has been downloaded successfully!");
        setIsSpinnerOnProgress(false);
        setTimeout(
          function () {
            setisProgressClicked(false);
          }.bind(this),
          5000
        );
      })
      .catch((err: any) => {
        message.error("File cannot be downloaded");
        setIsSpinnerOnProgress(false);
        setisProgressClicked(false);
      });
    //#endregion
    // } else {
    //   message.warning("File cannot be downloaded");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
    // } else {
    //   message.error("Error downloading file");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
  };

  return (
    <Spin
      spinning={isSpinnerOnProgress}
      tip="Please wait while the file is bieng Downloaded..."
    >
      <div className="archives">
        <div className="filter-container">
          <CustomFilter
            type="range-picker"
            head="Date Range"
            startDate={moment()}
            endDate={moment()}
            onChange={onDateChange}
          />
          {/* <CustomFilter
          type="single-select"
          options={crops}
          placeholder="Select Crop"
          head="Crop"
        /> */}
          <CustomFilter
            type="multi-select"
            value={selectedBranch}
            options={branches}
            onChange={(value: any) => {
              setSelectedHub(undefined);
              setSelectedBranchIndex(value);
            }}
            placeholder="Select Branch"
            head="ITC Branch Office"
            returnId
          />
          <CustomFilter
            // type="single-select"
            type="multi-select"
            value={selectedHub}
            options={hubs}
            onChange={(value: any) => {
              setSelectedHub(value);
              getAuctionReportData(value);
            }}
            placeholder="Select Hub"
            head="Hub"
            returnId
          />
          <CustomFilter
            // type="single-select"
            type="multi-select"
            value={selectedVariety}
            onChange={(value: any) => setSelectedVariety(value)}
            options={varietiesList}
            placeholder="Select Variety"
            head="Variety"
            returnId
          />
          <div className="selected-wheat">
            <span className="selected-wheat-label">Selected Crop</span> <br />
            <span className="selected-wheat-value">
              <b>
                {crops &&
                  crops.length &&
                  crops.find(
                    (item: { id: number; name: string }) =>
                      item.id == selectedCropId
                  ).name}
              </b>
            </span>
          </div>
          {/* <CustomFilter
          type="single-select"
          value={selectedSlot}
          options={slots}
          onChange={onSlotChange}
          placeholder="Select Slot"
          head="Slot"
          returnId
        /> */}
          {/* <CustomFilter
          type="single-select"
          value={selectedStatus}
          options={status}
          onChange={onStatusChange}
          placeholder="Select Status"
          head="Status"
          returnId
        /> */}
          <Button
            className="refresh"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() => getAuctionReportData()}
          >
            Refresh
          </Button>
        </div>
        {isTableVisible ? (
          <div className="auction-report-table-container">
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={auctionReportData}
              pagination={false}
              scroll={{ y: window.innerHeight - 339 }}
              footer={() => {
                return auctionReportData?.length ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      alignItems: "baseline",
                      gap: "1em",
                      position: "fixed",
                      right: "5px",
                    }}
                  >
                    <div className="progress-download-container">
                      {isProgressClicked && (
                        <Progress
                          type="circle"
                          percent={downloaddata}
                          width={37}
                          strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                          style={{
                            right: "125px",
                            position: "absolute",
                            display: "flex",
                            marginTop: "-47px",
                          }}
                          trailColor="#003285"
                        />
                      )}
                    </div>
                    <div className="download-button-container">
                      {auctionReportData?.length && (
                        <Button
                          onClick={() => {
                            setdownloaddata(0);
                            setisProgressClicked(true);
                            fileDownload();
                          }}
                          type="primary"
                          shape="round"
                          // icon="download"
                          size="large"
                          // style={{ display: "block" }}
                          className="download-btn-styles"
                        >
                          <div style={{ marginTop: "-7px" }}>Download</div>
                          <div
                            style={{
                              fontSize: "11px",
                              lineHeight: "7px",
                              color: "#b4c8dc",
                            }}
                          >
                            .xls report
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                ) : null;
              }}
            />
          </div>
        ) : (
          <Alert
            style={{ padding: "10px", width: "50%", margin: "auto" }}
            message="Informational Notes"
            description="The data for the selected time period is too big. You can use the button at the bottom right to download the file"
            type="info"
            showIcon
          />
        )}
      </div>
    </Spin>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedMultiBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  branches: selectBranches,
  crops: selectCrops,
  varietiesList: selectVarieties,
  selectedCropId: selectSelectedCrop,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  selectedBranch: selectSelectedMultiBranch,
  hubs: selectHubs,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionReport);
