import { Reducer, Action } from "redux";

import { CommonActionTypes } from "./common.types";

import { Common } from "../../models";

const INITIAL_STATE: Common = {
  branches: [],
  varieties: [],
  bagtypeList: [],
  divisionList: [],
  crops: [],
  hubs: [],
  rebates: [],
  refetch: false,
  selectedCrop: null,
  selectedBranch: null,
  selectedMultiBranch: [],
  bypassBD: false,
  currentPage: "1",
  viewAuctionPage: "1",
  slotPage: "1",
  openAuctionBranch: 0,
  bypassEnabled: true,
  tc: ``,
  default_params: {
    auction_freeze_period: "",
    default_crop: "",
    inco_terms: "",
    max_qty_parameter: "",
    minimum_price_decrement: "",
    minimum_refund_amount: "",
    price_tick_size: "",
    registration_sap_required: "",
    trade_limit_validation: "",
  },
  crop_level_params: {
    minimum_price_decrement_percentage: "",
    caution_deposit_min_amount: "",
    avg_commodity_price: "",
    safety_margin: "",
  },
  branch_level_params: {
    cash_discount: "",
    minimum_quantity: "",
    auction_closing_duration: "",
    saudha_type: "",
    upcoming_auction_buffer_time: "",
    approver_code: "",
    brokerage: "",
  },
};

const commonReducer: Reducer<Common, Action> = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case CommonActionTypes.SET_REFETCH:
      return {
        ...state,
        refetch: action.payload,
      };
    case CommonActionTypes.SET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case CommonActionTypes.SET_VARIETIES:
      return {
        ...state,
        varieties: action.payload,
      };
    case CommonActionTypes.SET_CROPS:
      return {
        ...state,
        crops: action.payload,
      };
    case CommonActionTypes.SET_HUBS:
      return {
        ...state,
        hubs: action.payload,
      };
    case CommonActionTypes.SET_REBATES:
      return {
        ...state,
        rebates: action.payload,
      };
    case CommonActionTypes.SET_SELECTED_CROP:
      return {
        ...state,
        selectedCrop: action.payload,
      };
    case CommonActionTypes.SET_SELECTED_BRANCH:
      return {
        ...state,
        selectedBranch: action.payload,
      };
    case CommonActionTypes.SET_SELECTED_MULTIBRANCH:
      return {
        ...state,
        selectedMultiBranch: action.payload,
      };
    case CommonActionTypes.SET_BYPASS_BD:
      return {
        ...state,
        bypassBD: action.payload,
      };
    case CommonActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case CommonActionTypes.SET_VIEW_AUCTION_PAGE:
      return {
        ...state,
        viewAuctionPage: action.payload,
      };
    case CommonActionTypes.SET_SLOT_PAGE:
      return {
        ...state,
        slotPage: action.payload,
      };
    case CommonActionTypes.SET_OPEN_AUCTION_BRANCH:
      return {
        ...state,
        openAuctionBranch: action.payload,
      };
    case CommonActionTypes.SET_BYPASS_ENABLED:
      return {
        ...state,
        bypassEnabled: action.payload,
      };
    case CommonActionTypes.SET_TC:
      return {
        ...state,
        tc: action.payload,
      };
    case CommonActionTypes.SET_DEFAULT_PARAMS:
      return {
        ...state,
        default_params: action.payload,
      };
    case CommonActionTypes.SET_BRANCH_PARAMS:
      return {
        ...state,
        branch_level_params: action.payload,
      };
    case CommonActionTypes.SET_CROP_PARAMS:
      return {
        ...state,
        crop_level_params: action.payload,
      };
    case CommonActionTypes.SET_BAGTYPE:
      return {
        ...state,
        bagtypeList: action.payload,
      };
    case CommonActionTypes.SET_DIVISION:
      return {
        ...state,
        divisionList: action.payload,
      };
    case CommonActionTypes.RESET_REDUCER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default commonReducer;
