import React, { useState, useEffect, useMemo } from "react";
import "./slot-individual-item.styles.scss";

import moment from "moment";

import { getRebateMatrixService } from "../../services/common-services";

import { Input, Select, Modal, Table, DatePicker } from "antd";
const { Option } = Select;

interface IIndividualItem {
  index: number;
  cropId: number;
  slotId: number;
  slotNumber: number;
  needDate: number | any;
  maxPrice: number | any;
  branchId?: number;
  errorAtIndex?: number | any;
  minQtyShow?: boolean | any;
  sysParametersData?: number | any;
  purchasedQty?: number;
  requiredQty?: number;
  rebateId?: string;
  startTime?: string;
  auctionFreezePeriod?: string;
  endTime?: string;
  rebateList: Array<any>;
  bag_type?: number;
  material_code?: string;
  bagTypeList?: Array<any>;
  materialList?: Array<any>;
  claim?: number | null;
  onChangeFn?: (
    value: number | any,
    slotId: number,
    changedField: string
  ) => void;
}

const IndividualItem: React.FC<IIndividualItem> = (props: IIndividualItem) => {
  const {
    index,
    cropId,
    slotId,
    branchId,
    purchasedQty,
    sysParametersData,
    minQtyShow,
    errorAtIndex,
    requiredQty,
    rebateId,
    onChangeFn,
    startTime,
    auctionFreezePeriod,
    endTime,
    slotNumber,
    rebateList,
    bagTypeList,
    materialList,
    bag_type,
    material_code,
    claim,
    needDate,
    maxPrice,
  } = props;
  const columns = [
    {
      title: "Head",
      dataIndex: "head",
      key: "head",
      render(_: any, row: any) {
        return {
          children: row.head,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },
    {
      title: "Slab",
      dataIndex: "slab",
      key: "slab",
    },
    {
      title: "Rebate %",
      dataIndex: "rate_display",
      key: "rate_display",
    },
  ];

  const [selectedRebate, setSelectedRebate] = useState<string>(
    rebateId ? rebateId : ""
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>();
  const [currentTime, setCurrentTime] = useState<any>(new Date());

  useEffect(() => {}, [selectedRebate]);

  useEffect(() => {
    // console.log(
    //   "setCurrentTime -----------> ",
    //   moment(currentTime).format("HH:mm:ss"),
    //   auctionFreezePeriod,
    //   "startTime",
    //   startTime,
    //   moment(
    //     moment(currentTime, "HH:mm:ss").add(auctionFreezePeriod, "minutes")
    //   ).format("HH:mm:ss"),
    //   "Before---->",
    //   moment(
    //     moment(currentTime, "HH:mm:ss").add(auctionFreezePeriod, "minutes"),
    //     "HH:mm:ss"
    //   ).isBefore(moment(startTime, "HH:mm:ss")),
    //   "After--->",
    //   moment(
    //     moment(currentTime, "HH:mm:ss").add(auctionFreezePeriod, "minutes"),
    //     "HH:mm:ss"
    //   ).isAfter(moment(startTime, "HH:mm:ss"))
    // );
  }, []);

  const customPropsQty = {
    ...(requiredQty && { value: requiredQty }),
  };
  const customPropsRebate = {
    ...(rebateId && { value: rebateId === null ? undefined : rebateId }),
  };
  const customPropsMaterail = {
    ...(material_code && {
      value: material_code === null ? undefined : material_code,
    }),
  };
  const customPropsBagtype = {
    ...(bag_type && { value: bag_type === null ? undefined : bag_type }),
  };

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined,
    cropId: number
  ) => {
    console.log("REbate ID", rebateId);

    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: cropId,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setTableData(response.data.data);
        setModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };

  return (
    <div className="individual-slot-container">
      <Modal
        className="rebate-modal"
        visible={modalVisible}
        maskClosable={false}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={columns}
            // dataSource={tableData}
            dataSource={createNewArr(tableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>
      <div className="individual-slot">
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss"))) &&
            "disabled"
          }`}
        >
          <div className="label">
            <div style={{ display: "flex" }}>
              <div>Slot&nbsp;(MT)&nbsp;{index + 1}</div>
              <div style={{ color: "red" }}>*</div>
            </div>
          </div>
          <Input
            type="number"
            placeholder="Slot Qty"
            {...customPropsQty}
            onChange={(e) =>
              onChangeFn !== undefined &&
              onChangeFn(parseInt(e.target.value), slotId, "qty_rqd")
            }
          />
        </div>
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss")) ||
              claim === 2) &&
            "disabled"
          }`}
        >
          <div className="label help">
            <div style={{ display: "flex" }}>
              <div>Rebate&nbsp;Name</div>
              <div style={{ color: "red" }}>*</div>
            </div>
            {rebateId && (
              <div
                className="info"
                onClick={() => {
                  getRebateMatrix(
                    selectedRebate === "" ? rebateId : selectedRebate,
                    branchId,
                    cropId
                  );
                }}
              >
                ?
              </div>
            )}
          </div>
          <Select
            placeholder="Rebate Name"
            showSearch
            allowClear
            filterOption={(inputValue, option: any) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            disabled={
              purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              claim === 2
            }
            {...customPropsRebate}
            onChange={(value) => {
              if (onChangeFn !== undefined) {
                onChangeFn(value, slotId, "rebate_id");
                setSelectedRebate(value + "");
              }
            }}
            // defaultValue={}
          >
            {rebateList.map((item: any) => (
              <Option
                value={item.rebate_id}
                id={item.rebate_id}
                key={item.rebate_id}
              >
                {item.rebate_name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss"))) &&
            "disabled"
          }`}
        >
          <div className="label">
            Max&nbsp;Price&nbsp;(₹/MT)<label style={{ color: "red" }}>*</label>
          </div>
          <Input
            type="text"
            placeholder="Max Price"
            value={maxPrice === null ? null : maxPrice}
            onChange={(e) => {
              if (!Number.isNaN(e.target.value) && Number(e.target.value) > 0) {
                onChangeFn !== undefined &&
                  onChangeFn(parseInt(e.target.value), slotId, "max_price");
              } else {
                onChangeFn !== undefined &&
                  onChangeFn(parseInt("0"), slotId, "max_price");
              }
            }}
          />
        </div>
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss"))) &&
            "disabled"
          }`}
        >
          <div className="label">
            <div className="max-price">
              Need&nbsp;Date<label style={{ color: "red" }}>*</label>
            </div>
          </div>
          <DatePicker
            clearIcon={false}
            // onChange={(value, date) => {
            //   getParamsFnWithOutLoad(formData.branch_id, true, true);
            //   setFormData({ ...formData, need_date: date });
            // }}
            //onChange={onNeedDateChange}
            onChange={(value, date) =>
              onChangeFn !== undefined && onChangeFn(date, slotId, "need_date")
            }
            value={needDate !== null ? moment(needDate) : null}
            disabledDate={(d) =>
              !d || d.isSameOrBefore(moment().subtract(1, "days"))
            }
          />
        </div>
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss"))) &&
            "disabled"
          }`}
        >
          <div className="label help">
            <div style={{ display: "flex" }}>
              <div>Material Code</div>
              <div style={{ color: "red" }}>*</div>
            </div>
          </div>
          <Select
            placeholder="Material Code"
            showSearch
            allowClear
            filterOption={(inputValue, option: any) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            disabled={
              purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment())
            }
            {...customPropsMaterail}
            onChange={(value) => {
              if (onChangeFn !== undefined) {
                onChangeFn(value, slotId, "material_code");
              }
            }}
            // defaultValue={}
          >
            {materialList &&
              materialList.map((item: any) => (
                <Option value={item.value} id={item.id} key={item.id}>
                  {item.value}
                </Option>
              ))}
          </Select>
        </div>
        <div
          className={`form-items ${
            (purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment()) ||
              moment(
                moment(currentTime, "HH:mm:ss").add(
                  auctionFreezePeriod,
                  "minutes"
                ),
                "HH:mm:ss"
              ).isAfter(moment(startTime, "HH:mm:ss"))) &&
            "disabled"
          }`}
        >
          <div className="label help">
            <div style={{ display: "flex" }}>
              <div>Bag Type</div>
              <div style={{ color: "red" }}>*</div>
            </div>
          </div>
          <Select
            placeholder="Bag Type"
            showSearch
            allowClear
            filterOption={(inputValue, option: any) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            disabled={
              purchasedQty !== null ||
              moment(startTime, "HH:mm:ss").isBefore(moment())
            }
            {...customPropsBagtype}
            onChange={(value) => {
              if (onChangeFn !== undefined) {
                onChangeFn(value, slotId, "bag_type");
              }
            }}
          >
            {bagTypeList &&
              bagTypeList.map((item: any) => (
                <Option value={item.id} id={item.id} key={item.name}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
        {/* <div className="form-items align-right">
          <div className="label">Purchased Qty</div>
          <div className="purchased-qty">
            {purchasedQty ? purchasedQty : 0} MT
          </div>
        </div> */}
      </div>
      <div className="time-display">
        Slot Time: {moment(startTime, "HH:mm:ss").format("hh:mm A")} to{" "}
        {moment(endTime, "HH:mm:ss").format("hh:mm A")}
      </div>
      {/* {minQtyShow && (
        <div className="error-qty">
          Minimum Truckable quantity is <b>{sysParametersData}</b> MT.
        </div>
      )} */}
    </div>
  );
};

export default IndividualItem;
