import React, { useState, useEffect } from "react";
import "./create-auction.styles.scss";

import IndividualItem from "../../components/slot-individual-item/slot-individual-item.component";

import moment from "moment";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import CustomSelect from "../../components/custom-select/custom-select.component";

// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ArrayFormat } from "../../models/common.types";

// import {
//   getHubsService,
//   getRebateService,
// } from "../../services/common-services";
// import { getSysParams } from "../../services/sys-params-services";
import {
  getMaterialCodes,
  getSlotAuctions,
  postAuction,
} from "../../services/auction-services";
import { Dispatch } from "redux";

import { createStructuredSelector } from "reselect";
// import { setBranchIndex } from "../../redux/common/common.actions";

import {
  selectBypassBD,
  selectBranches,
  selectCrops,
  selectVarieties,
  selectSelectedCrop,
  selectTC,
  selectCurrentPage,
  selectSelectedBranch,
  selectSaudhaType,
  selectMinQty,
  selectHubs,
  selectRebates,
  selectDivisionList,
  selectBagtypeList,
} from "../../redux/common/common.selectors";
import { connect } from "react-redux";

import {
  Form,
  Input,
  Button,
  Select,
  Radio,
  DatePicker,
  Spin,
  message,
  Modal,
  Checkbox,
} from "antd";
import { sys } from "typescript";
import { setSelectedBranch } from "../../redux/common/common.actions";
import {
  getLocationService,
  getRebateService,
} from "../../services/common-services";
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

interface ISlots {
  slot_id: number;
  qty_rqd: number;
  rebate_matrix_id: string;
}
interface ISlotsGet {
  slot_id: number;
  is_active: boolean | null;
  qty_rqd: number | null | any;
  qty_fulfilled: number | null;
  rebate_id: string | null;
  max_price: any | null;
  need_date: string | null;
  start_time: string;
  auction_freeze_period: number;
}
interface IFormData {
  crop_id: number;
  branch_id: number;
  hub_id: number | null;
  variety_id: number | null;
  division: number | null;
  saudha_type: number | null;
  claim_type: number | null;
  role_type: any;
  max_price: number | null;
  min_price: number | null;
  need_date: string | null;
  slots: Array<any> | null;
  remarks: string | null;
  buying_decision_id: number | null;
  withBd: boolean | null;
}

const CreateAuction: React.FC = (props: any) => {
  const {
    branches,
    varieties,
    selectedCrop,
    isBypassedBD,
    crops,
    currentpage,
    selectedBranch,
    setSelectedBranchIndex,
    minQty,
    saudhaType,
    hubs,
    rebates,
    divisions,
    bagTypeList,
  } = props;
  // let cropIDSelected = sessionStorage.getItem("cropID");
  const formDefault = {
    crop_id: null,
    branch_id: null,
    hub_id: null,
    del_loc_sap_code: null,
    bag_type: null,
    material_code: null,
    variety_id: null,
    division: null,
    saudha_type: null,
    claim_type: null,
    role_type: null,
    max_price: null,
    min_price: null,
    need_date: null,
    slots: null,
    remarks: "",
    buying_decision_id: null,
    withBd: null,
  };
  // const [hubs, setHubs] = useState([]);
  // const [rebates, setRebates] = useState([]);
  // const [editable, setEditable] = useState(true);
  const [formData, setFormData] = useState<any>(formDefault);
  const [isHubLoading, setIsHubLoading] = useState(false);
  // const [userinfo, setUserInfo] = useState<any>({
  //   languages: [],
  //   response: [],
  // });
  // const [checked, setChecked] = useState<any>([]);
  // const checkList = ["Trader", "Broker", "FPO"];
  const [isSlotAuctionLoading, setIsSlotAuctionLoading] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  // const [sysParametersData, setSysParametersData] = useState<any>();
  // const [minQtyShow, setMinQtyShow] = useState<any>();
  const [rebateList, setRebateList] = useState<any>([]);
  const [locationList, setLocation] = useState<any>([]);
  const [materialList, setMaterialList] = useState<any>([]);
  useEffect(() => {
    // getParamsFn(selectedBranch, selectedCrop, true, true);
    // onSelectedBranch(formData.branch_id, []);
    if (currentpage === "2" && isBypassedBD) {
      setFormData({
        ...formDefault,
        crop_id: selectedCrop,
        branch_id: selectedBranch,
        saudha_type: +saudhaType,
      });
      setLocation([]);
    }
  }, [currentpage, formData.branch_id]);

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     crop_id: selectedCrop,
  //   });
  //   // getRebate(formData.branch_id, selectedCrop);
  // }, [selectedCrop]);

  // useEffect(() => {
  //   setVarietiesData(varieties);
  // }, [varieties]);

  //to reset values and set hubs and default saudha for the branch when branch_id is changed
  // useEffect(() => {
  //   if(selectSelectedBranch){
  //     setFormData((prev) => ({
  //       ...prev,
  //       saudhaType: saudhaType,
  //       branch_id: selectSelectedBranch,
  //     }));
  //   }
  // }, [selectSelectedBranch]);

  //to fire API when the combination is selected
  useEffect(() => {
    if (
      formData.hub_id != null &&
      formData.variety_id != null &&
      formData.claim_type != null &&
      formData.division != null
    ) {
      const params = {
        crop_id: formData.crop_id,
        branch_id: formData.branch_id,
        hub_id: formData.hub_id,
        variety_id: formData.variety_id,
        claim_type: formData.claim_type,
        division: formData.division,
      };
      const rebateParams = {
        crop_id: formData.crop_id,
        branch_id: formData.branch_id,
        variety_id: formData.variety_id,
        division: divisions.filter(
          (item: any) => item.id === formData.division
        )[0].name,
      };
      getVarietyData(rebateParams);
      getSlotAuctionDetails(params);
      getMaterialData({
        variety_id: formData.variety_id,
        auction_division: divisions.filter(
          (item: any) => item.id === formData.division
        )[0].name,
      });
    }
  }, [
    formData.hub_id,
    formData.variety_id,
    formData.claim_type,
    formData.division,
  ]);

  // useEffect(() => {
  //   onSelectedBranch(selectedBranch);
  // }, [selectedBranch]);

  const getVarietyData = async (params: any) => {
    try {
      const rebateListResponse = await getRebateService(params);
      if (rebateListResponse.data.status) {
        setRebateList(rebateListResponse.data.data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  const getMaterialData = async (params: any) => {
    try {
      const response = await getMaterialCodes(params);
      if (response.data.status) {
        const data = response.data.data.map((item: any) => {
          return {
            id: item.pk_id,
            value: item.material_code,
          };
        });
        setMaterialList(data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  const getSlotAuctionDetails = async (params: any) => {
    setIsSlotAuctionLoading(true);
    setFormData({ ...formData, slots: [] });
    try {
      const response = await getSlotAuctions(params);
      if (response.data.status) {
        var edit = true;
        response.data.data.slots.map((item: any) => {
          item.need_date = null || moment().add(7, "days").format("YYYY-MM-DD");
          item.max_price = null;
          if (moment(item.start_time, "HH:mm:ss").isSameOrBefore(moment())) {
            edit = false;
          }
        });
        setFormData({
          ...formData,
          slots: response.data.data.slots,
          max_price: response.data.data.max_price,
          min_price: response.data.data.min_price,
          need_date: response.data.data.need_date,
          remarks: response.data.data.remarks,
          withBd: response.data.data.with_bd,
        });
        // if (response.data.data.max_price !== null && !edit) setEditable(false);
        // else setEditable(true);
      }
    } catch (error) {
      setIsSlotAuctionLoading(false);
    }
    setIsSlotAuctionLoading(false);
  };

  const setSaudhaAndHub = async () => {
    // await getHubsFn(formData.branch_id);
    // await getRebate(formData.branch_id, formData.crop_id);
    setIsSpinning(false);
  };
  const getLocationDetails = async (value) => {
    try {
      const locaitonResponse = await getLocationService({
        territory_id: value,
        crop_id: formData.crop_id,
      });
      setLocation(locaitonResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const onSelectedBranch = async (
  //   value: string | number | any,
  //   option?: any
  // ) => {
  //   // setIsSpinning(true);
  // setFormData({
  //   ...formDefault,
  //   branch_id: value,
  // });
  // };

  // const handleChange = (e) => {
  //   // Destructuring
  //   const { value, checked } = e.target;
  //   const { languages } = userinfo;

  //   // Case 1 : The user checks the box
  //   if (checked) {
  //     setUserInfo({
  //       languages: [...languages, value],
  //       response: [...languages, value],
  //     });
  //   }

  //   // Case 2  : The user unchecks the box
  //   else {
  //     setUserInfo({
  //       languages: languages.filter((e) => e !== value),
  //       response: languages.filter((e) => e !== value),
  //     });
  //   }
  //   // getParamsFnWithOutLoad(formData.branch_id, formData.crop_id, true, true);
  //   setFormData({
  //     ...formData,
  //     role_type: userinfo.response,
  //   });
  // };

  // const getParamsFn = async (
  //   branchId?: number,
  //   cropId?: number,
  //   variety?: boolean,
  //   initial?: boolean
  // ) => {
  //   setIsSpinning(true);
  //   try {
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: cropId,
  //     });
  //     setSysParametersData(branchResponse.data.data[1].parameter_data_value);
  //     setIsSpinning(false);
  //   } catch (error) {
  //     setIsSpinning(false);
  //   }
  // };

  // const handleCheck = (event) => {
  //   var updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  //   getParamsFnWithOutLoad(formData.branch_id, formData.crop_id, true, true);
  //   setFormData({ ...formData, role_type: checked.length });
  // };

  // Generate string of checked items
  // const checkedItems = checked.length
  //   ? checked.reduce((total, item) => {
  //       return total + ", " + item;
  //     })
  //   : "";

  // Return classes based on whether item is checked
  // var isChecked = (item) =>
  //   checked.includes(item) ? "checked-item" : "not-checked-item";

  // const getParamsFnWithOutLoad = async (
  //   branchId?: number,
  //   cropId?: number,
  //   variety?: boolean,
  //   initial?: boolean
  // ) => {
  //   try {
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: cropId,
  //     });
  //     let valueCheck = branchResponse.data.data.filter(
  //       (item) => item.parameter_type === "minimum_quantity"
  //     );
  //     setSysParametersData(valueCheck[0].parameter_data_value);
  //   } catch (error) {}
  // };

  // const getDefaultSaudhaType = async (branchId: number, cropId: number) => {
  //   const params = {
  //     branch_id: branchId,
  //     parameter_type: "saudha_type",
  //     crop_id: cropId,
  //   };
  //   try {
  //     const response = await getSysParams(params);
  //     if (response.data.status) {
  //       setFormData({
  //         ...formData,
  //         saudha_type: parseInt(response.data.data[0].parameter_data_value),
  //       });
  //     }
  //   } catch (error) {}
  // };

  const onChange = async (value: number, selected: string) => {
    switch (selected) {
      case "hub":
        setFormData({ ...formData, hub_id: value, del_loc_sap_code: "" });
        getLocationDetails(value);
        break;
      case "variety":
        setFormData({ ...formData, variety_id: value });
        break;
      case "del_loc_sap_code":
        setFormData({ ...formData, del_loc_sap_code: value });
        break;
      default:
        break;
    }
  };

  // const getHubsFn = async (branchId: number) => {
  //   setIsHubLoading(true);
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) {
  //     // setIsHubLoading(false);
  //   }
  // };

  // const getRebate = async (branchId: number, crop_id: number) => {
  //   try {
  //     const response = await getRebateService({ branch_id: branchId, crop_id });
  //     setRebates(response.data.data);
  //   } catch (error) {}
  //   setIsHubLoading(false);
  // };

  const onSlotValueChanged = (
    value: number,
    slotId: number,
    changedField: string | any
  ) => {
    let slotChangedData = formData.slots;
    slotChangedData !== null &&
      slotChangedData.map((item: any) => {
        if (item.slot_id === slotId) {
          item[changedField] = value ? value : null;
          if (
            changedField !== "rebate_id" &&
            changedField !== "max_price" &&
            changedField !== "bag_type" &&
            changedField !== "material_code" &&
            changedField !== "need_date"
          ) {
            if (value < Number(minQty)) {
              item["showError"] = true;
            } else {
              item["showError"] = false;
            }
          }
        }
        return item;
      });
    setFormData({ ...formData, slots: slotChangedData });
  };

  function showPromiseConfirm() {
    confirm({
      title: "Override buying decision ?",
      icon: <ExclamationCircleOutlined />,
      content:
        "An auction with buying decision exist, do you want to override ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        createAuction();
      },
      onCancel() {
        setFormData(formDefault);
      },
    });
  }

  const createAuctionClicked = () => {
    setIsPosting(true);
    if (formData.withBd) {
      showPromiseConfirm();
    } else createAuction();
    setIsPosting(false);
    // getParamsFnWithOutLoad(formData.branch_id, formData.crop_id, true, true);
  };

  const createAuction = async () => {
    const postData: any = [];
    if (formData.slots?.length === 0) {
      message.warning("Can not find any slots.");
      return false;
    } else if (formData.slots && formData.slots?.length !== 0) {
      let isFieldDataEmpty: boolean = true;
      for (let i = 0; i < formData.slots?.length; i++) {
        if (
          formData.slots[i].is_active &&
          formData.slots[i].need_date &&
          (formData.slots[i].qty_rqd || formData.slots[i].max_price)
        ) {
          const maxPrice = varieties.filter(
            (item: any) => item.id === formData.variety_id
          )[0].maxPrice;
          const minPrice = varieties.filter(
            (item: any) => item.id === formData.variety_id
          )[0].minPrice;
          if (
            moment(
              moment(moment(), "HH:mm:ss").add(
                formData.slots[i].auction_freeze_period,
                "minutes"
              ),
              "HH:mm:ss"
            ).isAfter(moment(formData.slots[i].start_time, "HH:mm:ss"))
          ) {
            message.warning(
              `Auction cannot be created ${formData.slots[i].auction_freeze_period} minute(s) before the slot start time.`
            );
            return false;
          } else if (
            formData.claim_type === 1 &&
            formData.slots[i].rebate_id === null
          ) {
            message.warning("Please enter valid rebate id.");
            return false;
            /*  } else if (formData.slots[i].qty_rqd < Number(minQty)) {
            message.warning(
              "Slot qty must be greater than Minimum Truckable quantity."
            );
            return false; */
          } else if (
            formData.slots[i].qty_rqd === null ||
            isNaN(formData.slots[i].qty_rqd)
          ) {
            message.warning("Please enter valid Slot qty.");
            return false;
          } else if (
            formData.slots[i].max_price === null ||
            isNaN(formData.slots[i].max_price)
          ) {
            message.warning("Please enter max price...");
            return false;
          } else if (
            formData.slots[i].max_price <= minPrice ||
            formData.slots[i].max_price >= maxPrice
          ) {
            message.warning(
              `Max price should be between the range ${minPrice} (₹/MT) to ${maxPrice} (₹/MT)...`
            );
            return false;
          } else if (
            isNaN(formData.slots[i].max_price) ||
            formData.slots[i].max_price <= 0
          ) {
            message.warning("Enter a valid max price...");
            return false;
          } else if (
            formData.slots[i].need_date === "" ||
            formData.slots[i].need_date === null ||
            formData.slots[i].need_date === undefined
          ) {
            message.warning("Please enter need date...");
            return false;
          } else if (
            formData.slots[i].material_code === "" ||
            formData.slots[i].material_code === null ||
            formData.slots[i].material_code === undefined
          ) {
            message.warning("Please select Material...");
            return false;
          } else if (
            formData.slots[i].bag_type === "" ||
            formData.slots[i].bag_type === null ||
            formData.slots[i].bag_type === undefined
          ) {
            message.warning("Please select Bag type...");
            return false;
          }
          postData.push({
            ...formData.slots[i],
            id: i,
            end_time: formData.slots[i].bid_ends_on,
            rebate_matrix_id: formData.slots[i].rebate_id,
          });
          console.log("postData", postData);
          console.log(i);
          postData
            .filter((item: any) => item.id === i)
            .map((item: any) => {
              item.qty_fulfilled =
                item.qty_fulfilled === null ? 0 : item.qty_fulfilled;
              delete item.bid_ends_on;
              delete item.rebate_id;
              delete item.auction_freeze_period;
              delete item.slot_number;
              delete item.start_time;
              delete item.showError;
              delete item.id;
            });
          isFieldDataEmpty = false;
        } else if (
          formData.slots[i].qty_rqd === null &&
          formData.slots[i].max_price === null &&
          formData.slots[i].need_date ===
            moment().add(7, "days").format("YYYY-MM-DD")
        ) {
          continue;
        } else {
          if (formData.slots[i].is_active) {
            message.warning("Please enter valid slot details...");
            return false;
          }
          continue;
        }
      }
      if (!isFieldDataEmpty) {
        setFormData((prev: IFormData) => {
          const modifiedData: any = {
            ...prev,
            saudha_type: prev.saudha_type,
            slots: postData,
            role_type: [15],
            withBd: false,
            min_price: null,
            buying_decision_id: null,
          };
          delete modifiedData.allSlots;
          delete modifiedData.need_date;
          delete modifiedData.normal_auction_exists;
          delete modifiedData.roleType;
          delete modifiedData.saudaType;
          delete modifiedData.total_qty;
          delete modifiedData.varietyName;
          delete modifiedData.hub_name;
          delete modifiedData.max_price;
          (async (modifiedData) => {
            try {
              const postResponse = await postAuction(modifiedData);
              if (postResponse.data.status) {
                message.success("Auction created / updated successfully");
              } else {
                message.error(
                  postResponse.data.message || "Something went wrong :("
                );
              }
            } catch (err: any) {
              console.log(err);
              message.error(
                err.response.data.message || "Something went wrong :("
              );
            }
          })(modifiedData);
          return prev;
        });
        return true;
      } else {
        message.error("Please fill slot datas to create/update auctions.");
        return false;
      }
    }
  };

  return (
    <div className="create-auction">
      <Spin spinning={isSpinning}>
        <div className="create-auction-title">
          Create Auction for{" "}
          <CustomSelect
            options={branches}
            value={formData.branch_id ? formData.branch_id : undefined}
            onChange={(value) => {
              setFormData((prev) => ({ ...prev, branch_id: value }));
              setSelectedBranchIndex(value);
            }}
            returnId
          />
          for{" "}
          {crops &&
            crops.length &&
            crops.find(
              (item: { id: number; name: string }) =>
                item.id === formData.crop_id
            )?.name}
          {/* {crops.find((item: any) => { if (formData.crop_id === item.id) return item.name })} */}
        </div>
        <div className="auction-form-container">
          <div className="container-1">
            <div className="form-items">
              <div className="label-hub">
                Select Hub<label style={{ color: "red" }}>*</label>
              </div>
              <div className="label-hub-select">
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select Hub"
                  value={formData.hub_id ? formData.hub_id : undefined}
                  onChange={(value, option) => onChange(value, "hub")}
                  loading={isHubLoading}
                  disabled={isHubLoading}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {hubs.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="form-items">
              <div className="label">Select Delivery Location</div>
              <div className="label-hub-select">
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select Delivery Location"
                  allowClear
                  value={
                    formData.del_loc_sap_code
                      ? formData.del_loc_sap_code
                      : undefined
                  }
                  onChange={(value, option) =>
                    onChange(value, "del_loc_sap_code")
                  }
                >
                  {locationList &&
                    locationList.map((item: any) => (
                      <Option
                        key={item.del_loc_sap_code}
                        value={item.del_loc_sap_code}
                      >
                        {item.del_loc_name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="form-items">
              <div className="label">
                Select Variety<label style={{ color: "red" }}>*</label>
              </div>
              <div className="label-hub-select">
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select Variety"
                  value={formData.variety_id ? formData.variety_id : undefined}
                  onChange={(value, option) => onChange(value, "variety")}
                >
                  {varieties &&
                    varieties.length &&
                    varieties.map((item: ArrayFormat) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="form-items">
              <div className="label">
                Claim Type<label style={{ color: "red" }}>*</label>
              </div>
              <Radio.Group
                value={formData.claim_type}
                onChange={(e) => {
                  // getParamsFnWithOutLoad(
                  //   formData.branch_id,
                  //   formData.crop_id,
                  //   true,
                  //   true
                  // );
                  setFormData({ ...formData, claim_type: e.target.value });
                }}
              >
                <Radio value={1}>Claim</Radio>
                <Radio value={2}>No Claim</Radio>
              </Radio.Group>
            </div>
            <div className="form-items">
              <div className="label">
                Division<label style={{ color: "red" }}>*</label>
              </div>
              <Radio.Group
                value={formData.division}
                onChange={(e) => {
                  // getParamsFnWithOutLoad(
                  //   formData.branch_id,
                  //   formData.crop_id,
                  //   true,
                  //   true
                  // );
                  setFormData({ ...formData, division: e.target.value });
                }}
              >
                {divisions &&
                  divisions.map((item: any) => (
                    <Radio value={item.id} id={item.id} key={item.id}>
                      {item.name}
                    </Radio>
                  ))}
              </Radio.Group>
            </div>
            <div className="form-items">
              <div className="label">
                Sauda Type<label style={{ color: "red" }}>*</label>
              </div>
              <Radio.Group
                value={formData.saudha_type}
                onChange={(e) => {
                  // getParamsFnWithOutLoad(
                  //   formData.branch_id,
                  //   formData.crop_id,
                  //   true,
                  //   true
                  // );
                  setFormData({ ...formData, saudha_type: e.target.value });
                }}
              >
                <Radio value={1}>Net basis</Radio>
                <Radio value={2}>Gross basis</Radio>
              </Radio.Group>
            </div>
            {/* <div className="form-items">
              <div className="label">
                Role<label style={{ color: "red" }}>*</label>
              </div>
              <form>
                <Checkbox value={15} onChange={handleChange}>
                  Trader/Broker
                </Checkbox>
                <Checkbox value={11} onChange={handleChange}>
                  FPO
                </Checkbox>
              </form>
              <div>
                {
                  (onchange = () => {
                    getParamsFnWithOutLoad(branches[branchIndex].id, true, true);
                    setFormData({
                      ...formData,
                      role_type: userinfo.response,
                    });
                  })
                }
              </div>
            </div> */}

            {/* <div className="form-items">
              <div className="label">
                 Type<label style={{ color: "red" }}>*</label>
              </div>
              <div className="list-container">
          {checkList.map((item, index) => (
            <div key={index}>
              <input value={item} type="checkbox" onChange={handleCheck} />
              <span className={isChecked(item)}>{item}</span>
            </div>
          ))}
          <div>
        {`Items checked are: ${checkedItems}`}
      </div>
        </div>
            </div>  */}

            {/* <div className="form-items">
              <div className="label">
                Type<label style={{ color: "red" }}>*</label>
              </div>
              <Checkbox.Group
                value={formData.person_type}
                onChange={(e) => {
                  getParamsFnWithOutLoad(branches[branchIndex].id, true, true);
                  setFormData({ ...formData, person_type:e.target.value});
                }}
              >
                <Checkbox value={1}>ABD</Checkbox>
                <Checkbox value={2}>BBD</Checkbox>
                <Checkbox value={3}>CBD</Checkbox>
              </Checkbox.Group>
            </div> */}
            {/* <div className={`form-items ${!editable && "disabled"}`}>
              <div className="label">
                Max Price (₹/MT)<label style={{ color: "red" }}>*</label>
              </div>
              <Input
                type="number"
                value={
                  formData.max_price === null ? undefined : formData.max_price
                }
                onChange={(e) => {
                  getParamsFnWithOutLoad(formData.branch_id, true, true);
                  setFormData({
                    ...formData,
                    max_price: parseInt(e.target.value),
                  });
                }}
              />
            </div>
            <div className={`form-items ${!editable && "disabled"}`}>
              <div className="label">
                Need Date<label style={{ color: "red" }}>*</label>
              </div>
              <DatePicker
                clearIcon={false}
                onChange={(value, date) => {
                  getParamsFnWithOutLoad(formData.branch_id, true, true);
                  setFormData({ ...formData, need_date: date });
                }}
                value={
                  formData.need_date !== null
                    ? moment(formData.need_date)
                    : undefined
                }
                disabledDate={(d) =>
                  !d || d.isSameOrBefore(moment().subtract(1, "days"))
                }
              />
            </div> */}
          </div>
          <div className="container-2">
            <div>
              <Spin spinning={isSlotAuctionLoading}>
                <div className="form-items">
                  <div className="label noteText">
                    <b>
                      {formData.slots !== undefined &&
                      formData.slots !== null &&
                      formData.slots.length
                        ? ` NOTE: Auction cannot be created ${formData.slots[0].auction_freeze_period} minute(s) before the slot start time`
                        : ""}
                    </b>
                  </div>
                </div>
                <div className="slots-container">
                  {formData.slots !== null && formData.slots.length !== 0 ? (
                    formData.slots
                      .filter(
                        (item: any) => item.is_active && item.is_active === true
                      )
                      .map((item: any, index: number) => {
                        return (
                          <IndividualItem
                            key={index}
                            index={index}
                            slotId={item.slot_id}
                            cropId={formData.crop_id}
                            branchId={formData.branch_id}
                            slotNumber={item.slot_number}
                            onChangeFn={onSlotValueChanged}
                            requiredQty={item.qty_rqd}
                            minQtyShow={
                              item.showError === undefined
                                ? false
                                : item.showError
                            }
                            sysParametersData={minQty}
                            purchasedQty={item.qty_fulfilled}
                            rebateId={item.rebate_id}
                            startTime={item.start_time}
                            auctionFreezePeriod={item.auction_freeze_period}
                            endTime={item.end_time}
                            rebateList={rebateList}
                            bag_type={formData.bag_type}
                            bagTypeList={bagTypeList}
                            materialList={materialList}
                            material_code={formData.material_code}
                            claim={formData.claim_type}
                            needDate={item.need_date}
                            maxPrice={item.max_price}
                          />
                        );
                      })
                  ) : formData.hub_id !== null &&
                    formData.variety_id !== null &&
                    formData.claim_type !== null &&
                    formData.division !== null ? (
                    <div className="no-slots-container">
                      No slots available...
                    </div>
                  ) : (
                    <div className="no-slots-container">
                      Select Hub - Variety - Claim Type - Division
                    </div>
                  )}
                </div>
              </Spin>
            </div>
            <div className={`form-items`}>
              <div className="label">Remarks</div>
              <TextArea
                value={formData.remarks === null ? undefined : formData.remarks}
                showCount
                maxLength={35}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    remarks: e.target.value,
                  })
                }
              ></TextArea>
            </div>
          </div>
          {/* <div className="container-3">
            <div className="terms-container">
              <div className="terms-and-conditions">Terms and Conditions</div>
              <div className="terms">
                <div
                  className="editor-text-container"
                  dangerouslySetInnerHTML={{ __html: TCHtmlString }}
                ></div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="bottom-container">
          <Button loading={isPosting} onClick={createAuctionClicked}>
            Create Auction
          </Button>
        </div>
      </Spin>
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  isBypassedBD: selectBypassBD,
  branches: selectBranches,
  crops: selectCrops,
  varieties: selectVarieties,
  selectedCrop: selectSelectedCrop,
  TCHtmlString: selectTC,
  currentpage: selectCurrentPage,
  selectedBranch: selectSelectedBranch,
  minQty: selectMinQty,
  saudhaType: selectSaudhaType,
  hubs: selectHubs,
  rebates: selectRebates,
  divisions: selectDivisionList,
  bagTypeList: selectBagtypeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAuction);
