/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "./intermediate-screen.styles.scss";

import moment from "moment";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";
import CircleImage from "../../assets/circle.svg";
import plusIcon from "../../assets/Alarm-Plus-icon.png";
import minusIcon from "../../assets/minusIcon.png";
import ReactDOM from "react-dom";

import { SyncOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import {
  selectBranches,
  selectSelectedCrop,
  selectCrops,
  selectVarieties,
  selectCurrentPage,
  selectViewAuctionPage,
  selectSelectedBranch,
  selectHubs,
  selectBranchParams,
} from "../../redux/common/common.selectors";

// import { setBranchIndex } from "../../redux/common/common.actions";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  getUpcomingAuction,
  cancelAuction,
  editAuction,
  getSaudaDetails,
  putSaudaDetails,
  getMaterialCodes,
  getConditionCodes,
  generateSaudaUrl,
  getBagTypeDetails,
} from "../../services/auction-services";
import {
  getRebateMatrixService,
  getRebateService,
  getHubsService,
  getSlotIdsService,
} from "../../services/common-services";

import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

import {
  Table,
  Form,
  message,
  Checkbox,
  Popover,
  Modal,
  Button,
  Select,
  Input,
  Alert,
  Row,
  Spin,
  Progress,
} from "antd";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import { selectUserData } from "../../redux/user/user.selectors";
import DummyData from "./dummyData.json";

import Axios from "axios";
// import API from "../../api";
import { render } from "sass";
import { setupMaster } from "cluster";
import { Dispatch } from "redux";
import { setSelectedBranch } from "../../redux/common/common.actions";
// const FileDownLoad = require("js-file-download");

const { confirm } = Modal;
const { Option } = Select;

// const conditions = [
//   { id: 1, val: "Option 1" },
//   { id: 2, val: "Option 2" },
//   { id: 3, val: "Option 3" },
//   { id: 4, val: "Option 4" },
// ];
// const materialCodes = [
//   { id: 1, val: "Option 1" },
//   { id: 2, val: "Option 2" },
//   { id: 3, val: "Option 3" },
//   { id: 4, val: "Option 4" },
// ];
// const bagTypes = [
//   { id: 1, val: "Old" },
//   { id: 2, val: "New" },
//   { id: 3, val: "PP" },
// ];

const status = [
  { name: "Submission Pending", id: 0 },
  { name: "Approval Pending", id: 1 },
  { name: "Approved", id: 2 },
  { name: "Sent to SAP", id: 3 },
];

// const editDefault = {
//   qty_rqd: undefined,
//   rebate_matrix_id: undefined,
//   auction_id: undefined,
// };

const IntermediateScreen: React.FC = (props: any) => {
  const {
    branches,
    selectedCropId,
    crops,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setSelectedBranchIndex,
    hubs,
    varietiesList,
    bagTypeData,
    conditionCodes,
    branchParams,
  } = props;
  const [upcomingData, setUpcomingData] = useState<any>(); //[]
  const [isLoading, setIsLoading] = useState(false); //true
  // const [isSpinning, setIsSpinning] = useState(false);
  // const [isProgressClicked, setisProgressClicked] = useState(false);
  // const [downloaddata, setdownloaddata] = useState<any>();
  // const [isSpinnerOnProgress, setIsSpinnerOnProgress] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [rebates, setRebates] = useState<any>();
  // const [valueToEdit, setValueToEdit] = useState<any>(editDefault);
  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [conditionsModalVisible, setConditionsModalVisible] = useState(false);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();
  // const [materialCode, setMaterialCodesData] = useState<any>();
  // const [conditionCodes, setConditionCodesData] = useState<any>();
  const [selectConditions, setSelectConditions] = useState<any>(conditionCodes);
  const [clickedRowData, setClickedRowData] = useState<any>();
  // const [checkAll, setCheckAll] = useState<any>(false);

  // const [modData, setModData] = useState<any>([]);

  // const [slots, setSlots] = useState();
  // const [hubs, setHubs] = useState();
  // const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id); //set it to 0 def
  const [selectedVariety, setSelectedVariety] = useState();
  const [selectedDates, setSelectedDates] = useState([
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [selectedHub, setSelectedHub] = useState<any>();
  // const [selectedSlot, setSelectedSlot] = useState();
  const [isTableVisible, setisTableVisible] = useState(true);
  // const [localCropID, setLocalCropID] = useState<any>();
  // const [varietiesList, setVarietiesData] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>([0]);
  // const [bagType, setBagType] = useState([]);
  // let intermediateScreenIntervalID;
  const { TextArea } = Input;

  /**
   * @component
   * @description Reusable component to display table header.
   * @property {string} title Title of header
   * @property {string} subtitle Subtitle of header
   * @memberof Approvals
   */
  // const TableColumnHeader = (props: any) => {
  //   const { title, subtitle } = props;
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //       }}
  //     >
  //       <div
  //         style={{
  //           fontWeight: 400,
  //           color: "white",
  //           fontSize: "14px",
  //           whiteSpace: "nowrap",
  //           textAlign: "left",
  //         }}
  //       >
  //         {title}
  //       </div>
  //       <div
  //         style={{
  //           color: "#cbdfff",
  //           fontSize: "11px",
  //           fontWeight: "normal",
  //           textAlign: "left",
  //         }}
  //       >
  //         {subtitle}
  //       </div>
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: "",

      // title: "MT",
      dataIndex: "addRow",
      key: "addRow",
      width: 70,
      fixed: true,
      render: (actions, record) => (
        <div className="btn-container">
          {/* <Button 
            disabled={!record.isBroker} 
            onClick={()=>handleAddRow(record)}>Add</Button> */}
          <img
            style={{
              width: "25px",
              height: "25px",
              margin: "0px auto",
              visibility:
                record.isBroker &&
                record.saudhaId !== null &&
                record.brokerSaudhaID === null
                  ? "visible"
                  : "collapse",
            }}
            src={plusIcon}
            alt="Submit"
            onClick={() => handleAddRow(record, upcomingData.length)}
          />
          <img
            style={{
              width: "25px",
              height: "25px",
              margin: "0px auto",
              visibility:
                record.isChild ||
                (record.saudhaId === null && record.brokerSaudhaID !== null)
                  ? "visible"
                  : "collapse",
            }}
            src={minusIcon}
            alt="Submit"
            onClick={() => handleDeleteRow(record)}
          />
        </div>
      ),
    },
    {
      title: "Sauda ID",
      children: [
        {
          // title: "MT",
          fixed: true,
          dataIndex: "saudhaId",
          key: "saudhaId",
          width: 50,
          // children:columns1,
        },
      ],
    },
    {
      title: "Broker Sauda ID",
      children: [
        {
          // title: "MT",
          fixed: true,
          dataIndex: "brokerSaudhaID",
          key: "brokerSaudhaID",
          width: 70,
          // children:columns1,
        },
      ],
    },
    {
      title: "Vendor Code",
      children: [
        {
          // title: "MT",
          dataIndex: "vendorCode",
          key: "vendorCode",
          width: 110,
          fixed: true,
          render: (value: number, row: any) => {
            return (
              <div className={"select-remarks"}>
                <Input
                  style={{ verticalAlign: "middle", color: "black" }}
                  maxLength={9}
                  // disabled={row.downloadStatus === 2 || !row.isChild}
                  value={row.vendorCode}
                  onChange={(e) =>
                    setVendorCode(
                      e.target.value,
                      row.varietyId,
                      row.saudhaId,
                      row.tempkey
                    )
                  }
                />
              </div>
            );
          },
        },
      ],
    },
    // {
    //   title: "Bid ID",
    //   children: [
    //     {
    //       // title: "MT",
    //       dataIndex: "bidId",
    //       key: "bidId",
    //       width: 170,
    //     },
    //   ],
    // },
    {
      title: "Firm Name",
      children: [
        {
          dataIndex: "userName",
          key: "user_name",
          width: 130,
        },
      ],
    },
    {
      title: "Broker Comment",
      children: [
        {
          dataIndex: "brokerRemark",
          key: "brokerRemark",
          width: 120,
        },
      ],
    },
    {
      title: "Hub",
      children: [
        {
          // title: "MT",
          dataIndex: "hubName",
          key: "hub_name",
          width: 120,
        },
      ],
    },
    {
      title: "Variety",
      children: [
        {
          // title: "MT",
          dataIndex: "varietyName",
          key: "variety_name",
          width: 110,
        },
      ],
    },
    {
      title: "Auction date",
      children: [
        {
          // title: "MT",
          dataIndex: "auctionDate",
          key: "auction_date",
          width: 85,
        },
      ],
    },

    // {
    //   title: "Bid Qty",
    //   children: [
    //     {
    //       title: "MT",
    //       dataIndex: "qty",
    //       key: "bidqty",
    //       width: 70,
    //     },
    //   ],
    // },
    {
      title: "Allotted Qty",
      children: [
        {
          title: "MT",
          dataIndex: "allotedQty",
          key: "allocatedqty",
          width: 90,
          render: (value: number, row: any) => {
            return (
              <div className={"select-remarks"}>
                <Input
                  type="number"
                  style={{
                    verticalAlign: "middle",
                    color: "black",
                  }}
                  maxLength={35}
                  min={0}
                  disabled={row.downloadStatus === 2 || !row.isChild}
                  value={row.allotedQty}
                  onChange={(e) =>
                    setAlottedQty(
                      e.target.value,
                      row.varietyId,
                      row.saudhaId,
                      row.tempkey
                    )
                  }
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "price",
          width: 90,
          key: "price",
        },
      ],
    },
    {
      title: "Brokerage Cost",
      children: [
        {
          title: "₹",
          dataIndex: "brokerageCost",
          width: 90,
          key: "brokerageCost",
        },
      ],
    },
    {
      title: "Rebate Name",
      children: [
        {
          dataIndex: "rebateName",
          key: "rebateName",
          width: 180,
          render: (value: number, row: any) => {
            return (
              <div className={"rebate-info"}>
                {value}
                {value && (
                  <span
                    className="info"
                    onClick={() => {
                      getRebateMatrix(row.rebateId, selectedBranch);
                    }}
                  >
                    ?
                  </span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Crop Year",
      children: [
        {
          // title: "MT",
          dataIndex: "cropYear",
          key: "cropYear",
          width: 85,
          render: (value: number, row: any) => {
            return (
              <div className={"rebate-info-upcoming"}>
                {/* <Radio defaultChecked>
                  Old
                </Radio>
                <Radio>
                  New
                </Radio> */}
                <Select
                  // className={
                  //   row.downloadStatus === 2 || row.isParent
                  //     ? "select-year-disabled"
                  //     : "select-year"
                  // }
                  disabled={
                    row.downloadStatus === 2 ||
                    row.isParent ||
                    selectedCropId === 2
                  }
                  style={{
                    verticalAlign: "middle",
                    color: "black",
                  }}
                  defaultValue={
                    row.cropYear === 1 ||
                    row.cropYear === new Date().getFullYear()
                      ? new Date().getFullYear()
                      : new Date().getFullYear() - 1
                  }
                  onSelect={(value, props) =>
                    setSelectedCropYear(
                      value,
                      props,
                      row.varietyId,
                      row.saudhaId,
                      row.tempkey
                    )
                  }
                  // style={{
                  //   width: "100%",
                  //   //  color:"black",
                  //   // fontWeight: row.isParent ? "bold" : "normal",
                  //   //color: row.isParent ? "gray" : "black",
                  // }}
                >
                  {row.cropYearList &&
                    row.cropYearList != undefined &&
                    row.cropYearList.length &&
                    row.cropYearList.map((bag: any, idx: number) => (
                      <Option key={bag.id} value={bag.val}>
                        {bag.val}
                      </Option>
                    ))}
                </Select>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Bag Type",
      children: [
        {
          dataIndex: "bagType",
          key: "bagType",
          width: 90,
          render: (value: number, row: any) => {
            return (
              <div className={"rebate-info-upcoming"}>
                {/* <Radio defaultChecked>
                  Old
                </Radio>
                <Radio>
                  New
                </Radio> */}
                <Select
                  // className="remarks-select"
                  value={row.bagId && `${row.bagId}`}
                  disabled={row.downloadStatus === 2 || row.isParent}
                  defaultValue={row.bagTypes[0].id}
                  onSelect={(value) =>
                    setSelectedBagType(
                      value,
                      row.varietyId,
                      row.saudhaId,
                      row.tempkey
                    )
                  }
                  style={{
                    width: "100%",
                    fontWeight: row.isParent ? "bold" : "normal",
                    color: row.isParent ? "gray" : "black",
                  }}
                >
                  {row.bagTypes &&
                    row.bagTypes !== undefined &&
                    row.bagTypes.length &&
                    row.bagTypes.map((bag: any, idx: number) => (
                      <Option key={bag.id} value={bag.id}>
                        {bag.val}
                      </Option>
                    ))}
                </Select>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Conditions",
      key: "condition",
      width: 90,
      children: [
        {
          // title: <TableNewHeader title="" />,
          // dataIndex: "condition",
          // key: "remarks_value",
          width: 90,
          render: (value: any, row: any) => (
            //remarks, record
            <div
              className={`select-remarks cursor-pointer
								${row.downloadStatus === 2 || row.isParent ? "disable-field" : ""}`}
              onClick={() => handleConditionsClick(row)}
              // onClick={() => {
              //   !row.materialCode &&
              //     (row.materialCode == undefined ||
              //       row.materialCode.length <= 0) &&
              //     getConditionsData(row.varietyId, row.saudhaId);/condition_codes
              // }}
            >
              Conditions
              {/* <Select
                // className="remarks-select"
                // disabled={
                //   record.mandiBuying.isMandi ||
                //   !canUserEdit(
                //     user,
                //     selectedBranchID,
                //     FeatureCodes.BUYING_DECISION_SUBMIT
                //   )
                // }
                // defaultValue={
                //   record.remarkId
                //     ? allRemarks.find(
                //       (item) =>
                //         item.remark_id === record.remarkId
                //     ).remark
                //     : "Add a remark"
                // }
                onSelect={(value) =>
                  setSelectedConditions(value, row.varietyId, row.saudhaId)
                }
                style={{ width: "100%" }}
              >
                {conditions.map((item, idx) => (
                  <Option key={item.id} value={item.id}>
                    {item.val}
                  </Option>
                ))}
              </Select> */}
            </div>
          ),
        },
      ],
    },
    {
      title: "Total Charges",
      children: [
        {
          title: "₹/MT",
          dataIndex: "charges",
          key: "charges",
          width: 100,
          render: (value: number, row: any) => {
            return (
              <div className={"rebate-info"}>
                {row.conditionCodes &&
                  row.conditionCodes.reduce(function (acc, obj) {
                    // Check if obj is an object and has a valid numeric condition_value
                    if (
                      obj &&
                      typeof obj.condition_value === "number" &&
                      !isNaN(obj.condition_value)
                    ) {
                      return acc + obj.condition_value;
                    } else {
                      return acc; // Skip invalid values
                    }
                  }, 0)}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Material Code",
      key: "materialId",
      width: 150,

      children: [
        {
          // title: <TableNewHeader title="" />,
          dataIndex: "materialId",
          // key: "remarks_value",
          width: 160,
          // render: (value: any, row: any) => (
          //   //remarks, record
          //   <div
          //     className={
          //       "select-remarks"
          //       // ${record.mandiBuying.isMandi ||
          //       //     !canUserEdit(
          //       //       user,
          //       //       selectedBranchID,
          //       //       FeatureCodes.BUYING_DECISION_SUBMIT
          //       //     )
          //       //     ? "disable-field"
          //       //     : ""
          //       // }`
          //     }
          //   >
          //     {/* {!record.mandiBuying.isMandi ? ( */}
          //     <Select
          //       // className="remarks-select"
          //       disabled={row.downloadStatus === 2 || row.isParent}
          //       // defaultValue={
          //       //   record.remarkId
          //       //     ? allRemarks.find(
          //       //       (item) =>
          //       //         item.remark_id === record.remarkId
          //       //     ).remark
          //       //     : "Add a remark"
          //       // }
          //       onSelect={(value) =>
          //         setSelectedMaterialCode(
          //           value,
          //           row.varietyId,
          //           row.saudhaId,
          //           row.tempkey
          //           // row.rebateId,
          //           // row.rebateName
          //         )
          //       }
          //       style={{ width: "100%" }}
          //       showSearch
          //       onClick={() => {
          //         !row.materialCode &&
          //           row.downloadStatus !== 2 &&
          //           (row.materialCode == undefined ||
          //             row.materialCode.length <= 0) &&
          //           getMaterialData(
          //             row.varietyId,
          //             row.saudhaId,
          //             row.auctionDivision,
          //             // row.rebateId,
          //             // row.rebateName,
          //             row.tempkey
          //           );
          //       }}
          //       value={row.materialId}
          //     >
          //       {row.materialCode &&
          //         row.materialCode != undefined &&
          //         row.materialCode.length &&
          //         row.materialCode.map((item: any, idx: number) => (
          //           <Option key={item.key} value={item.value}>
          //             {item.value}
          //           </Option>
          //         ))}
          //     </Select>
          //     {/* ) : null} */}
          //   </div>
          // ),
        },
      ],
    },
    {
      title: "Remarks*",
      key: "remarks",
      width: 180,
      children: [
        {
          dataIndex: "remarks",
          key: "remarks",
          width: 180,
          render: (value: any, row: any) => (
            <div className={"select-remarks"}>
              <TextArea
                autoSize={{ minRows: 0, maxRows: 2 }}
                style={{ verticalAlign: "middle" }}
                maxLength={35}
                disabled={row.downloadStatus === 2 || row.isParent}
                value={row.remarks}
                onChange={(e) =>
                  setRemarks(
                    e.target.value,
                    row.varietyId,
                    row.saudhaId,
                    row.tempkey
                  )
                }
              />
            </div>
          ),
        },
      ],
    },
    {
      title: "Submit",

      children: [
        {
          dataIndex: "actions",
          key: "actions",
          width: 85,
          render: (value: number, row: any) => {
            //actions, record
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {/* <Checkbox onChange={() => onCheckBoxChange(row)} /> */}
                {row.downloadStatus === 2 || row.downloadStatus === 0 ? (
                  <div
                    onClick={() => {
                      onCheckBoxChange([row], "single");
                    }}
                    className={`action-button
									${row.downloadStatus === 2 || row.isChild ? "disable-field" : ""}`}
                  >
                    <Popover
                      overlayClassName="competitor-hover-container"
                      content={false}
                      title={"Submit Sauda"}
                      style={{ width: "50px !important" }}
                    >
                      <img
                        style={{
                          // width: "10px",
                          // height: "10px",
                          margin: "0px auto",
                        }}
                        src={CircleImage}
                        alt="Submit"
                      />
                    </Popover>
                  </div>
                ) : row.checked === false ? (
                  <div className={`action-submitted-button`}>
                    <Popover
                      overlayClassName="competitor-hover-container"
                      content={false}
                      title={"Sauda Submitted"}
                      style={{ width: "50px !important" }}
                    >
                      <CheckedIcon />
                    </Popover>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      onCheckBoxChange([row], "single");
                    }}
                    className={`action-button
											${row.downloadStatus === 2 ? "disable-field" : ""}`}
                  >
                    <Popover
                      overlayClassName="competitor-hover-container"
                      content={false}
                      title={"Submit Sauda"}
                      style={{ width: "50px !important" }}
                    >
                      <img
                        style={{
                          // width: "10px",
                          // height: "10px",
                          margin: "0px auto",
                        }}
                        src={CircleImage}
                        alt="Submit"
                      />
                    </Popover>
                  </div>
                )}
              </div>
            );
          },
        },
      ],
    },
    // {
    //   title: (
    //     <div
    //       style={{
    //         fontSize: "12px",
    //         color: "white",
    //         display: "flex",
    //         flexDirection: "row",
    //         justifyContent: "space-between",
    //         width: "220px",
    //         paddingRight: "13px",
    //       }}
    //     >
    //       <TableColumnHeader title="Action" />
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-around",
    //           paddingLeft: "6px",
    //         }}
    //       >
    //         {/* {console.log("-----------> Check All", this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region && this.props.hubTableData.region.length && this.props.hubTableData.region.filter((obj) => obj.checked === true).length, "===>",
    //           (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length && (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region && this.props.hubTableData.region.length && this.props.hubTableData.region.filter((obj) => obj.checked === true).length) === (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length)),
    //           "===>",
    //           this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length,
    //           "==> checkAll",
    //           this.state.checkAll)} */}
    //         <Checkbox
    //           // width="10px"
    //           // height="10px"
    //           //{checkAll}
    //           checked={
    //             upcomingData &&
    //             upcomingData.length !== 0 &&
    //             (upcomingData &&
    //               upcomingData.length &&
    //               upcomingData.filter((obj: any) => obj.checked === true)
    //                 .length) === (upcomingData && upcomingData.length)
    //           }
    //           onChange={(e) => {
    //             let count = 0;
    //             // let data = setHubDataForTable();
    //             const modifiedData =
    //               upcomingData && upcomingData.length
    //                 ? upcomingData.map((item: any) => {
    //                     // if (item.edited || (item.version === 0 && item.revisedQty !== 0)
    //                     //   //   &&
    //                     //   // user.userName !== item.approver1Name &&
    //                     //   // user.userName !== item.approver2Name
    //                     // ) {
    //                     count++;
    //                     return {
    //                       ...item,
    //                       checked: e.target.checked,
    //                     };
    //                     // } else {
    //                     //   return item;
    //                     // }
    //                   })
    //                 : [];
    //             setUpcomingData(modifiedData);
    //           }}
    //         ></Checkbox>
    //       </div>
    //     </div>
    //   ),
    //   key: "action",
    //   dataIndex: "action",
    //   width: 85,
    //   render: (value: number, row: any) => {
    //     return (
    //       <div className="approval-table-checkbox">
    //         <Checkbox
    //           disabled={row.downloadStatus === 2 || row.isParent}
    //           // disabled={!row.edited && !(row.edited === false && (row.data.version === 0 && row.revisedQty !== 0)
    //           //   //  || (row.approval1 !== null && row.approval2 !== null) || (row.approval1 === false || row.approval2 === false) || (row.approval1 !== null && user.userName === row.approver1Name) || (row.approval2 !== null && user.userName === row.approver2Name) )
    //           // )
    //           // }
    //           checked={row.checked}
    //           onChange={(e) => {
    //             // let data = setHubDataForTable();
    //             let count = 0;
    //             const modifiedData = upcomingData.map(
    //               (item: any, index: any) => {
    //                 if (item.saudhaId === row.saudhaId) {
    //                   item.checked && count++;
    //                   return {
    //                     ...item,
    //                     checked: e.target.checked,
    //                   };
    //                 } else {
    //                   return { ...item };
    //                 }
    //               }
    //             );
    //             setUpcomingData(modifiedData);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const onCheckBoxChange = async (dataToPut: any, key: any) => {
    setIsLoading(true);
    if (key === "multi") {
      dataToPut = dataToPut
        .map((element: any) => {
          if (element.checked) return element;
          else return undefined;
        })
        .filter((item: any) => item !== undefined);
    }
    var list;

    if (key === "single" && dataToPut[0].isParent === true) {
      list = upcomingData.filter((item) => {
        if (
          item.brokerSaudaID !== null ||
          item.brokerSaudaID !== undefined ||
          item.brokerSaudaID !== ""
        ) {
          if (
            item.brokerSaudhaID === dataToPut[0].saudhaId ||
            item.saudhaId === dataToPut[0].saudhaId
          ) {
            return item;
          }
        }
      });
    } else {
      list = dataToPut;
    }

    if (validate(dataToPut, key)) {
      const reqBody = list.map((eachRow: any) => {
        return {
          saudha_id: eachRow.saudhaId,
          bag_type: eachRow.bagId,
          remarks: eachRow.remarks,
          conditions: eachRow.conditionCodes.filter((item) => {
            if (
              item.condition_type !== null &&
              item.condition_type !== "" &&
              item.condition_type !== undefined
            ) {
              return item;
            }
          }),
          material_code: eachRow.materialId,
          vendor_code: eachRow.vendorCode,
          broker_saudha_id: eachRow.brokerSaudhaID,
          YMM_ABD_CTRM_BUYING_MODEL: eachRow.buyingModel,
          YMM_ABD_CTRM_SALES_MODEL: eachRow.salesModel,
          YMM_ABD_CTRM_WH_TYPE: eachRow.whType,
          alloted_qty: eachRow.allotedQty,
          brokerage_cost: eachRow.brokerageCost,
          crop_year:
            eachRow.cropYear !== null
              ? String(eachRow.cropYear) === "2" ||
                String(eachRow.cropYear) ===
                  (new Date().getFullYear() - 1).toString()
                ? new Date().getFullYear() - 1
                : new Date().getFullYear()
              : new Date().getFullYear(),
        };
      });

      try {
        const response = await putSaudaDetails({ saudha_details: reqBody });
        if (response.data.status) {
          setUpcomingData([]);
          sessionStorage.setItem(
            "intermediateScreenTMParams",
            JSON.stringify({
              crop_id: selectedCropId,
              branch_id: selectedBranch,
              hub_id: selectedHub,
              variety_id: selectedVariety,
              start_date: selectedDates[0],
              end_date: selectedDates[1],
            })
          );
          // intermediateScreenDataSocketEmition(globalSocketData);

          getUpcomingData(
            selectedDates[0],
            selectedDates[1],
            selectedBranch,
            selectedVariety,
            selectedHub,
            selectedStatus
          );
          message.success("Sauda submitted successfully", 2);
        } else {
          message.error("Something went wrong :(", 1);
          // setUpcomingData([]);
        }
        setIsLoading(false);
      } catch (error) {
        message.error("Something went wrong :(", 1);
        console.log(error);
        setIsLoading(false);
      }
    }
    // else {
    //   message.error("Enter valid details and select atleast one row.", 1)
    //   setIsLoading(false);
    // }
    setIsLoading(false);
  };

  const validate = (dataToPut: any, key: any) => {
    let retVal = true;
    var allotedVal = 0;
    if (
      dataToPut === null ||
      dataToPut === undefined ||
      dataToPut === "" ||
      dataToPut.length === 0
    ) {
      message.error("Enter valid details and select atleast one row.", 1);
      retVal = false;
    }

    if (key === "single" && dataToPut[0].isParent === true) {
      upcomingData.map((item) => {
        if (
          item.brokerSaudaID !== null ||
          item.brokerSaudaID !== undefined ||
          item.brokerSaudaID !== ""
        ) {
          if (item.brokerSaudhaID === dataToPut[0].saudhaId) {
            allotedVal =
              Number(allotedVal) +
              (item.allotedQty !== null &&
              item.allotedQty !== undefined &&
              item.allotedQty !== ""
                ? Number(item.allotedQty)
                : 0);
          }
        }
      });

      var list = upcomingData.filter((item) => {
        if (
          item.brokerSaudaID !== null ||
          item.brokerSaudaID !== undefined ||
          item.brokerSaudaID !== ""
        ) {
          if (item.brokerSaudhaID === dataToPut[0].saudhaId) {
            return item;
          }
        }
      });

      if (Number(allotedVal) !== dataToPut[0].allotedQty) {
        message.error(
          "Modified allotted Qty should be equal to allotted qty.",
          1
        );
        setIsLoading(false);
        retVal = false;
      } else {
        for (let i = 0; i < list.length; i++) {
          let eachRow = list[i];
          // if (
          //   eachRow.conditionId == null ||
          //   eachRow.conditionId == "" ||
          //   eachRow.conditionId == undefined
          // ) {
          //   message.error("Enter Conditions Code(s)..!", 1);
          //   setIsLoading(false);
          //   retVal = false;
          // }
          // else
          if (
            eachRow.vendorCode == null ||
            eachRow.vendorCode == "" ||
            eachRow.vendorCode == undefined
          ) {
            message.error("Select Vendor Code..!", 1);
            setIsLoading(false);
            retVal = false;
          } else if (
            eachRow.materialId == null ||
            eachRow.materialId == "" ||
            eachRow.materialId == undefined
          ) {
            message.error("Select Material Code..!", 1);
            setIsLoading(false);
            retVal = false;
          } else if (
            eachRow.remarks == null ||
            eachRow.remarks == "" ||
            eachRow.remarks == undefined
          ) {
            message.error("Enter remarks..!", 1);
            setIsLoading(false);
            retVal = false;
          }
          if (!retVal) {
            break;
          }
        }
      }
    } else {
      if (retVal === true) {
        for (let i = 0; i < dataToPut.length; i++) {
          let eachRow = dataToPut[i];
          // if (
          //   eachRow.conditionId == null ||
          //   eachRow.conditionId == "" ||
          //   eachRow.conditionId == undefined
          // ) {
          //   message.error("Enter Conditions Code(s)..!", 1);
          //   setIsLoading(false);
          //   retVal = false;
          // }
          // else
          if (
            eachRow.vendorCode == null ||
            eachRow.vendorCode == "" ||
            eachRow.vendorCode == undefined
          ) {
            message.error("Select Vendor Code..!", 1);
            setIsLoading(false);
            retVal = false;
          } else if (
            eachRow.materialId == null ||
            eachRow.materialId == "" ||
            eachRow.materialId == undefined
          ) {
            message.error("Select Material Code..!", 1);
            setIsLoading(false);
            retVal = false;
          } else if (
            eachRow.remarks == null ||
            eachRow.remarks == "" ||
            eachRow.remarks == undefined
          ) {
            message.error("Enter remarks..!", 1);
            setIsLoading(false);
            retVal = false;
          }
          if (!retVal) {
            break;
          }
        }
      }
    }
    // })
    return retVal;
  };

  // useEffect(() => {
  //   setLocalCropID(selectedCropId);
  // }, [selectedCropId]);

  useEffect(() => {
    setSelectedVariety(undefined);
  }, [varietiesList]);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  // const intermediateScreenDataSocketEmition = (passedSocket) => {
  //   let socketCheck =
  //     globalSocketData !== undefined ? globalSocketData : passedSocket;
  //   let params = sessionStorage.getItem("intermediateScreenTMParams");
  //   params = params && JSON.parse(params);

  //   if (socketCheck) {
  //     if (params) {
  //       socketCheck.emit("im_screen_event", params);
  //     }

  //     // setIsLoading(true);
  //     socketCheck.on("im_screen", (data: any) => {
  //       const modifiedData = data.map((item: any, index: any) => {
  //         return {
  //           key: index,
  //           allotedQty: item.alloted_qty,
  //           hubName: item.hub_name,
  //           // saudaDate: item.sauda_date,
  //           // varietyName : item.variety_name,
  //           price: item.price,
  //           qty: item.qty,
  //           rebateId: item.rebate_id,
  //           rebateName: item.rebate_name,
  //           saudhaId: item.saudha_id,
  //           bidId: item.bid_id,
  //           downloadStatus: item.download_status,
  //           supplierId: item.supplier_id,
  //           userName: null,
  //           varietyId: item.variety_id,
  //           bagTypes: [
  //             { id: 1, val: "Old" },
  //             { id: 2, val: "New" },
  //             { id: 3, val: "PP" },
  //           ],
  //           bagId: item.bag_type === null ? 1 : item.bag_type,
  //           remarks: item.remarks,
  //           materialId: item.material_code,
  //           checked: false,
  //           charges: 0,
  //           isBroker: true, //TODO : chnge to item.role_id === 53? true:false,
  //           conditionCodes:
  //             item.conditions && item.conditions.length > 0
  //               ? item.conditions
  //               : conditionCodes,
  //           auctionDate: item.auction_date,
  //           vendorCode: item.vendorCode,
  //           varietyName: item.variety_name,
  //           cropYearList: [
  //             { id: 1, val: new Date().getFullYear() },
  //             { id: 2, val: new Date().getFullYear() - 1 },
  //           ],
  //           cropYear:
  //             item.crop_year === null
  //               ? new Date().getFullYear()
  //               : item.crop_Year,
  //           isParent: false,
  //           isChild: false,
  //           brokerSaudhaID: item.broker_saudha_id,
  //           auctionDivision: item.division,
  //         };
  //       });

  //       setUpcomingData(modifiedData);
  //       setIsLoading(false);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (userData && userData.access_token && globalSocketData) {
  //     intermediateScreenDataSocketEmition(globalSocketData);
  //     intermediateScreenIntervalID = setInterval(() => {
  //       intermediateScreenDataSocketEmition(globalSocketData);
  //     }, 30000);
  //   }
  // }, [userData, globalSocketData]);

  useEffect(() => {
    if (currentpage === "4" && viewAuctionPage === "40") {
      // getConditionsData(selectedBranch);
      getUpcomingData(
        selectedDates[0],
        selectedDates[1],
        selectedBranch,
        selectedVariety,
        selectedHub,
        selectedStatus
      );
      // onBranchChange(selectedBranch, []);
      // setUpcomingData(upcomingData);
      // setMaterialCodesData(materialCode);
    }
  }, [
    currentpage,
    viewAuctionPage,
    selectedDates,
    selectedBranch,
    selectedVariety,
    selectedStatus,
  ]);

  // useEffect(() => {
  //   getConditionsData();
  //   onBranchChange(branches[branchIndex].id, []);
  // }, [branchIndex]);

  // const onEditClicked = async (data: any) => {
  //   console.log(data);
  //   setValueToEdit({
  //     qty_rqd: data.qtyReqd,
  //     rebate_matrix_id: data.rebateId,
  //     auction_id: data.id,
  //     claim: data.claim,
  //   });

  //   setIsEdit(true);
  // };
  const setSelectedBagType = async (
    bagId: string,
    varietyId: number,
    saudaId: number,
    tempkey: number
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            bagId: bagId,
            checked: true,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            bagId: bagId,
            checked: true,
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
    // setMaterialCodesData(data);
  };

  const setSelectedCropYear = async (
    value: any,
    props: any,
    varietyId: number,
    saudaId: number,
    tempkey: number
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            cropYear: value,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            cropYear: value,
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
    // setMaterialCodesData(data);
  };

  const setSelectedMaterialCode = async (
    materialId: any,
    varietyId: number,
    saudaId: number,
    tempkey: number
    // rebateId: any,
    // rebateName: any
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            materialId: materialId,
            checked: true,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            materialId: materialId,
            checked: true,
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
  };

  // useEffect(() => {
  //   sessionStorage.setItem(
  //     "intermediateScreenTMParams",
  //     JSON.stringify({
  //       crop_id: selectedCropId,
  //       branch_id: branches[0]?.id,
  //       hub_id: selectedHub,
  //       variety_id: selectedVariety,
  //       start_date: selectedDates[0],
  //       end_date: selectedDates[1],
  //       download_status: [0],
  //     })
  //   );
  //   // intermediateScreenDataSocketEmition(globalSocketData);
  //   getUpcomingData(
  // selectedDates[0],
  // selectedDates[1],
  // selectedBranch,
  // selectedVariety,
  // selectedHub,
  // selectedStatus
  //   );
  // }, []);

  const setSelectedConditionsDropDown = async (
    e: any,
    row: any,
    index: any,
    target: any,
    props: any
  ) => {
    // let modifiedData = [...upcomingData]
    let modifiedData = upcomingData;
    modifiedData = modifiedData.map((item: any) => {
      if (
        (item.saudhaId === null
          ? item.brokerSaudhaID === row.brokerSaudhaID
          : item.saudhaId === row.saudhaId) &&
        item.varietyId === row.varietyId
      ) {
        let a = item.conditionCodes;

        // item.conditionCodes.map((it) => {
        //   if (it.condition_type === e && val === false) {
        //     message.error("Condition already exists!!!");
        //     val = true;
        //     return false;
        //   }
        // });

        if (target === "condition_name") {
          a[index].condition_name = props.children[0];
          a[index].condition_type = e;
        }

        // console.log(selectV)
        // return {
        // 	...item,
        // 	checked: true,
        // 	conditionCodes: a
        // }

        // setSelectConditions((prevState: any) =>
        //   prevState.filter((item) => item.condition_type !== e)
        // );
        setSelectConditions(
          conditionCodes.filter(
            (rowItem) =>
              !item.conditionCodes.some(
                (conditionItem) =>
                  rowItem.condition_type === conditionItem.condition_type
              )
          )
        );
        row.conditionCodes = a;
        item.conditionCodes = a;
      }
      return item;
      // else {
      //   	console.log("Conditions Data Else ---------->", "item DAta =======>", item);
      //   	return item;
      //   }
    });
    setClickedRowData(row);
    setUpcomingData(modifiedData);
  };

  const setOtherConditionChange = async (
    value: any,
    target: any,
    varietyId: number,
    saudaId: number,
    tempkey: number,
    rowData: any
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          if (target === "buyingModel") {
            rowData.buyingModel = value;
            return {
              ...item,
              buyingModel: value,
            };
          } else if (target === "salesModel") {
            rowData.salesModel = value;
            return {
              ...item,
              salesModel: value,
            };
          } else if (target === "whType") {
            rowData.whType = value;
            return {
              ...item,
              whType: value,
            };
          }
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          if (target === "buyingModel") {
            rowData.buyingModel = value;
            return {
              ...item,
              buyingModel: value,
            };
          } else if (target === "salesModel") {
            rowData.salesModel = value;
            return {
              ...item,
              salesModel: value,
            };
          } else if (target === "whType") {
            rowData.whType = value;
            return {
              ...item,
              whType: value,
            };
          }
        } else {
          return item;
        }
      }
    });
    setClickedRowData(rowData);
    setUpcomingData(modifiedData);
  };

  const setSelectedConditions = async (
    e: any,
    row: any,
    index: any,
    target: any,
    props: any
  ) => {
    let modifiedData = upcomingData;
    modifiedData = modifiedData.map((item: any) => {
      if (
        (item.saudhaId === null
          ? item.brokerSaudhaID === row.brokerSaudhaID
          : item.saudhaId === row.saudhaId) &&
        item.varietyId === row.varietyId &&
        Number(e.target.value) >= 0
      ) {
        let a = item.conditionCodes;
        if (target === "condition_value" && Number(e.target.value) >= 0) {
          a[index].condition_value = Number(e.target.value)
            ? Math.round(Number(e.target.value))
            : e.target.value;
        }

        if (target === "condition_name") {
          a[index].condition_name = e.target.value;
        }

        if (target === "service_vendor_code") {
          row.service_vendor_code =
            e.target.value === "" ? null : Number(e.target.value);
          a[index].service_vendor_code =
            e.target.value === "" ? null : Number(e.target.value);
        }

        // return {
        // 	...item,
        // 	checked: true,
        // 	conditionCodes: a
        // }
        row.conditionCodes = a;
        item.conditionCodes = a;
      }
      console.log("item", item);
      return item;
      // else {
      // 	console.log("Conditions Data Else ---------->", "item DAta =======>", item);
      // 	return item;
      // }
    });
    setClickedRowData(row);
    setUpcomingData(modifiedData);
  };

  const setRemarks = async (
    remarks: any,
    varietyId: number,
    saudaId: number,
    tempkey: number
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            remarks: remarks,
            checked: true,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            remarks: remarks,
            checked: true,
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
  };

  const setVendorCode = async (
    vendorcode: any,
    varietyId: number,
    saudaId: number,
    tempkey: number
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            vendorCode: vendorcode,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            vendorCode: vendorcode,
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
  };

  const setAlottedQty = async (
    qty: any,
    varietyId: number,
    saudaId: number,
    tempkey: number
  ) => {
    const modifiedData = upcomingData.map((item: any) => {
      if (item.saudhaId !== null) {
        if (item.saudhaId === saudaId && item.varietyId === varietyId) {
          return {
            ...item,
            allotedQty: qty,
          };
        } else {
          return item;
        }
      } else {
        if (item.tempkey === tempkey && item.varietyId === varietyId) {
          return {
            ...item,
            allotedQty: qty,
            brokerageCost: Number(qty) * Number(item.brokerage ?? 1),
          };
        } else {
          return item;
        }
      }
    });
    setUpcomingData(modifiedData);
  };

  const handleConditionsClick = async (row: any) => {
    // await getConditionsData(selectedBranch);
    // const modifiedData = upcomingData.map((item: any) => {
    //   if (item.saudhaId === saudaId && item.varietyId === varietyId) {
    //     return {
    //       ...item,
    //       conditionId: conditionId,
    //     };
    //   } else {
    //     return item;
    //   }
    // });
    // setUpcomingData(modifiedData);
    // console.log(upcomingData.map((item:any)=>{

    // }))
    // console.log(conditionCodes)
    // console.log(row.conditionCodes)
    const conditionNamesInArray2 = row.conditionCodes.map(
      (obj) => obj.condition_name
    );
    const resultArray = conditionCodes.filter(
      (obj) => !conditionNamesInArray2.includes(obj.condition_name)
    );
    setSelectConditions(resultArray);
    setClickedRowData(row);
    setConditionsModalVisible(true);
  };

  const getMaterialData = async (
    varietyId: number,
    saudaId: number,
    auctionDivision: number,
    //rebateName: any,
    tempkey: number
    //rebateId: any
  ) => {
    setIsLoading(true);
    const params = {
      variety_id: varietyId,
      auction_division:
        auctionDivision == 1 ||
        Number(auctionDivision) === 1 ||
        String(auctionDivision) === "1"
          ? "ABD"
          : "FBD",
      // version: rebateName.split("_").pop(),
      // rebate_id: rebateName.replace(/_[0-9]+/, ''),
    };
    try {
      const response = await getMaterialCodes(params);
      if (response.data.status) {
        const data = response.data.data.map((item: any) => {
          return {
            key: item.material_no,
            id: item.material_no,
            value: item.material_code,
          };
        });
        const modifiedData = upcomingData.map((item: any) => {
          if (item.saudhaId !== null) {
            if (item.saudhaId === saudaId && item.varietyId === varietyId) {
              return {
                ...item,
                materialCode: data,
              };
            } else {
              return item;
            }
          } else {
            if (item.tempkey === tempkey && item.varietyId === varietyId) {
              return {
                ...item,
                materialCode: data,
              };
            } else {
              return item;
            }
          }
        });
        setUpcomingData(modifiedData);
        // setMaterialCodesData(data);
      } else {
        message.error("Something went wrong :(", 1);
        // setMaterialCodesData([]);
      }
      setIsLoading(false);
    } catch (error) {
      message.error("Something went wrong :(", 1);
      console.log(error);
      setIsLoading(false);
    }
  };

  const getUpcomingData = async (
    startDate: string,
    endDate: string,
    branchId: number,
    varietyID: any,
    hubId: any,
    status: any
  ) => {
    try {
      setIsLoading(true);
      const params = JSON.stringify({
        crop_id: selectedCropId,
        branch_id: branchId,
        hub_id: hubId ? (hubId.length ? hubId : undefined) : selectedHub,
        variety_id: varietyID,
        start_date: startDate,
        end_date: endDate,
        download_status:
          status.length === 0
            ? [0, 1, 2, 3]
            : status && status.length
            ? status
            : [status],
      });
      const response = await getSaudaDetails(params);
      if (response.data.status && response.data.data !== undefined) {
        const data = response.data.data.map((item: any, index: any) => {
          return {
            key: index,
            allotedQty: item.alloted_qty,
            hubName: item.hub_name,
            // saudaDate: item.sauda_date,
            // varietyName : item.variety_name,
            price: item.price,
            qty: item.qty,
            brokerage: item.brokerage,
            brokerageCost:
              Number(item.alloted_qty ?? 0) * Number(item.brokerage ?? 1),
            rebateId: item.rebate_id,
            rebateName: item.rebate_name,
            saudhaId: item.saudha_id,
            bidId: item.bid_id,
            downloadStatus: item.download_status,
            supplierId: item.supplier_id,
            userName: item.user_name,
            varietyId: item.variety_id,
            bagTypes: bagTypeData,
            auctionDate: item.auction_date,
            vendorCode: item.vendor_code,
            varietyName: item.variety_name,
            brokerRemark: item.broker_bid_remark ? item.broker_bid_remark : "",
            cropYearList: [
              { id: 1, val: new Date().getFullYear() },
              { id: 2, val: new Date().getFullYear() - 1 },
            ],
            cropYear:
              item.crop_year === null
                ? 1
                : item.crop_year === new Date().getFullYear().toString()
                ? 1
                : 2,
            bagId: item.bag_type,
            remarks: item.remarks,
            materialId: item.material_code,
            checked: false,
            charges: 0,
            isParent: item.is_parent,
            isChild: false,
            brokerSaudhaID: item.broker_saudha_id,
            auctionDivision: item.division,
            isBroker: item.role_id === 52 ? true : false,
            buyingModel: item.YMM_ABD_CTRM_BUYING_MODEL,
            salesModel: item.YMM_ABD_CTRM_SALES_MODEL,
            whType: item.YMM_ABD_CTRM_WH_TYPE,
            conditionCodes: item.conditions,
          };
        });

        data.map((item) => {
          if (item && item.conditionCodes && item.conditionCodes.length < 5) {
            for (let i = item.conditionCodes.length; i < 5; i++) {
              item.conditionCodes.push({
                condition_name: "",
                condition_type: "",
                condition_value: null,
                service_vendor_code: null,
              });
            }
          }
        });
        setUpcomingData(data);
      } else {
        setUpcomingData([]);
      }
      setIsLoading(false);
    } catch (error) {
      message.error("Something went wrong :(", 1);
      console.log(error);
      setIsLoading(false);
    }
  };

  const onBranchChange = async (value: string | number | any, option: any) => {
    setSelectedHub(undefined);
    setSelectedBranchIndex(value);
  };

  // const cancelAuctionFn = async (auctionId: number) => {
  //   const dataToSend = {
  //     auction_id: auctionId,
  //   };
  //   try {
  //     const response = await cancelAuction(dataToSend);
  //     if (response.data.status) {
  //       message.success("Auction cancelled successfully...!");
  //       // sessionStorage.setItem(
  //       //   "intermediateScreenTMParams",
  //       //   JSON.stringify({
  //       //     crop_id: selectedCropId,
  //       //     branch_id: branches[0]?.id,
  //       //     hub_id: selectedHub,
  //       //     variety_id: selectedVariety,
  //       //     start_date: selectedDates[0],
  //       //     end_date: selectedDates[1],
  //       //     download_status: [0],
  //       //   })
  //       // );
  //       // intermediateScreenDataSocketEmition(globalSocketData);
  //       await getUpcomingData(
  //         selectedDates[0],
  //         selectedDates[1],
  //         selectedBranch,
  //         selectedVariety,
  //         selectedHub,
  //         selectedStatus
  //       );
  //     } else message.warning("Cannot cancel auction...!");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // function showPromiseConfirm(auctionId: number) {
  //   confirm({
  //     title: "Cancel Auction",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "Are you sure you want to cancel the auction ?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk() {
  //       cancelAuctionFn(auctionId);
  //     },
  //     onCancel() {},
  //   });
  // }

  // const getRebate = async (branchId: number) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getRebateService({ branch_id: branchId });
  //     console.log(response.data.data);
  //     setRebates(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // setIsHubLoading(false);
  // };

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined
  ) => {
    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: selectedCropId,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setRebateModalVisible(true);
        setRebateTableData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rebateColumns = [
    {
      title: "Head",
      dataIndex: "head",
      key: "head",
      render(_: any, row: any) {
        return {
          children: row.head,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },
    {
      title: "Slab",
      dataIndex: "slab",
      key: "slab",
    },
    {
      title: "Rebate",
      dataIndex: "rate_display",
      key: "rate_display",
    },
  ];

  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };

  // const onBranchChange = async (value: string | number | any, option: any) => {
  //   sessionStorage.setItem(
  //     "intermediateScreenTMParams",
  //     JSON.stringify({
  //       crop_id: selectedCropId,
  //       branch_id: value,
  //       hub_id: undefined,
  //       variety_id: selectedVariety,
  //       start_date: selectedDates[0],
  //       end_date: selectedDates[1],
  //     })
  //   );
  //   getConditionsData(value);
  //   setSelectedBranchIndex(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   setSelectedHub(undefined);
  //   // setSelectedSlot(undefined);
  //   setSelectedStatus([]);
  //   // getSlotsFn(value);
  //   getHubsFn(value);
  //   // await getConditionsData();
  //   // intermediateScreenDataSocketEmition(globalSocketData);
  //   await getUpcomingData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     value,
  //     selectedVariety,
  //     selectedHub,
  //     selectedStatus
  //   );
  // };

  // const onVarietyChange = (value: any, option: any) => {
  //   if (value === selectedVariety) {
  //     sessionStorage.setItem(
  //       "intermediateScreenTMParams",
  //       JSON.stringify({
  //         crop_id: selectedCropId,
  //         branch_id: selectedBranch,
  //         hub_id: selectedHub,
  //         variety_id: undefined,
  //         start_date: selectedDates[0],
  //         end_date: selectedDates[1],
  //       })
  //     );
  //     setSelectedVariety(undefined);
  //   } else {
  //     sessionStorage.setItem(
  //       "intermediateScreenTMParams",
  //       JSON.stringify({
  //         crop_id: selectedCropId,
  //         branch_id: selectedBranch,
  //         hub_id: selectedHub,
  //         variety_id: value === selectedVariety ? null : value,
  //         start_date: selectedDates[0],
  //         end_date: selectedDates[1],
  //       })
  //     );
  //     setSelectedVariety(value);
  //   }
  //   // intermediateScreenDataSocketEmition(globalSocketData);
  //   getUpcomingData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     value === selectedVariety ? null : value,
  //     selectedHub,
  //     selectedStatus
  //   );
  // };

  // const onHubChange = (value: string | number | any, option: any) => {
  //   if (value === selectedHub) {
  //     sessionStorage.setItem(
  //       "intermediateScreenTMParams",
  //       JSON.stringify({
  //         crop_id: selectedCropId,
  //         branch_id: selectedBranch,
  //         hub_id: undefined,
  //         variety_id: selectedVariety,
  //         start_date: selectedDates[0],
  //         end_date: selectedDates[1],
  //       })
  //     );
  //     setSelectedHub(undefined);
  //   } else {
  //     sessionStorage.setItem(
  //       "intermediateScreenTMParams",
  //       JSON.stringify({
  //         crop_id: selectedCropId,
  //         branch_id: selectedBranch,
  //         hub_id: value === selectedHub ? null : value,
  //         variety_id: selectedVariety,
  //         start_date: selectedDates[0],
  //         end_date: selectedDates[1],
  //       })
  //     );
  //     setSelectedHub(value);
  //   }
  //   // intermediateScreenDataSocketEmition(globalSocketData);
  //   getUpcomingData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     value === selectedHub ? null : value,
  //     selectedStatus
  //   );
  // };

  // const onStatusChange = (value: string | number | any, option: any) => {
  //   if (value === selectedStatus) {
  //     setSelectedStatus(status[0].id);
  //   } else setSelectedStatus(value);

  //   getUpcomingData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     selectedHub,
  //     value
  //   );
  // };

  const onDateChange = (value: string | number | any, timeString: any) => {
    setSelectedDates(timeString);
    // sessionStorage.setItem(
    //   "intermediateScreenTMParams",
    //   JSON.stringify({
    //     crop_id: selectedCropId,
    //     branch_id: selectedBranch,
    //     hub_id: selectedHub,
    //     variety_id: selectedVariety,
    //     start_date: timeString[0],
    //     end_date: timeString[1],
    //   })
    // );
    let start = moment(timeString[0]);
    let end = moment(timeString[1]);
    let differenceInDays = end.diff(start, "days");

    if (differenceInDays > 7) {
      setisTableVisible(false);
    } else {
      setisTableVisible(true);
      // intermediateScreenDataSocketEmition(globalSocketData);
      // getUpcomingData(
      //   timeString[0],
      //   timeString[1],
      //   selectedBranch,
      //   selectedVariety,
      //   selectedHub,
      //   selectedStatus
      // );
    }
  };

  // const getHubsFn = async (branchId: number) => {
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) {}
  // };

  // const getSlotsFn = async (branchId: number) => {
  //   try {
  //     const response = await getSlotIdsService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.slot_id, name: item.slot_name };
  //     });
  //     // setSlots(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleAddRow = async (row: any, length: any) => {
    setIsLoading(true);
    // const element = (
    //   <div>
    //     <Input
    //       type="number"
    //       // value={row.allotedQtyData}
    //       name="allotedQty"
    //       // disabled={revision >= 2}
    //       onChange={(e) => handleNewItemChange(e, e.target.value, length)}
    //     ></Input>{" "}
    //   </div>
    // );

    var vaal, lengths;
    lengths = length;
    let modified = upcomingData.filter((item) => item.saudhaId === null);
    if (modified === null || modified.length <= 0) {
    } else {
      vaal = Math.max(...modified.map((o) => o.tempkey));
      lengths = vaal + 1;
    }
    const bagTypeResponse = await getBagTypeDetails();
    // console.log(bagTypeResponse.data);
    const item = {
      tempkey: lengths,
      allotedQtyData: "",
      conditionCodes: row.conditionCodes,
      // allotedQty:  (<div>
      //   <Input
      //     type="number"

      //     name="allotedQty"
      //     // disabled={revision >= 2}
      //     onChange={(e) => handleNewItemChange(e, e.target.value,length)}
      //   ></Input>,
      // </div>),
      hubName: row.hubName,
      // saudaDate: item.sauda_date,
      // varietyName : item.variety_name,
      price: row.price,
      qty: row.qty,
      brokerage: row.brokerage,
      //    render(<option value={row.qty}></option>,()=>{}),
      brokerSaudhaID: row.saudhaId,
      rebateId: row.rebateId,
      rebateName: row.rebateName,
      saudhaId: null,
      bidId: row.bidId,
      downloadStatus: row.downloadStatus,
      supplierId: row.supplierId,
      userName: null,
      varietyId: row.varietyId,
      // bagTypes: [
      //   { id: 1, val: "Old" },
      //   { id: 2, val: "New" },
      //   { id: 3, val: "PP" },
      // ],
      bagTypes: bagTypeResponse.data.data.reduce((prevState, currState) => {
        prevState.push({
          id: currState.parameter_data_value,
          val: currState.parameter_display_value,
        });
        return prevState;
      }, []),
      cropYearList: [
        { id: 1, val: new Date().getFullYear() },
        { id: 2, val: new Date().getFullYear() - 1 },
      ],

      isChild: true,
      cropYear: row.cropYear,
      bagId: row.bagId,
      // remarks:row.remarks,
      // remarks: [
      //   <TextArea
      //   autoSize={{ minRows: 0, maxRows: 2 }}
      //   style={{ verticalAlign: "middle" }}
      //   maxLength={35}
      //   disabled={row.downloadStatus === 2}
      //   value={row.remarksData}
      //   onChange={(e) => handleNewItemChange(e, e.target.value,length)}
      // />,
      // ],
      materialId: row.materialId,
      checked: row.checked,
      charges: row.charges,
      auctionDate: row.auctionDate,
      isParent: false,
      auctionDivision: row.auctionDivision,
      vendorCodeData: "",
      vendorCode: row.vendorCode,
      //   materialcode:[<div
      //     className={
      //       "select-remarks"
      //       // ${record.mandiBuying.isMandi ||
      //       //     !canUserEdit(
      //       //       user,
      //       //       selectedBranchID,
      //       //       FeatureCodes.BUYING_DECISION_SUBMIT
      //       //     )
      //       //     ? "disable-field"
      //       //     : ""
      //       // }`
      //     }
      //   >
      //     {/* {!record.mandiBuying.isMandi ? ( */}
      //     <Select
      //       // className="remarks-select"
      //       disabled={row.downloadStatus === 2 || row.isParent === true}
      //       // defaultValue={
      //       //   record.remarkId
      //       //     ? allRemarks.find(
      //       //       (item) =>
      //       //         item.remark_id === record.remarkId
      //       //     ).remark
      //       //     : "Add a remark"
      //       // }
      //       onSelect={(value) =>
      //         setSelectedMaterialCode(value, row.varietyId, row.saudhaId)
      //       }
      //       style={{ width: "100%" }}
      //       onClick={() => {
      //         !row.materialCode &&
      //           row.downloadStatus !== 2 &&
      //           (row.materialCode == undefined ||
      //             row.materialCode.length <= 0) &&
      //           getMaterialData(
      //             row.varietyId,
      //             row.saudhaId,
      //             row.auction_division
      //           );
      //       }}
      //       value={row.materialId}
      //     >
      //       {row.materialCode &&
      //         row.materialCode != undefined &&
      //         row.materialCode.length &&
      //         row.materialCode.map((item: any, idx: number) => (
      //           <Option key={item.key} value={item.value}>
      //             {item.value}
      //           </Option>
      //         ))}
      //     </Select>
      //     {/* ) : null} */}
      //   </div>
      // ],
      // vendorCode: [
      //   <Input
      //     type="number"
      //     // value={row.vendorCodeData}
      //     name="vendorCode"
      //     // disabled={revision >= 2}
      //     onChange={(e) => handleNewItemChange(e, e.target.value,length)}
      //   ></Input>,
      // ],
      varietyName: row.varietyName,
      isBroker: false,
    };
    let modifiedDataa = upcomingData;
    modifiedDataa[row.key].isParent = true;
    const newData = modifiedDataa.slice(0); // copy

    newData.splice(upcomingData, row.key, item);

    let mdata = [
      ...modifiedDataa.slice(0, row.key + 1),
      item,
      ...modifiedDataa.slice(row.key + 1),
    ];

    mdata.map((item: any, index: any) => {
      item.key = index;

      return item;
    });

    // setModData(mdata);
    //     upcomingData.push(item);

    // let modifiedData = upcomingData;

    // console.log("modifiedData ", modifiedData);

    // const modifiedData = upcomingData.map((item: any) => {

    //     return item;

    // });

    await setUpcomingData(mdata);

    setIsLoading(false);
    setisTableVisible(true);
  };

  // useEffect(() => {
  //   setModData(upcomingData);
  // }, [upcomingData]);

  const handleDeleteRow = async (row: any) => {
    var val = 0;
    var index1 = 0;

    const reducedArr = [...upcomingData];
    reducedArr.map((item: any, index: any) => {
      if (item.brokerSaudhaID === row.brokerSaudhaID) {
        // item.isParent = false;
        index1 = item.key;
        val = val + 1;
        isparent = false;
      }

      return item;
    });

    var isparent = true;
    reducedArr.splice(row.key, 1);

    reducedArr.map((item: any, index: any) => {
      item.key = index;
      return item;
    });

    // console.log("val1 ", val, index1);
    if (val === 1) {
      reducedArr[index1 - 1].isParent = false;
    }

    setUpcomingData(reducedArr);
    isparent = true;
    val = 0;

    // let modifiedData = {...upcomingData};
    // const newlist = modifiedData.splice(row.key, 1);
    //   // const newList = modifiedData.splice(modifiedData.indexOf(row.key), 1);
    //   console.log("deleted ", newlist);
  };

  const handleNewItemChange = async (e: any, value: any, key: any) => {
    // const modifiedData = upcomingData.map((item: any, index: any) => {
    //  if(item.tempkey === key){
    //    return({
    //      ...item,
    //      [e.target.name]:e.target.value,
    //    })
    //  }
    //  else{
    //    return item;
    //  }
    // });
    // console.log("new item changed modifiedData ", modifiedData);
    // setUpcomingData(modifiedData);
  };

  // const fileDownload = async () => {
  //   setIsSpinnerOnProgress(true);
  //   const body = {
  //     start_date: selectedDates[0],
  //     end_date: selectedDates[1],
  //     branch_id: selectedBranch,
  //     crop_id: selectedCropId,
  //     // selectedVariety,
  //     // selectedHub,
  //     // selectedStatus,
  //     download: true,
  //   };

  //   const fileresponse = await generateSaudaUrl(body);

  //   if (fileresponse) {
  //     if (fileresponse.data.status) {
  //       //#region file download
  //       getUpcomingData(
  //         selectedDates[0],
  //         selectedDates[1],
  //         selectedBranch,
  //         selectedVariety,
  //         selectedHub,
  //         selectedStatus
  //       );
  //       Axios({
  //         url:
  //           "http://abdsourcingwfe.southindia.cloudapp.azure.com:8085/aashirvaad/v1/test_download",
  //         method: "GET",
  //         responseType: "blob", // important to specify responseType as 'blob' to handle binary data
  //       })
  //         .then((response) => {
  //           // Create a temporary anchor element to trigger the file download
  //           const downloadUrl = window.URL.createObjectURL(
  //             new Blob([response.data])
  //           );
  //           const link = document.createElement("a");
  //           link.href = downloadUrl;
  //           link.setAttribute("download", "users.xlsx");
  //           document.body.appendChild(link);
  //           link.click();
  //           link.remove();
  //           setIsSpinnerOnProgress(false);
  //           setisProgressClicked(false);
  //         })
  //         .catch((error) => {
  //           console.error("Error downloading Excel file:", error);
  //           setIsSpinnerOnProgress(false);
  //           setisProgressClicked(false);
  //         });

  //       //#endregion
  //     } else {
  //       message.warning("File has no data");
  //       setIsSpinnerOnProgress(false);
  //       setisProgressClicked(false);
  //     }
  //   } else {
  //     message.error("Error downloading file");
  //     setIsSpinnerOnProgress(false);
  //     setisProgressClicked(false);
  //   }
  // };

  return (
    // <Spin
    //   spinning={isSpinnerOnProgress}
    //   tip="Please wait while the file is bieng Downloaded..."
    // >
    <div className="upcoming-auction">
      <span>
        {/* <Modal
        className="edit-modal"
        visible={isEdit}
        onCancel={() => {
          setIsEdit(false);
          setValueToEdit(editDefault);
        }}
        footer={false}
        maskClosable={false}
      >
        <div className="header">Edit Auction</div>
        <div className="form-container">
          <div className={`form - items`}>
            <div className="label">Quantity</div>
            <Input
              value={valueToEdit.qty_rqd}
              type="number"
              placeholder="Slot Qty"
              onChange={(e) =>
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  qty_rqd: e.target.value,
                }))
              }
            />
          </div>

          {valueToEdit.claim === 1 && (
            <div className={`form - items`}>
              <div className="label help">
                Rebate Name {/* {rebateId && ( //
                <div
                  className="info"
                  onClick={() => {
                    getRebateMatrix(
                      valueToEdit.rebate_matrix_id,
                      selectedBranch
                    );
                  }}
                >
                  ?
                </div>
                {/* )} //
              </div>

              <Select
                placeholder="Rebate Name"
                value={valueToEdit.rebate_matrix_id}
                onChange={(value) =>
                  setValueToEdit((prevState: any) => ({
                    ...prevState,
                    rebate_matrix_id: value,
                  }))
                }
              >
                {rebates &&
                  rebates.map((item: any) => (
                    <Option
                      value={item.rebate_id}
                      id={item.rebate_id}
                      key={item.rebate_id}
                    >
                      {item.rebate_name}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
        </div>
        <div className="update-btn-container">
          <Button onClick={updateAuction}>Update</Button>
        </div>
      </Modal> */}
      </span>
      <Modal
        className="rebate-modal"
        visible={rebateModalVisible}
        onCancel={() => setRebateModalVisible(false)}
        footer={false}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={rebateColumns}
            // dataSource={tableData}
            dataSource={createNewArr(rebateTableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>

      <Modal
        footer={false}
        visible={conditionsModalVisible}
        onCancel={() => {
          setConditionsModalVisible(false);
        }}
        className="submit-modal"
        width="max-content"
      >
        <div className="conditions-header" style={{ fontWeight: "normal" }}>
          Conditions
        </div>
        <div className="rebate-container">
          <div className="hub-form-container">
            {/* <Form
							layout="horizontal"
							//   onValuesChange={onFormLayoutChange}
							size={"small"}
						// onSubmit={handleSubmit}
						> */}
            <div className="form-top-container">
              <div className="row">
                <div
                  style={{
                    color: "#154479",
                    fontWeight: 500,
                    marginLeft: "8%",
                  }}
                >
                  TCD
                </div>
                <div
                  style={{
                    color: "#154479",
                    fontWeight: 500,
                    marginLeft: "12%",
                  }}
                >
                  Value
                </div>
                <div
                  style={{
                    color: "#154479",
                    fontWeight: 500,
                    marginRight: "3%",
                  }}
                >
                  Service Vendor Code
                </div>
              </div>
              {upcomingData &&
                clickedRowData &&
                upcomingData.length &&
                clickedRowData.conditionCodes
                  .filter((item, idx) => idx < 5)
                  .map((item: any, index: any) => {
                    for (let i = 0; i < 4; i++) {
                      return (
                        <>
                          <div className="row">
                            <div className="left">
                              {/* {row.condition_name + " (" + row."condition_type" + ")"} */}
                              <Select
                                // disabled={
                                //   clickedRowData.isParent
                                // }
                                style={{
                                  width: "220px",
                                  marginRight: "25px",
                                }}
                                className="select-year"
                                value={
                                  item.condition_type !== ""
                                    ? item.condition_name +
                                      " (" +
                                      item.condition_type +
                                      ")"
                                    : item.condition_name
                                }
                                onSelect={(value, props) => {
                                  setSelectedConditionsDropDown(
                                    value,
                                    clickedRowData,
                                    index,
                                    "condition_name",
                                    props
                                  );
                                }}
                              >
                                {selectConditions &&
                                  selectConditions !== undefined &&
                                  selectConditions.length &&
                                  selectConditions
                                    .filter(
                                      (item, idx) => item.condition_name !== ""
                                    )
                                    .map((bag: any, idx: number) => (
                                      <Option
                                        key={bag.condition_type}
                                        value={bag.condition_type}
                                      >
                                        {bag.condition_name} (
                                        {bag.condition_type})
                                      </Option>
                                    ))}
                              </Select>
                            </div>
                            <div className="middle">
                              <Input
                                type="number"
                                value={item.condition_value}
                                name="condition_value"
                                disabled={item.condition_type === ""}
                                onChange={(e) =>
                                  setSelectedConditions(
                                    e,
                                    clickedRowData,
                                    index,
                                    "condition_value",
                                    null
                                  )
                                }
                                // onClick={(e) => setSelectedConditions(e, clickedRowData, index)}
                              />
                              <div className="unit">INR (₹)/MT</div>
                            </div>
                            <div className="right">
                              <Input
                                type="text"
                                value={item.service_vendor_code}
                                name="service_vendor_code"
                                // disabled={revision >= 2}
                                disabled={item.condition_value === null}
                                onChange={(e) =>
                                  setSelectedConditions(
                                    e,
                                    clickedRowData,
                                    index,
                                    "service_vendor_code",
                                    "service_vendor_code"
                                  )
                                }
                                maxLength={9}
                                // onClick={(e) => setSelectedConditions(e, clickedRowData, index)}
                              />
                              {/* <div className="unit">INR (₹)</div> */}
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
            </div>
            {/* <div className="form-bottom-container">
                <Form.Item>
                  <Button
                    // disabled={this.state.isSubmitted}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div> */}
            {/* </Form> */}
          </div>
        </div>
        {selectedCropId === 2 && clickedRowData !== undefined && (
          <div
            className="conditions-header"
            style={{ marginTop: 25, fontWeight: "normal" }}
          >
            Other Conditions
          </div>
        )}
        {selectedCropId === 2 && clickedRowData !== undefined && (
          <div className="rebate-container">
            <div className="hub-form-container">
              <div className="form-top-container">
                <div className="row">
                  <div
                    style={{
                      color: "#154479",
                      fontWeight: 500,
                      marginLeft: "8%",
                    }}
                  >
                    Conditions
                  </div>
                  <div
                    style={{
                      color: "#154479",
                      fontWeight: 500,
                      marginRight: "20%",
                    }}
                  >
                    Value
                  </div>
                </div>
                <div className="row">
                  <div className="left" style={{ width: "45%" }}>
                    YMM_ABD_CTRM_BUYING_MODEL
                  </div>
                  <div className="other-right">
                    <Input
                      type="text"
                      value={clickedRowData.buyingModel}
                      name="buyingModel"
                      //  disabled={s}
                      onChange={(e) =>
                        setOtherConditionChange(
                          e.target.value,
                          e.target.name,
                          clickedRowData.varietyId,
                          clickedRowData.saudhaId,
                          clickedRowData.tempkey,
                          clickedRowData
                        )
                      }
                      // onClick={(e) => setSelectedConditions(e, clickedRowData, index)}
                    />
                    {/* <div className="unit">INR (₹)</div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="left" style={{ width: "45%" }}>
                    YMM_ABD_CTRM_SALES_MODEL
                  </div>
                  <div className="other-right">
                    <Input
                      type="text"
                      value={clickedRowData.salesModel}
                      name="salesModel"
                      onChange={(e) =>
                        setOtherConditionChange(
                          e.target.value,
                          e.target.name,
                          clickedRowData.varietyId,
                          clickedRowData.saudhaId,
                          clickedRowData.tempkey,
                          clickedRowData
                        )
                      }
                    />
                    {/* <div className="unit">INR (₹)</div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="left" style={{ width: "45%" }}>
                    YMM_ABD_CTRM_WH_TYPE
                  </div>
                  <div className="other-right">
                    <Input
                      type="text"
                      value={clickedRowData.whType}
                      name="whType"
                      onChange={(e) =>
                        setOtherConditionChange(
                          e.target.value,
                          e.target.name,
                          clickedRowData.varietyId,
                          clickedRowData.saudhaId,
                          clickedRowData.tempkey,
                          clickedRowData
                        )
                      }
                      // onClick={(e) => setSelectedConditions(e, clickedRowData, index)}
                    />
                    {/* <div className="unit">INR (₹)</div> */}
                  </div>
                </div>
              </div>
              {/* ))
                )} */}
            </div>
            {/* <div className="form-bottom-container">
                <Form.Item>
                  <Button
                    // disabled={this.state.isSubmitted}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div> */}
            {/* </Form> */}
          </div>
        )}
        {/* </div> */}
      </Modal>

      <div className="filter-container" style={{ marginLeft: "5%" }}>
        {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="ITC Branch Office"
          returnId
        /> */}
        <CustomFilter
          type="range-picker"
          head="Date Range"
          startDate={moment()}
          endDate={moment()}
          onChange={onDateChange}
        />
        {/* <CustomFilter
          type="single-select"
          options={crops}
          placeholder="Select Crop"
          head="Crop"
        /> */}
        <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          placeholder="Select Branch"
          head="ITC Branch Office"
          returnId
        />
        <CustomFilter
          // type="single-select"
          type="multi-select"
          value={selectedHub}
          options={hubs}
          onChange={(value: any) => {
            setSelectedHub(value);
            getUpcomingData(
              selectedDates[0],
              selectedDates[1],
              selectedBranch,
              selectedVariety,
              value,
              selectedStatus
            );
          }}
          placeholder="Select Hub"
          head="Hub"
          returnId
        />
        <CustomFilter
          // type="single-select"
          type="multi-select"
          value={selectedVariety}
          onChange={(value: any) => setSelectedVariety(value)}
          options={varietiesList}
          placeholder="Select Variety"
          head="Variety"
          returnId
        />
        {/* <CustomFilter
          type="single-select"
          value={selectedSlot}
          options={slots}
          onChange={onSlotChange}
          placeholder="Select Slot"
          head="Slot"
          returnId
        /> */}
        <CustomFilter
          type="multi-select"
          value={selectedStatus}
          options={status}
          onChange={(value: any) => setSelectedStatus(value)}
          placeholder="Select Status"
          className="status-filter-approval"
          head="Status"
          returnId
        />
        <div className="selected-wheat">
          <span className="selected-wheat-label">Selected Crop</span> <br />
          <span className="selected-wheat-value">
            <b>
              {crops &&
                crops.length &&
                crops.find(
                  (item: { id: number; name: string }) =>
                    item.id == selectedCropId
                ).name}
            </b>
          </span>
        </div>
        {isTableVisible && (
          <Button
            className="refresh"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() =>
              // intermediateScreenDataSocketEmition(globalSocketData)
              getUpcomingData(
                selectedDates[0],
                selectedDates[1],
                selectedBranch,
                selectedVariety,
                selectedHub,
                selectedStatus
              )
            }
          >
            Refresh
          </Button>
        )}
      </div>
      {isTableVisible ? (
        <div className="upcoming-table-container">
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={upcomingData}
            pagination={false}
            // expandable={{
            //   expandedRowRender: record => <p style={{ margin: 0 }}>{record.userName}</p>,
            //   // rowExpandable: record => record.name !== 'Not Expandable',
            // }}
            scroll={{ y: window.innerHeight - 339 }}
            footer={() => {
              return (
                upcomingData?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // width: "220px",
                    }}
                  >
                    <div>
                      {/* <Button
                        className={"submit-btn"}
                        // onClick={() => <ActionItem
                        //   canUserSubmit={canUserEdit(
                        //     user,
                        //     selectedBranchID,
                        //     FeatureCodes.BUYING_DECISION_SUBMIT
                        //   )}
                        //   key="submitAll"
                        //   // this.props.handleSubmitClick(this.props.hubTableData)}
                        //   postBuyingDecisionHub={this.props.handleSubmitClick}
                        //   rowRecord={this.props.hubTableData}
                        //   // postHubRowId={record.actions.hubID}
                        //   // sourceType={record.mandiBuying.sourceType}
                        //   // srp={record.srp}
                        // />
                        // }
                        onClick={() => onCheckBoxChange(upcomingData, "multi")}
                      >
                        Submit
                      </Button> */}
                    </div>
                  </div>
                )
              );
            }}
          />
        </div>
      ) : (
        <Alert
          style={{ margin: "10px" }}
          message="Informational Notes"
          description="The data for the selected time period is too big."
          type="info"
          showIcon
        />
      )}
      {/* <div className="progress-download-container">
          {isProgressClicked && (
            <Progress
              type="circle"
              percent={downloaddata}
              width={37}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              style={{
                right: "232px",
                position: "absolute",
                display: "flex",
                marginTop: "-47px",
              }}
              trailColor="#003285"
            />
          )}
        </div> */}
      {/* <div className="download-button-container">
          {
            <Button
              onClick={() => {
                setdownloaddata(0);
                //setisProgressClicked(true);
                fileDownload();
                // const fileHeaders = {
                //   Authorization: "Bearer " + userData.access_token,
                // };
                // const params = {
                //   start_date: selectedDates[0],
                //   end_date: selectedDates[1],
                //   branch_id: [selectedBranch],
                //   // selectedVariety,
                //   // selectedHub,
                //   // selectedStatus,
                //   download: true,
                // };
                // API.post(`/generate_sauda`, {
                //   headers: fileHeaders,
                //   params: params,
                // })
                //   .then((fileresponse: any) => {
                //     if (fileresponse.data.status) {
                //       //#region file download
                //       getUpcomingData(
                //         selectedDates[0],
                //         selectedDates[1],
                //         selectedBranch,
                //         selectedVariety,
                //         selectedHub,
                //         selectedStatus
                //       );
                //       Axios({
                //         url: `./${fileresponse.data.data}`,
                //         method: "GET",
                //         responseType: "arraybuffer",
                //       })
                //         .then((response: any) => {
                //           FileDownLoad(
                //             response.data,
                //             `${fileresponse.data.data}`
                //           );
                //         })
                //         .catch((err: any) => {
                //           message.error("File cannot be downloaded");
                //         });
                //       //#endregion
                //     } else {
                //       message.warning("File has no data");
                //     }
                //   })
                //   .catch((err: any) => {
                //     message.error("Error downloading file");
                //   });
              }}
              type="primary"
              shape="round"
              // icon="download"
              size="large"
              // style={{ display: "block" }}
              className="download-btn"
            >
              <div>
                <div style={{ marginTop: "-7px" }}>Download</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xls report
                </div>
              </div>
            </Button>
          }
        </div> */}
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  crops: selectCrops,
  branches: selectBranches,
  selectedCropId: selectSelectedCrop,
  selectedBranch: selectSelectedBranch,
  varietiesList: selectVarieties,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  hubs: selectHubs,
  branchParams: selectBranchParams,
});

export default connect(mapStateToProps, mapDispatchToProps)(IntermediateScreen);
