import { CommonActionTypes } from "./common.types";
import {
  ArrayFormat,
  defaultparams,
  branchparams,
  cropparams,
  RebateArrayFormat,
} from "../../models";

export const setBranchParamsRefetch = (status: boolean): any => {
  return {
    type: CommonActionTypes.SET_REFETCH,
    payload: status,
  };
};
export const setBranches = (branches: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_BRANCHES,
    payload: branches,
  };
};
export const setVarieties = (varieties: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_VARIETIES,
    payload: varieties,
  };
};
export const setBagtypeList = (bagtypeList: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_BAGTYPE,
    payload: bagtypeList,
  };
};
export const setDivisionList = (divisionList: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_DIVISION,
    payload: divisionList,
  };
};
export const setCrops = (crops: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_CROPS,
    payload: crops,
  };
};
export const setHubs = (hubs: Array<ArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_HUBS,
    payload: hubs,
  };
};
export const setRebates = (rebates: Array<RebateArrayFormat>): any => {
  return {
    type: CommonActionTypes.SET_REBATES,
    payload: rebates,
  };
};
export const setSelectedCrop = (cropId: number): any => {
  return {
    type: CommonActionTypes.SET_SELECTED_CROP,
    payload: cropId,
  };
};
export const setSelectedBranch = (branchId: number): any => {
  return {
    type: CommonActionTypes.SET_SELECTED_BRANCH,
    payload: branchId,
  };
};
export const setSelectedMultiBranch = (branchId: number): any => {
  return {
    type: CommonActionTypes.SET_SELECTED_MULTIBRANCH,
    payload: branchId,
  };
};
export const setBypassBD = (status: boolean): any => {
  return {
    type: CommonActionTypes.SET_BYPASS_BD,
    payload: status,
  };
};
export const setCurrentPage = (activeKey: string): any => {
  return {
    type: CommonActionTypes.SET_CURRENT_PAGE,
    payload: activeKey,
  };
};
export const setViewAuctionPage = (activeKey: string): any => {
  return {
    type: CommonActionTypes.SET_VIEW_AUCTION_PAGE,
    payload: activeKey,
  };
};
export const setSlotPage = (activeKey: string): any => {
  return {
    type: CommonActionTypes.SET_SLOT_PAGE,
    payload: activeKey,
  };
};
export const setOpenAuctionBranch = (branchId: number): any => {
  return {
    type: CommonActionTypes.SET_OPEN_AUCTION_BRANCH,
    payload: branchId,
  };
};
export const setBypassEnabled = (status: boolean): any => {
  return {
    type: CommonActionTypes.SET_BYPASS_ENABLED,
    payload: status,
  };
};
export const setTC = (htmlText: string): any => {
  return {
    type: CommonActionTypes.SET_TC,
    payload: htmlText,
  };
};
export const setDefaultParams = (param: defaultparams): any => {
  return {
    type: CommonActionTypes.SET_DEFAULT_PARAMS,
    payload: param,
  };
};
export const setBranchParams = (param: branchparams): any => {
  return {
    type: CommonActionTypes.SET_BRANCH_PARAMS,
    payload: param,
  };
};
export const setCropParams = (param: cropparams): any => {
  return {
    type: CommonActionTypes.SET_CROP_PARAMS,
    payload: param,
  };
};
export const resetCommonReducer = (): any => {
  return {
    type: CommonActionTypes.RESET_REDUCER,
  };
};
