import React, { useState, useEffect, useMemo } from "react";
import "./copy-auction.styles.scss";
import CustomSelect from "../../components/custom-select/custom-select.component";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";

import IndividualItem from "../../components/slot-individual-item/slot-individual-item.component";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import moment from "moment";

import {
  setSelectedBranch,
  setVarieties,
} from "../../redux/common/common.actions";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectVarieties,
  selectBranches,
  selectCrops,
  selectSelectedCrop,
  selectTC,
  selectCurrentPage,
  selectSelectedBranch,
  selectMinQty,
  selectSaudhaType,
  selectRebates,
  selectBypassBD,
  selectBagtypeList,
} from "../../redux/common/common.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";
import { Dispatch } from "redux";
import {
  getBuyingDecision,
  getCopyAuctionsData,
  postAuction,
  getSlotAuctions,
  getMaterialCodes,
} from "../../services/auction-services";
// import { getSysParams } from "../../services/sys-params-services";
import { getRebateService } from "../../services/common-services";
import socketIOClient from "socket.io-client";
import { dataConstants } from "../../constants/data-constants";

import {
  Table,
  Input,
  Radio,
  DatePicker,
  Button,
  message,
  Modal,
  Checkbox,
} from "antd";
import { selectUserData } from "../../redux/user/user.selectors";
import Item from "antd/lib/list/Item";
const { TextArea } = Input;
const { confirm } = Modal;

interface ITableData {
  key: number | undefined;
  hub: string | undefined;
  division: number | undefined;
  claim: number | undefined;
  quantity: number | undefined;
  min: number | undefined;
  max: number | undefined;
}

interface ISlotsGet {
  slot_id: number;
  qty_rqd: number | null;
  qty_fulfilled: number | null;
  rebate_id: string | null;
}

interface IFormData {
  crop_id: number | null;
  branch_id: number | null;
  variety_id: number | null;
  hub_id: number | null;
  hub_name: string | null;
  division: number | null;
  saudaType: number | null;
  saudha_type: number | null;
  claim_type: number | null;
  role_type: number | null;
  max_price: number | null;
  min_price: number | null;
  need_date: string | null;
  slots: Array<any> | null;
  remarks: string | null;
  bag_type: number | null;
  del_loc_sap_code: string | null;
  buying_decision_id: number | null;
  total_qty: number;
  normal_auction_exists: boolean | null;
  withBd: boolean | null;
}

const CopyAuction: React.FC = (props: any) => {
  const {
    varieties,
    branches,
    selectedCrop,
    crops,
    currentPage,
    selectedBranch,
    minQty,
    saudhaType,
    rebates,
    setSelectedBranchIndex,
    bypassEnabled,
    bagTypeList,
  } = props;
  const formDefault = {
    crop_id: null,
    branch_id: null,
    variety_id: null, // varieties[0]?.id,
    hub_id: null,
    division: null,
    del_loc_sap_code: null,
    bag_type: null,
    saudha_type: null,
    role_type: null,
    claim_type: null,
    max_price: null,
    min_price: null,
    need_date: null,
    slots: null,
    remarks: "",
    buying_decision_id: null,
    normal_auction_exists: null,
    hub_name: "",
    needDate: null,
    saudaType: null,
    roleType: null,
    varietyName: null,
    total_qty: 0,
    withBd: null,
  };

  // const [varietiesList, setVarietiesList] = useState<any>([]);
  const [tableData, setTableData] = useState<Array<ITableData>>([]);
  // const [rebates, setRebates] = useState([]);
  const [editable, setEditable] = useState(true);
  const [formData, setFormData] = useState<IFormData>(formDefault);
  const [modalVisible, setModalVisible] = useState(false);
  const [userinfo, setUserInfo] = useState<any>({
    selectedRole: [],
    response: [],
  });
  const [roleCheck1, setroleCheck1] = useState(false);
  const [roleCheck2, setroleCheck2] = useState(false);
  const [bdLoading, setBdLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rebateList, setRebateList] = useState<any>([]);
  const [locationList, setLocation] = useState<any>([]);
  const [materialList, setMaterialList] = useState<any>([]);
  // const [minQtyShow, setMinQtyShow] = useState<any>();
  // const [globalSocketData, setGlobalSocket] = useState<any>();
  let byPassBDIntervalID;

  const columns = [
    {
      title: "Hub",
      children: [
        {
          dataIndex: "hub_name",
          key: "hub_name",
        },
      ],
    },
    // {
    //   title: "Variety ",
    //   children: [
    //     {
    //       dataIndex: "variety_name",
    //       key: "variety_name",
    //     },
    //   ],
    // },
    {
      title: "Claim Type",
      children: [
        {
          dataIndex: "claim_type",
          key: "claim_type",
          render: (value: number) => {
            return (
              <div className="claim-container">
                {value === 1 ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Division ",
      children: [
        {
          dataIndex: "division",
          key: "division",
          render: (value: number) => {
            return (
              <div>{value === 1 ? "ABD" : value === 2 ? "FBD" : "NCDEX"}</div>
            );
          },
        },
      ],
    },
    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudha_type",
          key: "saudha_type",
          render: (value: number) => {
            return (
              // <div className="claim-container">
              value === 1 ? "Net Basis" : "Gross Basis"
              // </div>
            );
          },
        },
      ],
    },
    {
      title: "Role",
      children: [
        {
          dataIndex: "role_mapping",
          key: "role_mapping",
          render: (value: number) => {
            return value === 1
              ? "Broker/Trader"
              : value === 2
              ? "FPO"
              : value === 3
              ? "Broker/Trader & FPO"
              : "Not Selected";
          },
        },
      ],
    },
    {
      title: "Auction Qty",
      children: [
        {
          title: "MT",
          dataIndex: "qty_rqd",
          key: "qty_rqd",
        },
      ],
    },
    {
      title: "Min Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "min_price",
          key: "min_price",
        },
      ],
    },
    {
      title: "Max Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "max_price",
          key: "max_price",
        },
      ],
    },
    {
      title: "Auction Date",
      children: [
        {
          dataIndex: "auction_date",
          key: "auction_date",
        },
      ],
    },
    {
      title: "",
      children: [
        {
          dataIndex: "create",
          key: "create",
          render: (value: any, rowData: any) => {
            return (
              <div
                className="create-auction-link"
                // href="#"
                onClick={() => {
                  const params = {
                    crop_id: formData.crop_id,
                    branch_id: formData.branch_id,
                    hub_id: rowData.hub_id,
                    variety_id: formData.variety_id,
                    claim_type: rowData.claim_type,
                    division: rowData.division,
                    // hub_name: rowData.hub_name,
                  };
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    division: rowData.division,
                    hub_id: rowData.hub_id,
                    hub_name: rowData.hub_name,
                    claim_type: rowData.claim_type,
                    max_price: rowData.max_price,
                    min_price: rowData.min_price,
                    buying_decision_id: rowData.key,
                    total_qty: rowData.qty_rqd,
                    needDate: rowData.AuctionDate,
                    saudaType: rowData.saudha_type,
                    role_type: rowData.role_mapping,
                    varietyName: rowData.variety_name,
                    slots: [],
                    allSlots: rowData.all_slots,
                  }));
                  const rebateParams = {
                    crop_id: formData.crop_id,
                    branch_id: formData.branch_id,
                    variety_id: formData.variety_id,
                    division: rowData.division_name,
                  };
                  getVarietyData(rebateParams);
                  getMaterialData({
                    variety_id: formData.variety_id,
                    auction_division: rowData.division_name,
                  });
                  // getParamsFnWithOutLoad(formData.branch_id, formData.crop_id);
                  getSlotAuctionDetails(params, rowData);
                  // getRebate(formData.branch_id, formData.crop_id);
                }}
              >
                Copy Auction
              </div>
            );
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (currentPage === "2" && !bypassEnabled) {
      setFormData({
        ...formDefault,
        crop_id: selectedCrop,
        branch_id: selectedBranch,
        saudha_type: +saudhaType,
        variety_id: varieties[0]?.id,
      });
      getAuction(selectedBranch, varieties[0]?.id, selectedCrop);
      // getParamsFn(selectedBranch, selectedCrop, true, true);
      // getDefaultSaudhaType(formData.branch_id, formData.crop_id);
      // byPassBDDataSocketEmition(globalSocketData);
    }
  }, [currentPage, selectedCrop, selectedBranch]);

  // useMemo(()=>formData.branch_id && formData.variety_id && getCopyData(formData.branch_id, formData.variety_id),[formData.branch_id,formData.variety_id]);
  // useEffect(() => {
  //   setSaudhaAndRebate();
  // }, [selectSelectedBranch]);

  // useEffect(() => {
  //   let cropIDSelected = sessionStorage.getItem("cropID");

  //   // getCopyData(formData.branch_id, formData.variety_id);
  //   setFormData({
  //     ...formData,
  //     crop_id: sessionStorage.getItem("cropID")
  //       ? cropIDSelected && JSON.parse(cropIDSelected)
  //       : selectedCrop,
  //   });
  // }, [selectedCrop]);

  // useEffect(() => {
  //   onSelectedBranch(selectedBranch);
  // }, [selectedBranch]);

  // const getParamsFn = async (
  //   branchId?: number,
  //   cropId?: number,
  //   variety?: boolean,
  //   initial?: boolean
  // ) => {
  //   try {
  //     setBdLoading(true);
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: cropId,
  //     });
  //     let valueCheck = branchResponse.data.data.filter(
  //       (item) => item.parameter_type === "minimum_quantity"
  //     );
  //     setSysParametersData(valueCheck[0].parameter_data_value);
  //     setBdLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setBdLoading(false);
  //   }
  // };

  // const getParamsFnWithOutLoad = async (
  //   branchId?: number,
  //   cropId?: number,
  //   variety?: boolean,
  //   initial?: boolean
  // ) => {
  //   try {
  //     console.log(branchId);
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: cropId,
  //     });
  //     let valueCheck = branchResponse.data.data.filter(
  //       (item) => item.parameter_type === "minimum_quantity"
  //     );
  //     setSysParametersData(valueCheck[0].parameter_data_value);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  // const byPassBDDataSocketEmition = (passedSocket) => {
  //   let socketCheck =
  //     globalSocketData !== undefined ? globalSocketData : passedSocket;
  //   let params = sessionStorage.getItem("createAuctionBDTMParams");
  //   params = params && JSON.parse(params);

  //   if (socketCheck) {
  //     if (params) {
  //       socketCheck.emit("tm_buying_decision_event", params);
  //     }

  //     socketCheck.on("tm_buying_decision", (data: any) => {
  //       const modifiedData = data.map((item: any) => {
  //         return {
  //           key: item.buying_decision_id,
  //           hub: item.hub_name,
  //           division: item.division,
  //           claim: item.claim_type,
  //           quantity: item.qty,
  //           min: item.min_price,
  //           max: item.max_price,
  //           hubId: item.hub_id,
  //         };
  //       });
  //       setTableData(modifiedData);
  //       setBdLoading(false);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   // const socketURL = "http://abdsourcingwfe.southindia.cloudapp.azure.com:8085/auctions";
  //   const socket = socketIOClient(dataConstants.socketURL, {
  //     transports: ["websocket"],
  //     query: {
  //       Authorization: userData.access_token,
  //     },
  //   });
  //   socket.on("connect", () => {
  //     if (userData && userData.access_token && socket.connected) {
  //       setGlobalSocket(socket);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   // setVarietiesList(varieties);
  //   sessionStorage.setItem(
  //     "createAuctionBDTMParams",
  //     JSON.stringify({
  //       variety_id: varieties[0]?.id,
  //       branch_id: selectedBranch,
  //     })
  //   );

  //   //getCopyData(formData.branch_id, varieties[0]?.id);
  //   setFormData({
  //     ...formData,
  //     variety_id: varieties[0]?.id,
  //   });
  // }, [varieties]);

  // useEffect(() => {
  //   sessionStorage.setItem(
  //     "createAuctionBDTMParams",
  //     JSON.stringify({
  //       variety_id: formData.variety_id,
  //       branch_id: formData.branch_id,
  //     })
  //   );
  //   if (userData && userData.access_token && globalSocketData) {
  //     // setBdLoading(true);
  //     byPassBDDataSocketEmition(globalSocketData);
  //     byPassBDIntervalID = setInterval(() => {
  //       byPassBDDataSocketEmition(globalSocketData);
  //     }, 30000);
  //   }
  // }, [userData, globalSocketData]);

  // const waitForData = () => {
  //   if (branches.length !== 0) {
  //     sessionStorage.setItem(
  //       "createAuctionBDTMParams",
  //       JSON.stringify({
  //         variety_id: formData.variety_id,
  //         branch_id: formData.branch_id,
  //       })
  //     );
  //     // getDefaultSaudhaType(formData.branch_id, formData.crop_id);
  //     getCopyData(formData.branch_id, formData.variety_id);
  //     // byPassBDDataSocketEmition(globalSocketData);
  //   } else {
  //     setTimeout(() => waitForData(), 100);
  //   }
  // };

  // const setSaudhaAndRebate = async () => {
  // setFormData(prev=>({...prev,saudhaType:saudhaType}));
  // await getRebate(formData.branch_id, formData.crop_id);
  // };
  const getVarietyData = async (params: any) => {
    try {
      const rebateListResponse = await getRebateService(params);
      if (rebateListResponse.data.status) {
        setRebateList(rebateListResponse.data.data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  const getSlotAuctionDetails = async (params: any, rowData: any) => {
    try {
      const response = await getSlotAuctions(params);
      if (response.data.status) {
        // const data = {
        //   ...formData,
        // };

        // Compare here with the current row old slots and the new slots data to update the qty and rebate id
        var newSlots: any = [];
        response.data.data.slots.forEach((slot, index) => {
          rowData.all_slots.forEach((oldSlots, index) => {
            slot.need_date =
              null || moment().add(7, "days").format("YYYY-MM-DD");
            slot.max_price = null;
            if (
              slot.start_time === oldSlots.start_time &&
              slot.end_time === oldSlots.end_time
            ) {
              //   slot.qty_rqd = oldSlots.qty_rqd;
              // slot.rebate_id = oldSlots.rebate_id_name;
              // slot.rebate_id_name = oldSlots.rebate_id;
              // slot.rebate_id = oldSlots.rebate_id;
              // slot.rebate_id_name = oldSlots.rebate_id_name;
            }
          });
          console.log("aslot", slot);
          newSlots.push(slot);
          console.log("aslot2", newSlots);
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          slots: newSlots,
          // slots: response.data.data.slots,   // directly assigining the new slots
          // max_price: response.data.data.max_price,
          need_date: response.data.data.need_date,
          remarks: response.data.data.remarks,
          normal_auction_exists: response.data.data.normal_auction_exists,
        }));
        var edit = true;
        response.data.data.slots.map((item: any) => {
          if (moment(item.start_time, "HH:mm:ss").isSameOrBefore(moment())) {
            edit = false;
          }
        });
        if (response.data.data.max_price !== null && !edit) setEditable(false);
        else setEditable(true);
        setModalVisible(true);
      }
    } catch (error) {
      console.log(error);
      // setIsSlotAuctionLoading(false);
    }
    // setIsSlotAuctionLoading(false);
  };

  // const onSelectedBranch = (value: string | number | any, option?: any) => {
  //   sessionStorage.setItem(
  //     "createAuctionBDTMParams",
  //     JSON.stringify({ variety_id: formData.variety_id, branch_id: value })
  //   );
  //   setFormData({ ...formData, branch_id: value });
  //   setSelectedBranchIndex(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id === value));
  //   // byPassBDDataSocketEmition(globalSocketData);
  //   getCopyData(value, formData.variety_id);
  //   // getParamsFnWithOutLoad(value, formData.crop_id);
  // };

  // const onSelectedVariety = (value: string | number | any, option: any) => {
  //   sessionStorage.setItem(
  //     "createAuctionBDTMParams",
  //     JSON.stringify({ variety_id: value, branch_id: formData.branch_id })
  //   );
  //   setFormData({ ...formData, variety_id: value });
  //   // byPassBDDataSocketEmition(globalSocketData);
  //   getCopyData(formData.branch_id, value);
  // };

  //vaidation of the roles based on user selection
  // const handleChange = (e) => {
  //   // Destructuring
  //   const { value, checked } = e.target;
  //   const { selectedRole } = userinfo;
  //   if (value === 1) {
  //     setroleCheck1(checked);
  //   } else if (value === 2) {
  //     setroleCheck2(checked);
  //   }
  //   setFormData({ ...formData, role_type: null });
  //   if (roleCheck1 && roleCheck2) {
  //     setFormData({ ...formData, role_type: 3 });
  //   } else if (roleCheck1 === true) {
  //     setFormData({ ...formData, role_type: 1 });
  //   } else if (roleCheck2 === true) {
  //     setFormData({ ...formData, role_type: 2 });
  //   }

  //   // Case 1 : The user checks the box
  //   if (checked) {
  //     setUserInfo({
  //       selectedRole: [...selectedRole, value],
  //       response: [...selectedRole, value],
  //     });
  //   }

  //   // Case 2  : The user unchecks the box
  //   else {
  //     setUserInfo({
  //       selectedRole: selectedRole.filter((e) => e !== value),
  //       response: selectedRole.filter((e) => e !== value),
  //     });
  //   }
  //   // getParamsFnWithOutLoad(formData.branch_id, formData.crop_id, true, true);
  //   // setFormData({
  //   //  ...formData,
  //   //  role_type: userinfo.response,
  //   //  });
  // };

  // const getDefaultSaudhaType = async (branchId: number, cropId: number) => {
  //   const params = {
  //     branch_id: branchId,
  //     parameter_type: "saudha_type",
  //     crop_id: cropId,
  //   };
  //   try {
  //     const response = await getSysParams(params);
  //     if (response.data.status) {
  //       setFormData({
  //         ...formData,
  //         saudaType: parseInt(response.data.data[0].parameter_data_value),
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getMaterialData = async (params: any) => {
    try {
      const response = await getMaterialCodes(params);
      if (response.data.status) {
        const data = response.data.data.map((item: any) => {
          return {
            id: item.pk_id,
            value: item.material_code,
          };
        });
        setMaterialList(data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  const getAuction = async (
    branchId = formData.branch_id,
    varietyId = formData.variety_id,
    cropId = formData.crop_id
  ) => {
    try {
      console.log(branchId, cropId);
      setBdLoading(true);
      const params = {
        branch_id: branchId,
        variety_id: varietyId,
        crop_id: cropId,
      };
      const response = await getCopyAuctionsData(params);
      if (response.data.status && response.data?.data?.length) {
        const data = response.data.data.map((item: any, index: any) => {
          return {
            ...item,
            key: index,
            // hub: item.hub_name,
            // division: item.division,
            // claim: item.claim_type,
            // quantity: item.qty,
            // min: item.min_price,
            // max: item.max_price,
            // hubId: item.hub_id,
            // all_slots: item.all_slots
          };
        });
        setTableData(data);
      } else {
        message.info(response.data.message, 1);
        setTableData(response.data.data);
      }
      setBdLoading(false);
    } catch (error: any) {
      console.log(error);
      setBdLoading(false);
      message.error(
        error.response.data?.message ||
          "Something went wrong , please try again later."
      );
    }
  };

  // const getRebate = async (branchId: number, crop_id: number) => {
  //   try {
  //     const response = await getRebateService({ branch_id: branchId, crop_id });
  //     setRebates(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function showPromiseConfirm() {
    confirm({
      title: "Override auction ?",
      icon: <ExclamationCircleOutlined />,
      content:
        "An auction exist for this combination, Are you sure you want to override ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        createAuction();
      },
      onCancel() {
        setFormData({
          ...formDefault,
          crop_id: selectedCrop,
          branch_id: selectedBranch,
          saudha_type: +saudhaType,
          variety_id: varieties[0]?.id,
        });
        setModalVisible(false);
      },
    });
  }

  const createAuctionClicked = () => {
    setIsLoading(true);
    if (formData.normal_auction_exists) {
      showPromiseConfirm();
    } else createAuction();
    setIsLoading(false);
    // getParamsFnWithOutLoad(formData.branch_id, formData.crop_id, true, true);
  };

  const createAuction = async () => {
    // let formData = formData;
    // console.log(formData)
    // const postSlots =
    //   formData.slots &&
    //   formData.slots.filter(
    //     (item) => console.log(item)
    //       // item.qty_fulfilled === null &&
    //       // (item.qty_rqd !== null ||
    //       //   item.rebate_id !== null ||
    //       //   item.need_date !== null ||
    //       //   item.max_price !== null)
    //   );
    // const postSlotStructure =
    //   postSlots &&
    //   postSlots.map((item: any) => {
    //     return {
    //       slot_id: item.slot_id,
    //       end_time: item.bid_ends_on, //
    //       qty_rqd: item.qty_rqd,
    //       qty_fulfilled: item.qty_fulfilled === null ? 0 : item.qty_fulfilled,
    //       rebate_matrix_id: item.rebate_id,
    //       max_price: item.max_price,
    //       need_date: item.need_date,
    //       is_active: item.is_active,
    //     };
    //   });
    // let formData = {
    //   crop_id: formData.crop_id,
    //   branch_id: formData.branch_id,
    //   hub_id: formData.hub_id,
    //   variety_id: formData.variety_id,
    //   division: formData.division,
    //   saudha_type: formData.saudaType,
    //   claim_type: formData.claim_type,
    //   // role_type:
    //   //   formData.role_type === 1
    //   //     ? [15]
    //   //     : formData.role_type === 2
    //   //     ? [11]
    //   //     : formData.role_type === 3
    //   //     ? [15, 11]
    //   //     : null,
    //   role_type: [15],
    //   //max_price: formData.max_price,
    //   min_price: null,
    //   //need_date: formData.need_date,
    //   remarks: formData.remarks,
    //   buying_decision_id: null,
    //   withBd: false,
    //   slots: postSlotStructure,
    // };
    const postData: any = [];
    if (formData.slots?.length === 0) {
      message.warning("Can not find any slots.");
      return false;
    } else if (formData.slots && formData.slots?.length !== 0) {
      let isFieldDataEmpty: boolean = true;
      for (let i = 0; i < formData.slots?.length; i++) {
        if (
          formData.slots[i].is_active &&
          formData.slots[i].need_date &&
          (formData.slots[i].qty_rqd || formData.slots[i].max_price)
        ) {
          const maxPrice = varieties.filter(
            (item: any) => item.id === formData.variety_id
          )[0].maxPrice;
          const minPrice = varieties.filter(
            (item: any) => item.id === formData.variety_id
          )[0].minPrice;
          if (
            moment(
              moment(moment(), "HH:mm:ss").add(
                formData.slots[i].auction_freeze_period,
                "minutes"
              ),
              "HH:mm:ss"
            ).isAfter(moment(formData.slots[i].start_time, "HH:mm:ss"))
          ) {
            message.warning(
              `Auction cannot be created ${formData.slots[i].auction_freeze_period} minute(s) before the slot start time.`
            );
            return false;
          } else if (
            formData.claim_type === 1 &&
            formData.slots[i].rebate_id === null
          ) {
            message.warning("Please enter valid rebate id.");
            return false;
            /*  } else if (formData.slots[i].qty_rqd < Number(minQty)) {
            message.warning(
              "Slot qty must be greater than Minimum Truckable quantity."
            );
            return false; */
          } else if (
            formData.slots[i].qty_rqd === null ||
            isNaN(formData.slots[i].qty_rqd)
          ) {
            message.warning("Please enter valid Slot qty.");
            return false;
          } else if (
            formData.slots[i].max_price === null ||
            isNaN(formData.slots[i].max_price)
          ) {
            message.warning("Please enter max price...");
            return false;
          } else if (
            formData.slots[i].max_price <= minPrice ||
            formData.slots[i].max_price >= maxPrice
          ) {
            message.warning(
              `Max price should be between the range ${minPrice} (₹/MT) to ${maxPrice} (₹/MT)...`
            );
            return false;
          } else if (
            isNaN(formData.slots[i].max_price) ||
            formData.slots[i].max_price <= 0
          ) {
            message.warning("Enter a valid max price...");
            return false;
          } else if (
            formData.slots[i].need_date === "" ||
            formData.slots[i].need_date === null ||
            formData.slots[i].need_date === undefined
          ) {
            message.warning("Please enter need date...");
            return false;
          } else if (
            formData.slots[i].material_code === "" ||
            formData.slots[i].material_code === null ||
            formData.slots[i].material_code === undefined
          ) {
            message.warning("Please select Material...");
            return false;
          } else if (
            formData.slots[i].bag_type === "" ||
            formData.slots[i].bag_type === null ||
            formData.slots[i].bag_type === undefined
          ) {
            message.warning("Please select Bag type...");
            return false;
          }
          postData.push({
            ...formData.slots[i],
            id: i,
            end_time: formData.slots[i].bid_ends_on,
            rebate_matrix_id: formData.slots[i].rebate_id,
          });
          console.log(postData);
          console.log(i);
          postData
            .filter((item: any) => item.id === i)
            .map((item: any) => {
              item.qty_fulfilled =
                item.qty_fulfilled === null ? 0 : item.qty_fulfilled;
              delete item.bid_ends_on;
              delete item.rebate_id;
              delete item.auction_freeze_period;
              delete item.slot_number;
              delete item.start_time;
              delete item.showError;
              delete item.id;
            });
          isFieldDataEmpty = false;
        } else if (
          formData.slots[i].qty_rqd === null &&
          formData.slots[i].max_price === null &&
          formData.slots[i].need_date ===
            moment().add(7, "days").format("YYYY-MM-DD")
        ) {
          continue;
        } else {
          if (formData.slots[i].is_active) {
            message.warning("Please enter valid slot details...");
            return false;
          }
          continue;
        }
      }
      if (!isFieldDataEmpty) {
        setFormData((prev: IFormData) => {
          const modifiedData: any = {
            ...prev,
            saudha_type: prev.saudaType,
            slots: postData,
            role_type: [15],
            withBd: false,
            min_price: null,
            buying_decision_id: null,
          };
          delete modifiedData.allSlots;
          delete modifiedData.need_date;
          delete modifiedData.normal_auction_exists;
          delete modifiedData.roleType;
          delete modifiedData.saudaType;
          delete modifiedData.total_qty;
          delete modifiedData.varietyName;
          delete modifiedData.hub_name;
          delete modifiedData.max_price;
          (async (modifiedData) => {
            try {
              const postResponse = await postAuction(modifiedData);
              if (postResponse.data.status) {
                message.success("Auction created / updated successfully");
                setModalVisible(false);
              } else {
                message.error(
                  postResponse.data.message || "Something went wrong :("
                );
              }
            } catch (err: any) {
              console.log("err", err.response);
              message.error(
                err.response.data.message || "Something went wrong :("
              );
            }
          })(modifiedData);
          return prev;
        });
        return true;
      } else {
        message.error("Please fill slot datas to create/update auctions.");
        return false;
      }
    }

    // if (validate()) {
    //   try {
    //     const postResponse = await postAuction(formData);
    //     if (postResponse.data.status) {
    //       message.success("Auction created / updated successfully");
    //       setModalVisible(false);
    //     } else {
    //       message.error("Something went wrong :(");
    //     }
    //   } catch (error) {
    //     message.error("Something went wrong :(");
    //     console.log(error);
    //   }
    // }
  };

  const onSlotValueChanged = async (
    value: number,
    slotId: number,
    changedField: string | any
  ) => {
    let slotChangedData = formData.slots;
    slotChangedData !== null &&
      slotChangedData.map((item: any) => {
        if (item.slot_id === slotId) {
          item[changedField] = value ? value : null;
          if (
            changedField !== "rebate_id" &&
            changedField !== "max_price" &&
            changedField !== "bag_type" &&
            changedField !== "material_code" &&
            changedField !== "need_date"
          ) {
            if (value < minQty) {
              item["showError"] = true;
            } else {
              item["showError"] = false;
            }
          }
        }
        return item;
      });
    setFormData({ ...formData, slots: slotChangedData });
  };

  return (
    <div className="copy-auction-bd">
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        maskClosable={false}
        className="create-auction-modal"
        width={"1300px"}
      >
        <div className="header">
          Create Auction in <div className="bold">{formData.hub_name}</div> for{" "}
          <div className="bold">
            {crops &&
              crops.length &&
              crops.find(
                (item: { id: number; name: string }) =>
                  item.id === formData.crop_id
              )?.name}{" "}
            -{" "}
            {varieties &&
              varieties.length &&
              varieties.find(
                (item: { id: number; name: string }) =>
                  item.id === formData.variety_id
              )?.name}{" "}
            -{" "}
            {formData.claim_type && formData.claim_type === 1
              ? "Claim"
              : "No Claim"}{" "}
            -{" "}
            {formData.division && formData.division === 1
              ? "ABD"
              : formData.division === 2
              ? "FBD"
              : "NCDEX"}
          </div>
        </div>
        <div className="modal-container">
          <div className="left-container">
            <div className={`form-items ${!editable && "disabled"}`}>
              <div className="label-saudha">
                Sauda Type<label style={{ color: "red" }}>*</label>
              </div>
              <Radio.Group
                value={formData.saudaType}
                onChange={(e) =>
                  setFormData({ ...formData, saudaType: e.target.value })
                }
              >
                <Radio value={1}>Net basis</Radio>
                <Radio value={2}>Gross basis</Radio>
              </Radio.Group>
            </div>
            {/* <div className="form-items">
              <div className="label">
                Role<label style={{ color: "red" }}>*</label>
              </div>
              {console.log("paramrole ", formData.role_type)}
              {console.log("paramrole12 ", formData)}
              <form>
                <Checkbox
                  value={1}
                  checked={
                    formData.role_type === 1 || formData.role_type === 3
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  //setroleCheck1(checked)
                >
                  Trader/Broker
                </Checkbox>
                <Checkbox
                  value={2}
                  checked={
                    formData.role_type === 2 || formData.role_type === 3
                      ? true
                      : false
                  }
                  onChange={handleChange}
                >
                  FPO
                </Checkbox>
              </form>
              <div>
                {
                  (onchange = () => {
                    getParamsFnWithOutLoad(formData.branch_id, true, true);
                    if (roleCheck1 && roleCheck2) {
                      setFormData({ ...formData, role_type: 3 });
                    } else if (roleCheck1 === true) {
                      setFormData({ ...formData, role_type: 1 });
                    } else if (roleCheck2 === true)
                      setFormData({ ...formData, role_type: 2 });
                  })
                }
              </div>
            </div> */}
            {/* <div className="form-items">
              <div className="label">Max Price (₹/MT)</div>
              <Input
                type="number"
                maxLength={10}
                value={
                  formData.max_price === null ? undefined : formData.max_price
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    max_price: Number(e.target.value),
                  })
                }
              ></Input>
            </div>

            <div className={`form-items ${!editable && "disabled"}`}>
              <div className="label">
                Need Date<label style={{ color: "red" }}>*</label>
              </div>
              <DatePicker
                clearIcon={false}
                onChange={(value, date) =>
                  setFormData({ ...formData, need_date: date })
                }
                value={
                  formData.need_date !== null
                    ? moment(formData.need_date)
                    : undefined
                }
                disabledDate={(d) =>
                  !d ||
                  // d.isAfter(moment().add(7, "days")) ||
                  d.isSameOrBefore(moment().subtract(1, "days"))
                }
              />
            </div> */}
            <div className={`form-items ${!editable && "disabled"}`}>
              <div className="label">Remarks</div>
              <TextArea
                showCount
                maxLength={35}
                value={formData.remarks === null ? undefined : formData.remarks}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    remarks: e.target.value,
                  })
                }
              ></TextArea>
            </div>
          </div>
          <div className="right-container">
            <div className="form-items">
              <div className="label noteText">
                <b>
                  {formData.slots !== undefined &&
                  formData.slots !== null &&
                  formData.slots.length
                    ? ` NOTE: Auction cannot be created ${formData.slots[0].auction_freeze_period} minute(s) before the slot start time.`
                    : ""}
                </b>
              </div>
            </div>
            <div className="slots-container">
              {formData.slots !== undefined &&
                formData.slots
                  ?.filter(
                    (item: any) => item.is_active && item.is_active === true
                  )
                  .map((item: any, index: number) => {
                    return (
                      <IndividualItem
                        key={index}
                        index={index}
                        cropId={formData?.crop_id || crops[0].id}
                        slotId={item.slot_id}
                        branchId={formData?.branch_id || branches[0].id}
                        slotNumber={item.slot_number}
                        onChangeFn={onSlotValueChanged}
                        minQtyShow={
                          item.showError == undefined ? false : item.showError
                        }
                        sysParametersData={minQty}
                        requiredQty={item.qty_rqd}
                        purchasedQty={item.qty_fulfilled}
                        rebateId={item.rebate_id}
                        auctionFreezePeriod={item.auction_freeze_period}
                        startTime={item.start_time}
                        endTime={item.end_time}
                        rebateList={rebateList}
                        // bag_type={formData?.bag_type}
                        bagTypeList={bagTypeList}
                        materialList={materialList}
                        claim={formData.claim_type}
                        needDate={item.need_date}
                        maxPrice={item.max_price}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <Button loading={isLoading} onClick={createAuctionClicked}>
            Create Auction
          </Button>
          {/* <div className="remaining-time">
            {/* <div>***Total Quantity: {selectedData.quantity} MT</div> //
            <div>
              ***Remaining Quantity (MT) :{" "}
              {formData.slots?.length !== 0 &&
              formData.total_qty -
                formData.slots
                  ?.map((item) => item.qty_fulfilled)
                  .reduce((prev, next) => prev + next) <
                0
                ? 0
                : formData.slots?.length !== 0 &&
                  formData.total_qty -
                    formData.slots
                      ?.map((item) => item.qty_fulfilled)
                      .reduce((prev, next) => prev + next)}
              {/* MT //
            </div>
            <div>
              ***Total buying target (MT) :{" "}
              {formData.total_qty < 0 ? 0 : formData.total_qty}
            </div> 
          </div> */}
        </div>
      </Modal>
      <div className="header">
        Copy Auctions for{" "}
        <CustomSelect
          options={branches}
          value={formData?.branch_id || branches[0]?.id}
          onChange={(value, _) => {
            setFormData((prev) => ({ ...prev, branch_id: value }));
            setSelectedBranchIndex(value);
            getAuction(value);
          }}
          returnId
        />{" "}
        for{" "}
        {crops &&
          crops.length &&
          crops.find(
            (item: { id: number; name: string }) => item.id === formData.crop_id
          )?.name}{" "}
        -
        <CustomSelect
          options={varieties}
          value={formData?.variety_id || varieties[0]?.id}
          onChange={(value, _) => {
            setFormData((prev) => ({ ...prev, variety_id: value }));
            getAuction(formData.branch_id, value, formData.crop_id);
          }}
          returnId
        />
      </div>

      <div className="container">
        <div className="table-container">
          <Table
            style={{ maxHeight: "330px" }}
            columns={columns}
            // dataSource={dummyData}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: window.innerHeight - 222 }}
            loading={bdLoading}
          />
        </div>
        {/* <div className="terms-container">
          <div className="terms-and-conditions">Terms and Conditions</div>
          <div className="terms">
            <div
              className="editor-text-container"
              dangerouslySetInnerHTML={{ __html: TCHtmlString }}
            ></div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

// interface IIndividualItem {
//   slotId: number;
// }
// const IndividualItem: React.FC<IIndividualItem> = (props: IIndividualItem) => {
//   const { slotId } = props;
//   return (
//     <div className="individual-slot">
//       <div className="form-items">
//         <div className="label">Slot {slotId}</div>
//         <Input type="number" placeholder="Slot Qty" />
//       </div>
//       <div className="form-items">
//         <div className="label">Rebate ID</div>

//         <Select placeholder="Rebate ID">
//           <Option value={17283768237}>17283768237</Option>
//           <Option value={17283768235}>17283768235</Option>
//           <Option value={17283768236}>17283768236</Option>
//           <Option value={17283768238}>17283768238</Option>
//         </Select>
//       </div>
//       <div className="form-items align-right">
//         <div className="label">Purchased Qty</div>
//         <div>1,200 MT</div>
//       </div>
//     </div>
//   );
// };
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  varieties: selectVarieties,
  selectedCrop: selectSelectedCrop,
  crops: selectCrops,
  TCHtmlString: selectTC,
  userData: selectUserData,
  currentPage: selectCurrentPage,
  selectedBranch: selectSelectedBranch,
  minQty: selectMinQty,
  saudhaType: selectSaudhaType,
  rebates: selectRebates,
  bypassEnabled: selectBypassBD,
  bagTypeList: selectBagtypeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyAuction);
