/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import "./system-parameters.styles.scss";
import {
  Input,
  Button,
  TimePicker,
  Radio,
  Modal,
  Spin,
  message,
  Switch,
} from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  // getSysParams,
  putSysParams,
  getDiscountParams,
} from "../../services/sys-params-services";

import {
  selectBranches,
  selectVarieties,
  selectCrops,
  selectSelectedCrop,
  selectCurrentPage,
  selectBranchParams,
  selectCropParams,
  selectSelectedBranch,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";
import { Dispatch } from "redux";

import CustomSelect from "../../components/custom-select/custom-select.component";

import { Select } from "antd";

import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";

import moment from "moment";
import { selectUserData } from "../../redux/user/user.selectors";
import {
  setBranchParamsRefetch,
  setSelectedBranch,
  setSelectedCrop,
} from "../../redux/common/common.actions";
import {
  getBagTypeDetails,
  getCropYearDetails,
} from "../../services/auction-services";
// import { convertCompilerOptionsFromJson } from "typescript";
// const { TextArea } = Input;

const { Option } = Select;

const formD: any = {
  minimum_quantity: "",
  auction_closing_duration: "",
  saudha_type: "",
  price_tick_size: "",
  minimum_price_decrement: "",
  cash_discount: "",
  cash_discount_desc: "",
  minimum_refund_amount: "",
  bag_type: "",
  crop_year: "",
  send_wa_upcoming: "",
  Brokerage: "",
};

const formParamD: any = {
  avg_commodity_price: "",
  caution_deposit_min_amount: "",
  safety_margin: "",
  minimum_price_decrement_percentage: "",
};

const SystemParameters: React.FC = (props: any) => {
  const {
    branches,
    user,
    crops,
    selectedCrop,
    currentpage,
    branchParams,
    selectedBranch,
    setCrop,
    setBranch,
    viewAuctionPage,
    cropParams,
    setRefetch,
  } = props;
  const [formData, setFormData] = useState(formD);
  // const [selectedBranch, setBranch] = useState(branches[0]?.id);
  // const [selectedVariety, setSelectedVariety] = useState(varieties[0]?.id);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [branchLoading, setBranchLoading] = useState(false);
  // const [varietyLoading, setVarietyLoading] = useState(false);
  const [sysLoading, setSysLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [bagType, setBagType] = useState([]);
  const [cropYear, setCropYear] = useState([]);
  const [cashDiscountDesc, setCashDiscountDesc] = useState<any>();
  const [formParamData, setFormParamData] = useState(formParamD);
  // const [selectedCrop, setCrop] = useState<any>(selectedCrop);

  useEffect(() => {
    if (currentpage === "7" && viewAuctionPage === "71") {
      // setCrop(selectedCrop);
      // getParamsFn(selectedBranch, true, true);
      // getParamsFnForCrop(selectedCrop, true);
      // getDiscounts(selectedCrop, selectedBranch);
      setFormData(branchParams);
      setFormParamData(cropParams);
      getDiscount(branchParams);
    }
  }, [currentpage, branchParams, cropParams, selectedBranch]);
  useMemo(async () => {
    const bagTypeResponse = await getBagTypeDetails();
    if (bagTypeResponse.data.status) {
      setBagType(bagTypeResponse.data.data);
    }
    const cropYearResponse = await getCropYearDetails();
    if (cropYearResponse.data.status) {
      setCropYear(cropYearResponse.data.data);
    }
  }, []);
  const getDiscount = async (branchParams: any) => {
    console.log("branch change", branchParams);
    try {
      const discountResponse = await getDiscountParams({
        crop_id: selectedCrop,
        branch_id: selectedBranch,
      });
      var tempData: any = [];
      tempData = discountResponse.data.data;
      setDiscounts(tempData);
      setCashDiscountDesc(
        tempData?.filter(
          (item: any) =>
            item.cash_discount_id.toString() === branchParams.cash_discount
        ).length > 0
          ? tempData?.filter(
              (item: any) =>
                item.cash_discount_id.toString() === branchParams.cash_discount
            )[0]
          : tempData.length > 0 && tempData[0]
      );
    } catch (error: any) {
      console.log(error);
      message.error(
        error.response.data.message ||
          "Something went wrong, please try again later."
      );
    }
  };

  // const getParamsFn = async (
  //   branchId?: number,
  //   variety?: boolean,
  //   initial?: boolean
  // ) => {
  //   setIsLoading(true);
  //   try {
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: selectedCrop,
  //     });
  //     var tempData: any = {};
  //     branchResponse.data.data.map((item: any) => {
  //       Object.assign(tempData, {
  //         [item.parameter_type]: item.parameter_data_value,
  //       });
  //     });
  //     setFormData({
  //       ...formData,
  //       ...tempData,
  //     });
  //     // const varietyResponse = await getSysParams({
  //     //   branch_id: branchId,
  //     //   variety_param: variety,
  //     // });
  //     // Object.assign(tempData, {
  //     //   cash_discount: varietyResponse.data.data.map((item: any) => {
  //     //     return {
  //     //       [item.parameter_type]: item.parameter_data_value * 100,
  //     //       variety_id: item.variety_id,
  //     //       variety_name: item.variety_name,
  //     //     };
  //     //   }),
  //     // });
  //     // varietyResponse.data.data.map((item: any) => {
  //     //   Object.assign(tempData, {
  //     //     [item.parameter_type]: item.parameter_data_value,
  //     //     variety_id: item.variety_id,
  //     //   });
  //     // });
  //     // varietyResponse.data.data.map((item: any) => {
  //     //   Object.assign(tempData, {
  //     //     [item.parameter_type]: item.parameter_data_value,
  //     //   });
  //     // });
  //     // tempData = {
  //     //   ...tempData,
  //     //   cash_discount: tempData.cash_discount * 100,
  //     // };
  //     setFormData({ ...formData, ...tempData });
  //     if (initial) {
  //       const sysResponse = await getSysParams({});
  //       sysResponse.data.data.map((item: any) => {
  //         Object.assign(tempData, {
  //           [item.parameter_type]: item.parameter_data_value,
  //         });
  //       });
  //       tempData = {
  //         ...tempData,
  //         minimum_price_decrement: tempData.minimum_price_decrement * 100,
  //       };
  //       setFormData({ ...formData, ...tempData });
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // const getParamsFnForCrop = async (crop?: number, initial?: boolean) => {
  //   console.log("getParamsFnForCrop");
  //   setIsLoading(true);
  //   try {
  //     const branchResponse = await getSysParams({ crop_id: crop });
  //     var tempData: any = {};
  //     branchResponse.data.data.map((item: any) => {
  //       Object.assign(tempData, {
  //         [item.parameter_type]: item.parameter_data_value,
  //       });
  //     });

  //     setFormParamData({ ...tempData });
  //     if (initial) {
  //       // const sysResponse = await getSysParams({});
  //       // sysResponse.data.data.map((item: any) => {
  //       //   Object.assign(tempData, {
  //       //     [item.parameter_type]: item.parameter_data_value,
  //       //   });
  //       // });
  //       tempData = {
  //         ...tempData,
  //         minimum_price_decrement_percentage:
  //           tempData.minimum_price_decrement_percentage * 100,
  //       };
  //       setFormParamData({ ...tempData });
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  const onChangeInput = (value: any, field: string, variety?: number) => {
    if (field === "cash_discount") {
      // discounts.map((item: any) => {
      //   if (item.variety_id === variety) {
      //     item.cash_discount = value;
      //   }
      // });
      setCashDiscountDesc(
        discounts?.filter((item: any) => item.cash_discount_id == value)
          .length &&
          discounts?.filter((item: any) => item.cash_discount_id == value)[0]
      );
      setFormData({
        ...formData,
        cash_discount: value,
      });
    } else if (field === "send_wa_upcoming") {
      setFormData({
        ...formData,
        send_wa_upcoming: value === true ? "1" : "0",
      });
    } else if (field === "minimum_quantity") {
      const tempValue = String(value).replace(/\D/, "");
      setFormData({ ...formData, [field]: tempValue });
    } else {
      // if (field === "auction_freeze_period" && value > 0 && value <= 60) {
      //   setFormData({ ...formData, [field]: value });
      // } else
      setFormData({ ...formData, [field]: value });
    }
  };

  const onChangeInputForParam = (
    value: any,
    field: string,
    variety?: number
  ) => {
    var numberRegex = /^[0-9.]+$/;
    if (value === "") {
      setFormParamData({ ...formParamData, [field]: value });
    } else if (value.match(numberRegex)) {
      setFormParamData({ ...formParamData, [field]: value });
    }
  };

  const onChangeTime = (timeString: string, key: string) => {
    if (
      timeString === "00:00" ||
      timeString === "00:01" ||
      timeString === "00:02" ||
      timeString === "00:03" ||
      timeString === "00:04"
    ) {
      message.warning(
        "Minimum Auction Buffer Time Should be set to 5 Minutes..."
      );
    } else {
      setFormData({ ...formData, [key]: timeString });
    }
  };

  const onUpdate = async (
    keys: Array<string>,
    form: number,
    onCautionParamUpdate: boolean
  ) => {
    var tempData: any[] = [];
    if (form === 3) {
      tempData = formData.cash_discount.map((item: any) => {
        return {
          // data_value: item.cash_discount / 100,
          data_value: item.cash_discount,
          variety_id: item.variety_id,
          parameter_type: "cash_discount",
        };
      });
    } else if (onCautionParamUpdate) {
      tempData = keys.map((key) => {
        return {
          data_value: formParamData[key],
          parameter_type: key,
        };
      });
    } else {
      tempData = keys.map((key) => {
        if (key === "inco_terms") {
          // || key === "approver_code"
          return {
            data_value: formData[key].toUpperCase(),
            parameter_type: key,
          };
        } else {
          return {
            data_value: formData[key],
            parameter_type: key,
          };
        }
      });
    }
    var dataToSend: any = {
      branch_id: null,
      parameters: tempData,
      crop_id: selectedCrop,
    };
    var messageString = "";
    switch (form) {
      case 1:
        setBranchLoading(true);
        dataToSend = {
          ...dataToSend,
          branch_id: selectedBranch,
        };
        messageString = "Updated branch level param...";
        break;
      case 2:
        setSysLoading(true);
        if (onCautionParamUpdate) {
          dataToSend = {
            ...dataToSend,
            crop_id: selectedCrop,
          };
          messageString = "Updated company level param...";
        } else {
          messageString = "Updated company level param...";
        }

        break;
      // case 3:
      //   setVarietyLoading(true);
      //   messageString = "Updated variety level param...";
      //   dataToSend = {
      //     ...dataToSend,
      //     branch_id: selectedBranch,
      //     variety_param: true,
      //   };
      //   break;
    }

    const validate = () => {
      for (let i = 0; i < dataToSend.parameters.length; i++) {
        switch (dataToSend.parameters[i].parameter_type) {
          case "minimum_quantity":
            if (
              parseInt(dataToSend.parameters[i].data_value) <= 0 ||
              isNaN(parseInt(dataToSend.parameters[i].data_value))
            ) {
              message.warning("Enter a valid minimum quantity...");
              return false;
            }
            break;
          case "auction_closing_duration":
            if (dataToSend.parameters[i].data_value == "00:00:00") {
              message.warning("Enter a valid duration...");
              return false;
            }
            break;
          case "price_tick_size":
            if (
              parseInt(dataToSend.parameters[i].data_value) <= 0 ||
              isNaN(parseInt(dataToSend.parameters[i].data_value))
            ) {
              message.warning("Enter a valid tick size...");
              return false;
            }
            break;
          case "minimum_price_decrement":
            dataToSend.parameters[i].data_value =
              dataToSend.parameters[i].data_value / 100;
            if (
              dataToSend.parameters[i].data_value <= 0 ||
              dataToSend.parameters[i].data_value > 1
            ) {
              message.warning("Enter a valid price decrement...");
              return false;
            }
            break;
          case "minimum_price_decrement_percentage":
            dataToSend.parameters[i].data_value =
              dataToSend.parameters[i].data_value / 100;
            if (
              dataToSend.parameters[i].data_value <= 0 ||
              dataToSend.parameters[i].data_value > 1
            ) {
              message.warning("Enter a valid price decrement...");
              return false;
            }
            break;
          case "cash_discount":
            // if (isNaN(parseInt(dataToSend.parameters[i].data_value))) {
            if (
              !dataToSend.parameters[i].data_value ||
              dataToSend.parameters[i].data_value === ""
            ) {
              message.warning("Enter cash discount...");
              return false;
            }
            // if (
            //   dataToSend.parameters[i].data_value < 0 ||
            //   dataToSend.parameters[i].data_value > 100
            // ) {
            //   message.warning("Enter cash discount...");
            //   return false;
            // }
            break;
          case "bag_type":
            if (
              !dataToSend.parameters[i].data_value ||
              dataToSend.parameters[i].data_value === ""
            ) {
              message.warning("Enter bag type...");
              return false;
            }
            break;
          case "crop_year":
            if (
              !dataToSend.parameters[i].data_value ||
              dataToSend.parameters[i].data_value === ""
            ) {
              message.warning("Enter crop year...");
              return false;
            }
            break;
        }
      }
      return true;
    };

    if (validate()) {
      try {
        let updateResponse;
        if (onCautionParamUpdate) {
          updateResponse = await putSysParams(dataToSend);
        } else {
          updateResponse = await putSysParams(dataToSend);
        }
        if (updateResponse.data.status) {
          message.success(messageString, 1.5);
        } else {
          message.error(
            "Something went wrong, could not update params..!",
            1.5
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    setRefetch(true);
    setBranchLoading(false);
    setSysLoading(false);
  };

  // const onCropSelected = (value: number) => {
  //   setCrop(value);
  //   getParamsFnForCrop(value);
  // };

  // const onSelectedBranch = (value: number) => {
  //   setBranch(value);
  //   getDiscounts(selectedCrop, value);
  //   // getParamsFn(value, true);
  // };

  function onChangeDiscount(value: any) {
    onChangeInput(value, "cash_discount");
  }

  return (
    <div className="system-params">
      {(canUserAccess(user, FeatureCodes.COMPANY_LEVEL_PREFERENCES) ||
        canUserAccess(user, FeatureCodes.ACCESS_EDIT_COMPANY_LEVEL_PARAM)) && (
        <div style={{ display: "flex" }}>
          <div className="left-container">
            <Spin spinning={sysLoading}>
              <div className="outer-container">
                <div className="header">Company Level Parameters</div>
                <div className="form-container">
                  {/* <div>Minimum Price Distribution % from Max Price</div>
                    <Input
                      type="number"
                      placeholder="Minimum Price Distribution"
                      min={1}
                      max={100}
                      value={formData.minimum_price_decrement}
                      onChange={(e) =>
                        onChangeInput(e.target.value, "minimum_price_decrement")
                      }
                    /> */}
                  <div>Auction Freeze Period (Minutes)</div>
                  <Input
                    type="number"
                    placeholder="Auction Freeze Period"
                    min={1}
                    max={60}
                    value={formData.auction_freeze_period}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "auction_freeze_period")
                    }
                  />
                  <div>Max Qty parameter</div>

                  <Input
                    type="number"
                    placeholder="Max Qty parameter"
                    min={1}
                    max={100}
                    value={formData.max_qty_parameter}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "max_qty_parameter")
                    }
                  />
                  <div>Minimum Refund Amount(₹) </div>

                  <Input
                    type="number"
                    placeholder="Minimum Refund Amount"
                    min={1}
                    value={formData.minimum_refund_amount}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "minimum_refund_amount")
                    }
                  />
                  <div>Price Tick Size</div>
                  {/* <Input
                    type="number"
                    min={1}
                    max={100}
                    placeholder="Price Tick Size"
                    value={formData.price_tick_size}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "price_tick_size")
                    }
                  /> */}
                  <div className="price-tick-size" style={{ height: "32px" }}>
                    {formData.price_tick_size}
                  </div>
                  <div>Inco Terms</div>
                  {/* <Input
                    type="string"
                    placeholder="Inco Terms"
                    // min={1}
                    // max={100}
                    value={formData.inco_terms}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "inco_terms")
                    }
                  /> */}
                  <div className="price-tick-size" style={{ height: "32px" }}>
                    {formData.inco_terms}
                  </div>
                  <div className="update-btn">
                    <Button
                      disabled={
                        formData.max_qty_parameter === "" ||
                        formData.inco_terms === "" ||
                        formData.auction_freeze_period === ""
                        // ||
                        // formData.minimum_price_decrement === ""
                      }
                      type="primary"
                      onClick={() =>
                        onUpdate(
                          [
                            "price_tick_size",
                            // "minimum_price_decrement",
                            "auction_freeze_period",
                            "inco_terms",
                            "max_qty_parameter",
                            "minimum_refund_amount",
                          ],
                          2,
                          false
                        )
                      }
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className="left-container" style={{ marginLeft: "10px" }}>
            <Spin spinning={sysLoading}>
              <div className="outer-container" style={{ width: "520px" }}>
                <div className="header">
                  Company Level Parameters for
                  <CustomSelect
                    isCropParam={true}
                    options={crops}
                    value={selectedCrop}
                    onChange={(value: any) => setCrop(value)}
                    returnId
                  />
                </div>
                <div className="form-container">
                  <div>Minimum Price Distribution % from Max Price</div>
                  <Input
                    type="number"
                    placeholder="Minimum Price Distribution"
                    min={1}
                    max={100}
                    value={formParamData.minimum_price_decrement_percentage}
                    onChange={(e) =>
                      onChangeInputForParam(
                        e.target.value,
                        "minimum_price_decrement_percentage"
                      )
                    }
                  />
                  <div>Minimum Caution Deposit Amount(₹)</div>
                  <Input
                    type="number"
                    // placeholder="Minimum Caution Deposit Amount"
                    min={1}
                    max={100}
                    value={formParamData.caution_deposit_min_amount}
                    onChange={(e) =>
                      onChangeInputForParam(
                        e.target.value,
                        "caution_deposit_min_amount"
                      )
                    }
                  />
                  <div>Avg Commodity Price(MT)</div>
                  <Input
                    type="number"
                    // placeholder="Avg Commodity Price"
                    min={1}
                    max={60}
                    value={formParamData.avg_commodity_price}
                    onChange={(e) =>
                      onChangeInputForParam(
                        e.target.value,
                        "avg_commodity_price"
                      )
                    }
                  />
                  <div>Caution Deposit Safety Margin(%)</div>
                  <Input
                    type="number"
                    // placeholder="Caution Deposit Safety Margin"
                    min={1}
                    max={100}
                    value={formParamData.safety_margin}
                    onChange={(e) =>
                      onChangeInputForParam(e.target.value, "safety_margin")
                    }
                  />
                  <div className="update-btn" style={{ marginTop: "2%" }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        onUpdate(
                          [
                            "minimum_price_decrement_percentage",
                            "caution_deposit_min_amount",
                            "avg_commodity_price",
                            "safety_margin",
                          ],
                          2,
                          true
                        )
                      }
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      )}
      {(canUserAccess(user, FeatureCodes.VIEW_ONLY_ACCESS) ||
        canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)) && (
        <div className={`right-container`}>
          <Spin spinning={branchLoading}>
            <div className="outer-container">
              <div className="header">
                Parameters for{" "}
                {
                  <CustomSelect
                    options={branches}
                    value={selectedBranch}
                    onChange={(value: any) => setBranch(value)}
                    className="set-height"
                    returnId
                  />
                }{" "}
                for{" "}
                {crops &&
                  crops.length &&
                  crops.find(
                    (item: { id: number; name: string }) =>
                      item.id === selectedCrop
                  ).name}
              </div>
              <div className="form-container">
                <div>
                  Minimum Truckable Quantity (MT)
                  <label style={{ color: "red" }}>*</label>
                </div>
                <Input
                  type="string"
                  min={1}
                  max={100}
                  placeholder="Minimum Quantity"
                  value={formData.minimum_quantity}
                  onChange={(e) =>
                    onChangeInput(e.target.value, "minimum_quantity")
                  }
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                />
                <div>
                  Cash Discount<label style={{ color: "red" }}>*</label>
                </div>
                {
                  /* <Input
                    type="string"
                    min={0}
                    max={100}
                    placeholder="Cash Discount"
                    value={formData.cash_discount}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "cash_discount")
                    }
                    disabled={
                      !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                    }
                  /> */
                  <Select
                    showSearch
                    style={{ width: "100%", borderRadius: 10 }}
                    value={
                      cashDiscountDesc &&
                      `${cashDiscountDesc.cash_discount_description} ( ${cashDiscountDesc.cash_discount_code} )`
                    }
                    disabled={
                      !canUserAccess(
                        user,
                        FeatureCodes.BRANCH_LEVEL_PREFERENCES
                      )
                    }
                    // value={formData.cash_discount}
                    onChange={onChangeDiscount}
                    placeholder="Select cash discount"
                    optionFilterProp="children"
                  >
                    {discounts.map((v: any, i) => {
                      return (
                        <Option key={i} value={v.cash_discount_id}>
                          {v.cash_discount_description} ( {v.cash_discount_code}{" "}
                          )
                        </Option>
                      );
                    })}
                  </Select>
                }
                {/* <div>
                    Approver Code<label style={{ color: "red" }}>*</label>
                  </div>
                  <Input
                    type="string"
                    min={0}
                    max={100}
                    placeholder="Approver Code"
                    value={formData.approver_code}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "approver_code")
                    }
                    disabled={
                      !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                    }
                  /> */}
                <div>
                  Auction Buffer Time (HH:mm)
                  <label style={{ color: "red" }}>*</label>
                </div>
                <TimePicker
                  clearIcon={false}
                  showNow={false}
                  format="HH:mm"
                  placeholder="HH:mm"
                  value={moment(formData.auction_closing_duration, "HH:mm")}
                  onChange={(_: any, timeString: any) =>
                    onChangeTime(timeString, "auction_closing_duration")
                  }
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                />
                <div>
                  Duration before which suppliers can see upcoming auction
                  (HH:mm)<label style={{ color: "red" }}>*</label>
                </div>
                <TimePicker
                  clearIcon={false}
                  showNow={false}
                  format="HH:mm"
                  placeholder="HH:mm"
                  value={moment(formData.upcoming_auction_buffer_time, "HH:mm")}
                  onChange={(_: any, timeString: any) =>
                    onChangeTime(timeString, "upcoming_auction_buffer_time")
                  }
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                />
                <div>
                  Bag Type <label style={{ color: "red" }}>*</label>
                </div>
                <Select
                  style={{ width: "100%", borderRadius: 10 }}
                  // value={""}
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                  value={formData.bag_type}
                  onChange={(e) => onChangeInput(e, "bag_type")}
                  placeholder="Select Bag Type"
                  optionFilterProp="children"
                >
                  {bagType.map((v: any, i) => {
                    return (
                      <Option key={i} value={v.parameter_data_value}>
                        {v.parameter_display_value}
                      </Option>
                    );
                  })}
                </Select>
                <div>
                  Crop Year <label style={{ color: "red" }}>*</label>
                </div>
                <Select
                  style={{ width: "100%", borderRadius: 10 }}
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                  value={formData.crop_year}
                  onChange={(e) => onChangeInput(e, "crop_year")}
                  placeholder="Select crop year"
                  optionFilterProp="children"
                >
                  {cropYear.map((v: any, i) => {
                    return (
                      <Option key={i} value={v.crop_year}>
                        {v.crop_year}
                      </Option>
                    );
                  })}
                </Select>
                <div>
                  Send WA for Upcoming Auction
                  <label style={{ color: "red" }}> *</label>
                </div>
                <Switch
                  onChange={(e) => onChangeInput(e, "send_wa_upcoming")}
                  checked={formData.send_wa_upcoming === "1"}
                  disabled={
                    !canUserAccess(user, FeatureCodes.BRANCH_LEVEL_PREFERENCES)
                  }
                />
                <div>Brokerage</div>
                <Input
                  type="string"
                  placeholder="Brokerage"
                  value={formData.Brokerage}
                  disabled
                />
                {/*  <span>
                  <div>
                    Send WA for Bid Won
                    <label style={{ color: "red" }}> *</label>
                  </div>
                  <Switch
                    // onChange={(e) => onChangeInput(e, "is_bid_won")}
                    // checked={formData.is_upcoming_auction}
                    disabled={
                      !canUserAccess(
                        user,
                        FeatureCodes.BRANCH_LEVEL_PREFERENCES
                      )
                    }
                  />
                </span> */}
                {/* <div>
                  Sauda Type<label style={{ color: "red" }}>*</label>
                </div>
                <div className="radio-and-btn">
                  <Radio.Group
                    onChange={(e) =>
                      onChangeInput(e.target.value, "saudha_type")
                    }
                    value={formData.saudha_type}
                    disabled={
                      !canUserAccess(
                        user,
                        FeatureCodes.BRANCH_LEVEL_PREFERENCES
                      )
                    }
                  >
                    <Radio value="1">Net basis</Radio>
                    <Radio value="2">Gross basis</Radio>
                  </Radio.Group>
                </div> */}
                <div className="update-btn">
                  <Button
                    type="primary"
                    onClick={() =>
                      onUpdate(
                        [
                          "minimum_quantity",
                          "auction_closing_duration",
                          // "saudha_type",
                          "upcoming_auction_buffer_time",
                          "send_wa_upcoming",
                          "cash_discount",
                          "bag_type",
                          "crop_year",
                        ],
                        1,
                        false
                      )
                    }
                    disabled={
                      !canUserAccess(
                        user,
                        FeatureCodes.BRANCH_LEVEL_PREFERENCES
                      )
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
          {/* <Spin spinning={varietyLoading}>
            <div className="outer-container">
              <div className="header">
                Cash Discount for varieties in{" "}
                {branches.map((item: any) => {
                  return item.id == selectedBranch && item.name;
                })}
              </div>
              <div className="form-container">
                {formData.cash_discount.map((item: any) => (
                  <div className="discount-item" key={item.variety_id}>
                    <div>{item.variety_name}</div>
                    <Input
                      type="number"
                      min={0}
                      max={100}
                      placeholder="Cash Discount"
                      value={item.cash_discount}
                      onChange={(e) =>
                        onChangeInput(
                          e.target.value,
                          "cash_discount",
                          item.variety_id
                        )
                      }
                    />
                  </div>
                ))}

                <div className="update-btn">
                  <Button
                    type="primary"
                    onClick={() => onUpdate(["cash_discount"], 3)}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
         */}
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setBranch: (index: number) => dispatch(setSelectedBranch(index)),
    setCrop: (index: number) => dispatch(setSelectedCrop(index)),
    setRefetch: (status: boolean) => dispatch(setBranchParamsRefetch(status)),
  };
};
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  varieties: selectVarieties,
  user: selectUserData,
  crops: selectCrops,
  selectedCrop: selectSelectedCrop,
  selectedBranch: selectSelectedBranch,
  currentpage: selectCurrentPage,
  branchParams: selectBranchParams,
  cropParams: selectCropParams,
  viewAuctionPage: selectViewAuctionPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemParameters);
