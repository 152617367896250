/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./landing.styles.scss";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import socketIOClient from "socket.io-client";

import {
  setUserData,
  setUserLoggedInStatus,
} from "../../redux/user/user.actions";
import {
  selectAuthData,
  selectUserData,
} from "../../redux/user/user.selectors";

import {
  setBranches,
  setVarieties,
  setCrops,
  setSelectedCrop,
  setCurrentPage,
  setBypassEnabled,
  setBypassBD,
  setTC,
  setViewAuctionPage,
  setDefaultParams,
  setSelectedBranch,
  setCropParams,
  setBranchParams,
  setRebates,
  setHubs,
  setBranchParamsRefetch,
  setSlotPage,
  setDivisionList,
  setBagtypeList,
  setSelectedMultiBranch,
} from "../../redux/common/common.actions";

import {
  selectCurrentPage,
  selectRefetch,
  selectSelectedBranch,
  selectSelectedCrop,
  selectSelectedMultiBranch,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

import {
  Layout,
  Tabs,
  Spin,
  Avatar,
  Popover,
  message,
  Typography,
  Button,
  Form,
  Input,
  Modal,
} from "antd";
import {
  User,
  ArrayFormat,
  defaultparams,
  cropparams,
  branchparams,
  RebateArrayFormat,
} from "../../models";
import { changePwService } from "../../services/login-services";
import {
  checkAuctionExist,
  getBagTypeDetails,
  getDivisionDetails,
} from "../../services/auction-services";
import {
  getBranchesService,
  getVarietiesService,
  getCropsService,
  getTCService,
  getRebateService,
  getHubsService,
} from "../../services/common-services";

import Home from "../home/home.page";
import ViewAuctionLanding from "../view-auction-landing/view-auction-landing.page";
import ApprovalsLanding from "../approvals-landing/approvals-landing.page";
import ReportsLanding from "../reports-landing/reports-landing.page";
import CreateAuctionLanding from "../create-auction-landing/create-auction-landing.page";
import DefineSlots from "../define-slots/define-slots.page";
import SystemParameters from "../system-parameters/system-parameters.page";
import PreferencesLanding from "../preferences-landing/preferences-landing.page";
import MobileUpdation from "../mobile-apk-updatation/mobile-apk-updation.page";
import AdminTabs from "../../components/admin-tabs/admin-tabs.component";
import IntemediateSaudaLanding from "../intemediate-sauda-landing/intemediate-sauda-landing.page";

import ProfilePopover from "../../components/profile-popover/profile-popover.component";
import { ReactComponent as LogoITC } from "../../assets/itc_trade_next_logo.svg";

import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";
import packageJson from "../../../package.json";
import { dataConstants } from "../../constants/data-constants";
import { getSysParams } from "../../services/sys-params-services";
import { CopyrightOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import triggerSleepClient from "../../services/timeout-services";

const { Footer } = Layout;
const { TabPane } = Tabs;

const LandingPage: React.FC = (props: any) => {
  const [loader, setLoader] = useState(false);
  // const [loadData, setLoadData] = useState(false);
  const {
    setBranches,
    setBagtypeList,
    setDivisionList,
    setVarieties,
    setCrops,
    setSelectedCrop,
    setCurrentPage,
    setBypassEnabled,
    setBypassBD,
    refetch,
    setParams,
    setSelectedBranch,
    setSelectedMultiBranch,
    setRefetch,
    setSlotPageIndex,
    setUserData,
    //selectors
    selectedBranch,
    selectedMultiBranch,
    authData,
    selectedCrop,
    currentPage,
    selectedKey,
    userData,
    setViewAuctionPage,
    setCropBranchParams,
    setCropParams,
    setRebateList,
    setHubList,
  } = props;
  const [globalSocketObj, setGlobalSocket] = useState<any>({
    globalSocketData: null,
  });
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  // var globalSocketObj = {}
  useEffect(() => {
    sessionStorage.removeItem("createAuctionBDTMParams");
    // sessionStorage.removeItem("archiveTMParams");
    // sessionStorage.removeItem("openTMParams");
    sessionStorage.removeItem("upcomingTMParams");
    getBranchVarietyFn();
  }, []);

  useEffect(() => {
    const socket = socketIOClient(dataConstants.socketURL, {
      transports: ["websocket"],
      query: {
        Authorization: authData.access_token,
      },
    });
    socket.on("connect", () => {
      if (authData && authData.access_token && socket.connected) {
        setGlobalSocket({ globalSocketData: socket });
        // globalSocketObj = { globalSocket: socket };
      }
    });
  }, [authData.access_token]);

  useMemo(async () => {
    console.log("only crop function render");
    try {
      if (selectedCrop) {
        console.log("crop render");
        const cropParams = {
          crop_id: selectedCrop,
        };
        const cropParamsResponse = await getSysParams(cropParams);
        var tempCropParamsData: any = {};
        cropParamsResponse.data.data.map((item: any) => {
          Object.assign(tempCropParamsData, {
            [item.parameter_type]: item.parameter_data_value,
          });
          setCropParams(tempCropParamsData);
        });
        const varietiesResponse = await getVarietiesService(cropParams);
        const varietyData = varietiesResponse.data.data.map((item: any) => {
          return {
            id: item.variety_id,
            name: item.variety_name,
            maxPrice: item.max_price,
            minPrice: item.min_price,
          };
        });
        setVarieties(varietyData);
        const branchesResponse = await getBranchesService();
        let userBasedBranches = branchesResponse.data.data.filter((x: any) =>
          userData.user_territories.includes(x.territory_id)
        );
        const branchData = userBasedBranches.map((item: any) => {
          return {
            id: item.territory_id,
            name: item.territory_name,
          };
        });
        const defaultBranch = branchData.filter(
          (items) =>
            userData.active_territories.hasOwnProperty(selectedCrop) &&
            userData.active_territories[selectedCrop].includes(items.id)
        );
        setBranches(defaultBranch);
        setSelectedBranch(defaultBranch[0]?.id);
        setSelectedMultiBranch([defaultBranch[0]?.id]);
      }
    } catch (error: any) {
      message.error(
        error.response?.data?.message ||
          "Something went wrong, Please try again later!"
      );
    }
  }, [selectedCrop]);

  useMemo(async () => {
    try {
      if (selectedMultiBranch && currentPage === "6" && selectedKey === "60") {
        const hubListResponse = await getHubsService({
          branch_id:
            selectedMultiBranch.length > 0
              ? selectedMultiBranch
              : [selectedBranch],
        });
        const data = hubListResponse.data.data.map((item: any) => {
          return { id: item.territory_id, name: item.territory_name };
        });
        setHubList(data);
      } else if (selectedBranch) {
        const hubListResponse = await getHubsService({
          branch_id: selectedBranch,
        });
        const data = hubListResponse.data.data.map((item: any) => {
          return { id: item.territory_id, name: item.territory_name };
        });
        setHubList(data);
      }
    } catch (error: any) {
      message.error(
        error.response?.data?.message ||
          "Something went wrong, Please try again later!"
      );
    }
  }, [selectedBranch, selectedMultiBranch, currentPage, selectedKey]);

  useMemo(async () => {
    console.log("crop & branch function render");
    try {
      if (selectedCrop && selectedBranch) {
        console.log("branch render");
        const cropBranchBody = {
          crop_id: selectedCrop,
          branch_id: selectedBranch,
        };
        const cropBranchParamsResponse = await getSysParams(cropBranchBody);
        const rebateListResponse = await getRebateService(cropBranchBody);
        var tempCropbranchParamsData: any = {};
        cropBranchParamsResponse.data.data.map((item: any) => {
          Object.assign(tempCropbranchParamsData, {
            [item.parameter_type]: item.parameter_data_value,
          });
          setCropBranchParams(tempCropbranchParamsData);
        });
        setRebateList(rebateListResponse.data.data);
        setRefetch(false);
      }
    } catch (error: any) {
      message.error(
        error.response?.data?.message ||
          "Something went wrong, Please try again later!"
      );
    }
  }, [selectedBranch, selectedCrop, refetch]);

  const getBranchVarietyFn = useCallback(async () => {
    try {
      setLoader(true);
      const response = await getSysParams({});
      var tempData: any = {};
      response.data.data.map((item: any) => {
        Object.assign(tempData, {
          [item.parameter_type]: item.parameter_data_value,
        });
        setParams(tempData);
      });

      const cropResponse = await getCropsService();
      const cropsData = cropResponse.data.data
        .map((item: any) => {
          return {
            id: item.crop_id,
            name:
              item.crop_name.charAt(0).toUpperCase() + item.crop_name.slice(1),
          };
        })
        .filter((x: any) => userData.crops.includes(x.id));
      setCrops(cropsData);
      let cropIDSelected = sessionStorage.getItem("cropID");
      cropIDSelected && JSON.parse(cropIDSelected) !== 1 && setBypassBD(true);
      let tempCrop = cropsData.some(
        (item) => item.id === tempData.default_crop
      );
      const cropid = sessionStorage.getItem("cropID")
        ? cropIDSelected && JSON.parse(cropIDSelected)
        : tempCrop
        ? Number(tempData.default_crop)
        : cropsData[0]?.id;

      setSelectedCrop(
        selectedCrop
          ? selectedCrop
          : tempCrop
          ? Number(tempData.default_crop)
          : cropsData[0]?.id
      );

      const checkResponse = await checkAuctionExist({
        crop_id: cropid,
      });

      parseInt(checkResponse.data.data.auctions) === 1
        ? setBypassEnabled(false)
        : setBypassEnabled(true);

      const branchesResponse = await getBranchesService();
      let userBasedBranches = branchesResponse.data.data.filter((x: any) =>
        userData.user_territories.includes(x.territory_id)
      );
      const branchData = userBasedBranches.map((item: any) => {
        return {
          id: item.territory_id,
          name: item.territory_name,
        };
      });
      const defaultBranch = branchData.filter(
        (items) =>
          userData.active_territories.hasOwnProperty(cropid) &&
          userData.active_territories[cropid].includes(items.id)
      );
      setBranches(defaultBranch);
      const divisionResponse = await getDivisionDetails();
      const divisionList = divisionResponse.data.data.map((item: any) => {
        return {
          id: !isNaN(Number(item.parameter_data_value))
            ? Number(item.parameter_data_value)
            : item.parameter_data_value,
          name: item.parameter_display_value,
        };
      });
      setDivisionList(divisionList);
      const bagTypeResponse = await getBagTypeDetails();
      const bagTypeList = bagTypeResponse.data.data.map((item: any) => {
        return {
          id: !isNaN(Number(item.parameter_data_value))
            ? Number(item.parameter_data_value)
            : item.parameter_data_value,
          name: item.parameter_display_value,
        };
      });
      setBagtypeList(bagTypeList);
      setSelectedBranch(selectedBranch || defaultBranch[0]?.id);
      setSelectedMultiBranch([selectedBranch || defaultBranch[0]?.id]);
      // const tcResponse = await getTCService();
      // setTC(tcResponse.data.data);

      // const cropParams = {
      //   crop_id:
      //     (sessionStorage.getItem("cropID") === undefined ||
      //       sessionStorage.getItem("cropID") === null) &&
      //     selectedCrop === null
      //       ? Number(tempData.default_crop)
      //       : Number(sessionStorage.getItem("cropID"))
      //       ? Number(sessionStorage.getItem("cropID"))
      //       : Number(selectedCrop),
      // };

      // const varietiesResponse = await getVarietiesService(cropParams);
      // const varietyData = varietiesResponse.data.data.map((item: any) => {
      //   return {
      //     id: item.variety_id,
      //     name: item.variety_name,
      //     maxPrice: item.max_price,
      //     minPrice: item.min_price,
      //   };
      // });
      // setVarieties(varietyData);
      setLoader(false);

      sessionStorage.getItem("activePage") &&
        setCurrentPage(sessionStorage.getItem("activePage"));
      // if (currentPage === "7") {setCurrentPage("7");console.log("abc")}
      // else if (
      //   currentPage !== "3" &&
      //   (canUserAccess(userData, FeatureCodes.AUCTION_MAKER) ||
      //     canUserAccess(userData, FeatureCodes.AUCTION_CHECKER))
      // )
      //   setCurrentPage("2");
      // else setCurrentPage("3");
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }, []);
  const onchange = async (e: any) => {
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "old_password"
    ) {
      form.setFieldsValue({ old_password: e.target.value.replace(/\s/g, "") });
    }
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "new_password"
    ) {
      form.setFieldsValue({ new_password: e.target.value.replace(/\s/g, "") });
    }
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "confirm_password"
    ) {
      form.setFieldsValue({
        confirm_password: e.target.value.replace(/\s/g, ""),
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = async (values: {
    old_password: string;
    new_password: string;
    confirm_password: string;
  }) => {
    try {
      const dataToSend = {
        user_name: userData.username,
        old_password: values.old_password,
        new_password: values.new_password,
      };
      setLoading(true);
      const response = await changePwService(dataToSend);
      if (response.status === 200) {
        setModalVisible(false);
        form.resetFields();
        message.success(
          "Password changed successfully, Please relogin with new password!"
        );
        // setUserData({
        //   ...userData,
        //   is_first_time_login: false,
        //   password_expired: false,
        // });
        await triggerSleepClient.consentLogoutUser(history);
      } else {
        message.error("Cannot reset password..!");
      }
    } catch (error: any) {
      if (error.response?.data?.msg) {
        message.error(error.response?.data?.msg + " !!!");
      } else {
        message.error("Something went wrong...Please try again !!!");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (userData.is_first_time_login || userData.password_expired) {
      setModalVisible(true);
    }
  }, []);
  // const logo = <div className="sample-logo">A</div>;
  const logo = <LogoITC className="landingPage-itc-logo" />;

  const popoverContent = (
    <div>
      <ProfilePopover />
    </div>
  );

  const profile = userData.name && (
    <div className="avatar-container">
      <Popover
        overlayClassName="avatar-popover"
        content={popoverContent}
        placement="bottomRight"
        // title={userData.name}
        trigger="hover"
      >
        <Avatar
          style={{ backgroundColor: "#fdbc2c", verticalAlign: "middle" }}
          size="default"
        >
          {userData.name[0]}
        </Avatar>
      </Popover>
    </div>
  );

  // <div className="logout-button" onClick={logoutFn}>
  //   Logout
  // </div>
  return (
    <div className="landing-page">
      <Spin spinning={loader}>
        <Tabs
          activeKey={currentPage}
          tabBarExtraContent={{ left: logo, right: profile }}
          onChange={(activeKey) => {
            setCurrentPage(activeKey);
            switch (activeKey) {
              case "7":
                setViewAuctionPage(
                  sessionStorage.getItem("preferencesPage") || "70"
                );
                setSlotPageIndex(
                  sessionStorage.getItem("preferenceTabs") || "701"
                );
                break;
              case "6":
                setViewAuctionPage(
                  sessionStorage.getItem("reportPage") || "60"
                );
                break;
              case "4":
                setViewAuctionPage(sessionStorage.getItem("interPage") || "40");
                break;
              case "3":
                setViewAuctionPage(
                  sessionStorage.getItem("auctionPage") || "30"
                );
                break;
            }
            sessionStorage.setItem("activePage", activeKey);
          }}
        >
          <TabPane tab="Home" key="1">
            <Home />
          </TabPane>
          {canUserAccess(userData, FeatureCodes.AUCTION_MAKER) && (
            <TabPane tab="Create Auction" key="2">
              <CreateAuctionLanding {...globalSocketObj} />
            </TabPane>
          )}
          <TabPane tab="View Auction" key="3">
            <ViewAuctionLanding {...globalSocketObj} />
          </TabPane>
          {userData.role_id !== 5 && (
            <TabPane tab="Intermediate Sauda" key="4">
              <IntemediateSaudaLanding {...globalSocketObj} />
            </TabPane>
          )}
          {(canUserAccess(userData, FeatureCodes.PROCESS_APPROVALS) ||
            canUserAccess(userData, FeatureCodes.EXISTING_VENDOR_CHECKER) ||
            canUserAccess(userData, FeatureCodes.NEW_VENDOR_CHECKER) ||
            canUserAccess(userData, FeatureCodes.REFUND_APPROVAL) ||
            canUserAccess(userData, FeatureCodes.EXISTING_VENDOR_APPROVAL)) && (
            <TabPane tab="Vendor Approvals" key="5">
              <ApprovalsLanding {...globalSocketObj} />
            </TabPane>
          )}
          <TabPane tab="Reports" key="6">
            <ReportsLanding {...globalSocketObj} />
          </TabPane>
          {/* <TabPane tab="Auction Slots" key="4">
            <DefineSlots />
          </TabPane>
          <TabPane tab="Auction Preferences" key="5">
            <SystemParameters />
          </TabPane> */}
          <TabPane tab="Preferences" key="7">
            <PreferencesLanding />
          </TabPane>
          {userData.role_id === 8 && (
            <TabPane tab="Admin" key="8">
              <AdminTabs />
            </TabPane>
          )}
          {userData.role_id === 5 && (
            <TabPane tab="Mobile APK updation" key="9">
              <MobileUpdation />
            </TabPane>
          )}
        </Tabs>
        <Modal
          visible={modalVisible}
          closable={false}
          footer={false}
          onCancel={() => {
            form.resetFields();
            setModalVisible(false);
          }}
          maskClosable={false}
          className="forgot-pw-modal"
        >
          <div className="reset-pw">
            <div className="header">
              {userData.password_expired
                ? "Password has Expired. Please change your password."
                : "Change Password"}
            </div>
            <div className="login-form">
              <Form
                form={form}
                name="change_pw"
                initialValues={{ remember: false }}
                size="large"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onChange={onchange}
              >
                <Form.Item
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password !",
                    },
                  ]}
                  label="Current Password"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={"Current Password"}
                  />
                </Form.Item>
                <Form.Item
                  name="new_password"
                  dependencies={["old_password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input password !",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("old_password") !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The new password should not be same as current password !"
                        );
                      },
                    }),
                  ]}
                  label="New Password"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={"New Password"}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  dependencies={["new_password"]}
                  hasFeedback
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password !",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Confirm password does not match with new password !"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={"Confirm Password"}
                  />
                </Form.Item>

                <Form.Item>
                  <div className="reset-btn-container">
                    <Button
                      loading={loading}
                      type="primary"
                      size={"large"}
                      block
                      htmlType="submit"
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
        <Footer
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            pointerEvents: "none",
          }}
        >
          <Typography.Text strong>
            <CopyrightOutlined /> ITC Limited v{packageJson.version}
          </Typography.Text>
        </Footer>
      </Spin>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentPage: selectCurrentPage,
  selectedKey: selectViewAuctionPage,
  userData: selectUserData,
  selectedCrop: selectSelectedCrop,
  authData: selectAuthData,
  selectedBranch: selectSelectedBranch,
  selectedMultiBranch: selectSelectedMultiBranch,
  refetch: selectRefetch,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setUserLoggedInStatus: (status: boolean) =>
      dispatch(setUserLoggedInStatus(status)),
    setBranches: (branches: Array<ArrayFormat>) =>
      dispatch(setBranches(branches)),
    setBagtypeList: (bagtypes: Array<ArrayFormat>) =>
      dispatch(setBagtypeList(bagtypes)),
    setDivisionList: (divisions: Array<ArrayFormat>) =>
      dispatch(setDivisionList(divisions)),
    setVarieties: (varieties: Array<ArrayFormat>) =>
      dispatch(setVarieties(varieties)),
    setCrops: (crops: Array<ArrayFormat>) => dispatch(setCrops(crops)),
    setSelectedCrop: (cropId: number) => dispatch(setSelectedCrop(cropId)),
    setSelectedBranch: (branchId: number) =>
      dispatch(setSelectedBranch(branchId)),
    setSelectedMultiBranch: (branchId: number) =>
      dispatch(setSelectedMultiBranch(branchId)),
    setCurrentPage: (activeKey: string) => dispatch(setCurrentPage(activeKey)),
    setBypassEnabled: (status: boolean) => dispatch(setBypassEnabled(status)),
    setTC: (htmlText: string) => dispatch(setTC(htmlText)),
    setBypassBD: (status: boolean) => dispatch(setBypassBD(status)),
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
    setParams: (param: defaultparams) => dispatch(setDefaultParams(param)),
    setCropParams: (param: cropparams) => dispatch(setCropParams(param)),
    setCropBranchParams: (param: branchparams) =>
      dispatch(setBranchParams(param)),
    setRebateList: (rebates: Array<RebateArrayFormat>) =>
      dispatch(setRebates(rebates)),
    setHubList: (hubs: Array<ArrayFormat>) => dispatch(setHubs(hubs)),
    setRefetch: (status: boolean) => dispatch(setBranchParamsRefetch(status)),
    setSlotPageIndex: (activeKey: string) => dispatch(setSlotPage(activeKey)),
    setUserData: (userData: User) => dispatch(setUserData(userData)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
