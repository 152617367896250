/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./auction-approval.styles.scss";

import moment from "moment";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import { SyncOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
// import setInterval from 'timers/promises';
import { createStructuredSelector } from "reselect";

import {
  selectBagtypeList,
  selectBranchParams,
  selectBranches,
  selectCrops,
  selectCurrentPage,
  selectDefaultParams,
  selectDivisionList,
  selectFreezingTime,
  selectMinQty,
  selectRebates,
  selectSelectedBranch,
  selectSelectedCrop,
  selectVarieties,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";

// import { getSysParams } from "../../services/sys-params-services";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  approveAuction,
  cancelAuction,
  editAuction,
  getUpcomingAuction,
} from "../../services/auction-services";
import {
  getRebateMatrixService,
  getRebateService,
  // getRebateService,
} from "../../services/common-services";

import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";
// import { getCompanyParams } from "../../services/sys-params-services";
import {
  Table,
  message,
  Modal,
  Button,
  Select,
  Input,
  // Popover,
  Checkbox,
} from "antd";
// import { Dispatch } from "redux";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectAuthData,
  selectUserData,
} from "../../redux/user/user.selectors";
// import { setInterval } from "timers";

const { confirm } = Modal;
const { Option } = Select;

const editDefault = {
  qty_rqd: undefined,
  rebate_matrix_id: undefined,
  auction_id: undefined,
  bagType: undefined,
  maxPrice: undefined,
  division: undefined,
  variety: undefined,
};

const ApproveAuction: React.FC = (props: any) => {
  let ApproveAuctionsIntervalID = useRef<NodeJS.Timeout>();
  const {
    branches,
    selectedCropId,
    user,
    globalSocketData,
    crops,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setBranch,
    // auth,
    freezingTime,
    parametersData,
    selectedBranchId,
    minQuantity,
    rebates,
    divisions,
    bagType,
    varieties,
  } = props;

  // const [selectedCrop, setSelectedCrop] = useState(
  //   selectedCropId ? selectedCropId : crops[0]?.id
  // );
  const [upcomingData, setUpcomingData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [rebates, setRebates] = useState<any>();
  const [valueToEdit, setValueToEdit] = useState<any>(editDefault);
  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [modalconfirmation, setModalConfirmation] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();
  const [rebateList, setRebateList] = useState<any>([]);
  // const [localCropID, setLocalCropID] = useState<any>();
  // const [currentTime, setCurrentTime] = useState<any>(new Date());
  // const [minQuantity, setMinQuantity] = useState<any>([]);
  const [error, setError] = useState<String>("");
  let timer = useRef<NodeJS.Timeout>();
  let timeout = useRef<NodeJS.Timeout>();
  const TableColumnHeader = (props: any) => {
    const { title, subtitle } = props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontWeight: 400,
            color: "white",
            fontSize: "12px",
            textAlign: "left",
            marginLeft: "0px",
            justifyContent: "flex-start",
            float: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "8px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      sorter: (a, b) => a.id - b.id,
      children: [
        {
          dataIndex: "id",
          key: "id2",
          responsive: ["md"],
          width: 60,
          //   fixed:true,
        },
      ],
    },
    {
      title: "Hub",
      sorter: (a, b) => a.hub.localeCompare(b.hub),
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          responsive: ["md"],
          width: 100,
        },
      ],
    },
    {
      title: "Division",
      sorter: (a, b) => a.division - b.division,
      children: [
        {
          dataIndex: "division",
          key: "division2",
          width: 100,
          align: "center",
          responsive: ["md"],
          render: (value: number) => {
            return (
              <div>{value === 1 ? "ABD" : value === 2 ? "FBD" : "NCDEX"}</div>
            );
          },
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          width: 60,
          responsive: ["md"],
          render: (value: number) => {
            return (
              <div className="claim-container">
                {value === 1 ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Qty Reqd",
      children: [
        {
          title: "MT",
          dataIndex: "qtyReqd",
          key: "qtyReqd2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Max Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "maxPrice",
          key: "maxPrice2",
          responsive: ["md"],
        },
      ],
    },

    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudhaType",
          key: "saudhaType2",
          responsive: ["md"],
          render: (value: number) => {
            return <div>{value === 1 ? "Net basis" : "Gross basis"}</div>;
          },
        },
      ],
    },
    {
      title: "Role",
      children: [
        {
          dataIndex: "roleType",
          key: "Role",
          responsive: ["md", "lg"],
          render: (value: number) => {
            return (
              <div style={{ whiteSpace: "break-spaces" }}>
                {value === 1
                  ? "Broker / Trader"
                  : value === 2
                  ? "FPO"
                  : value === 3
                  ? "Broker/Trader & FPO"
                  : "Not Selected"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Start Time",
      children: [
        {
          dataIndex: "startTime",
          key: "startTime2",
          responsive: ["md"],
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "End Time",
      children: [
        {
          dataIndex: "endTime",
          key: "endtime2",
          responsive: ["md"],
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "Rebate Name",
      children: [
        {
          dataIndex: "rebateName",
          key: "rebateName2",
          width: 180,
          render: (value: number, row: any) => {
            return (
              <div
                className={"rebate-info-upcoming"}
                style={{ whiteSpace: "break-spaces" }}
              >
                {value}
                {value && (
                  <span
                    className="info"
                    onClick={() => {
                      getRebateMatrix(row.rebateId, selectedBranch);
                    }}
                  >
                    ?
                  </span>
                )}
              </div>
            );
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Material Code",
      children: [
        {
          dataIndex: "materialCode",
          key: "materialCode",
          width: 100,
          render: (value: number, row: any) => {
            return <div>{value}</div>;
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Brokerage",
      children: [
        {
          dataIndex: "brokerage",
          key: "brokerage",
          width: 80,
          render: (value: number, row: any) => {
            return <div>{value}</div>;
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Action",
      children: [
        {
          dataIndex: "action",
          key: "action2",
          responsive: ["md"],
          render: (value: boolean, rowData: any) => {
            return (
              <div>
                {/* style={{display:"flex",flexDirection:"row",width:"100%"}}> */}
                {/* <div style={{width: "58px",height: "21px",marginRight:"5px"}}
                className={`cancel-container ${
                  !canUserAccess(user, FeatureCodes.BRANCH_EDIT_ALL) &&
                  "disabled"
                }`}
                onClick={() => showPromiseapprove(rowData.key)}
              >
                Approve
              </div> */}
                <div
                  //   <div style={{width: "58px",height: "21px"}}
                  className="cancel-container"
                  // className={`cancel-container ${
                  //   !canUserAccess(user, FeatureCodes.AUCTION_CHECKER) &&
                  //   "disabled"
                  // }`}
                  onClick={() =>
                    showPromiseConfirm(rowData.key, rowData.startTime)
                  }
                >
                  Cancel
                </div>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "",
      children: [
        {
          dataIndex: "edit",
          key: "edit2",
          render: (value: boolean, rowData: any) => {
            return (
              <div
                className={`edit-container ${
                  canUserAccess(user, FeatureCodes.AUCTION_CHECKER) ||
                  canUserAccess(user, FeatureCodes.AUCTION_MAKER)
                    ? ""
                    : "disabled"
                }`}
                onClick={() => onEditClicked(rowData)}
              >
                Edit
              </div>
            );
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: (
        <div
          style={{
            fontSize: "8px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "13px",
            marginRight: "10px",
          }}
        >
          {(!canUserAccess(user, FeatureCodes.AUCTION_APPROVE_VIEW) ||
            canUserAccess(user, FeatureCodes.AUCTION_CHECKER)) && (
            <>
              <TableColumnHeader
                title="Approve"
                style={{ marginLeft: "0px" }}
              />
              <div
                style={{
                  display: "flex",

                  // paddingLeft: "6px",
                }}
              >
                <Checkbox
                  style={{ margin: "auto" }}
                  checked={
                    upcomingData &&
                    upcomingData.length !== 0 &&
                    (upcomingData && upcomingData.length) ===
                      (selectedData && selectedData.length)
                  }
                  disabled={
                    canUserAccess(user, FeatureCodes.AUCTION_CHECKER)
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    let count = 0;
                    // let data = setHubDataForTable();
                    const modifiedData =
                      upcomingData && upcomingData.length
                        ? upcomingData.map((item: any) => {
                            // if (item.approvalStatus === 2 && !item.vendorCode) {
                            // if (!item.disableRow) {
                            //   count++;
                            //   return {
                            //     ...item,
                            //     checked: e.target.checked,
                            //   };
                            // } else {
                            if (
                              moment(
                                moment(new Date(), "HH:mm:ss").add(
                                  freezingTime,
                                  "minutes"
                                ),
                                "HH:mm:ss"
                              ).isBefore(moment(item.startTime, "HH:mm:ss"))
                            ) {
                              return item.id;
                            }
                          })
                        : [];
                    // console.log(
                    //   "Key check --ALLLLLLLLL---> modifiedData",
                    //   modifiedData,
                    //   "-------------->",
                    //   upcomingData.filter((obj: any) => obj.checked === true)
                    //     .length ===
                    //     (upcomingData &&
                    //       upcomingData.length -
                    //         upcomingData.filter(
                    //           (obj: any) => obj.disableRow === true
                    //         ).length)
                    // );
                    if (e.target.checked === true) {
                      setSelectedData(modifiedData);
                    } else {
                      setSelectedData([]);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      ),
      key: "action",
      dataIndex: "action",
      render: (value: number, row: any) => {
        return (
          <div className="approval-table-checkbox">
            {(!canUserAccess(user, FeatureCodes.AUCTION_APPROVE_VIEW) ||
              canUserAccess(user, FeatureCodes.AUCTION_CHECKER)) && (
              <Checkbox
                checked={
                  Array.isArray(selectedData) && selectedData.includes(row.id)
                }
                disabled={
                  canUserAccess(user, FeatureCodes.AUCTION_CHECKER)
                    ? false
                    : true
                }
                onChange={(e) => {
                  if (
                    moment(
                      moment(new Date(), "HH:mm:ss").add(
                        freezingTime,
                        "minutes"
                      ),
                      "HH:mm:ss"
                    ).isAfter(moment(row.startTime, "HH:mm:ss"))
                  ) {
                    message.error(
                      "cannot approve the auction during freezing period"
                    );
                  } else {
                    // let data = setHubDataForTable();
                    /* let count = 0;
                    const modifiedData = upcomingData.map(
                      (item: any, index: any) => {
                        // console.log(
                        //   "Key check --OUTTTTTTTTTT--->",
                        //   row,
                        //   " row ",
                        //   item.id,
                        //   " item.key",
                        //   item.id === row.id,
                        //   "row.saudhaId",
                        //   row.id
                        // );

                        if (item.id === row.id) {
                          // console.log(
                          //   "Key check ----->",
                          //   item.id,
                          //   " item.saudhaId",
                          //   item.id === row.id,
                          //   "row.saudhaId",
                          //   row.id
                          // );
                          item.checked && count++;
                          return {
                            ...item,
                            checked: e.target.checked,
                          };
                        } else {
                          return { ...item };
                        }
                      }
                    ); */
                    // setUpcomingData(modifiedData);
                    if (e.target.checked === true) {
                      setSelectedData([...selectedData, row.id]);
                    } else {
                      const temp = selectedData.filter(
                        (item) => item !== row.id
                      );
                      setSelectedData(temp);
                    }
                  }
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (modalconfirmation) {
      timer.current = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      timeout.current = setTimeout(() => {
        onCheckBoxChange(upcomingData, "multi");
        clearInterval(timer.current as NodeJS.Timeout);
        setModalConfirmation(false);
      }, 10000);
    } else {
      clearTimeout(timeout.current as NodeJS.Timeout);
      clearInterval(timer.current as NodeJS.Timeout);
    }
    return () => clearInterval(timer.current as NodeJS.Timeout);
  }, [modalconfirmation]);

  useEffect(() => {
    if (currentpage === "3" && viewAuctionPage === "30" && globalSocketData) {
      ApproveAuctionsIntervalID.current &&
        clearInterval(ApproveAuctionsIntervalID.current);
      // onBranchChange(selectedBranch, []);
      // getParamsMinQty({ branch_id: selectedBranch, crop_id: selectedCropId });
      ApproveAuctionsDataSocketEmition();
      ApproveAuctionsIntervalID.current = setInterval(() => {
        ApproveAuctionsDataSocketEmition();
      }, 10000);
    }
    return () => {
      ApproveAuctionsIntervalID.current &&
        clearInterval(ApproveAuctionsIntervalID.current);
    };
  }, [
    currentpage,
    viewAuctionPage,
    globalSocketData,
    selectedBranch,
    selectedCropId,
  ]);

  // useEffect(() => {
  //   onBranchChange(branches[branchIndex].id, []);
  //   getFreezeTime();
  //   getParams();
  // }, [branchIndex]);

  // const getParams = async () => {
  //   var tempData: any = {};
  //   const sysResponse = await getSysParams(tempData);
  //   sysResponse.data.data.map((item: any) => {
  //     Object.assign(tempData, {
  //       [item.parameter_type]: item.parameter_data_value,
  //     });
  //   });
  //   setParametersData({ ...tempData });
  // };

  // const getParamsMinQty = async (body) => {
  //   var tempData: any = {};
  //   const sysResponse = await getSysParams(body);
  //   sysResponse.data.data.map((item: any) => {
  //     Object.assign(tempData, {
  //       [item.parameter_type]: item.parameter_data_value,
  //     });
  //   });
  //   setMinQuantity({ ...tempData });
  // };

  // useEffect(() => {
  //   setLocalCropID(selectedCropId);
  //   sessionStorage.setItem(
  //     "upcomingTMParamsApproval",
  //     JSON.stringify({
  //       crop_id: selectedCropId,
  //       branch_id: selectedBranch,
  //       approval_status: 0,
  //     })
  //   );
  // }, [selectedCropId, selectedBranch]);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  const ApproveAuctionsDataSocketEmition = () => {
    // let socketCheck =
    // globalSocketData !== undefined ? globalSocketData ;
    // let params = sessionStorage.getItem("upcomingTMParamsApproval");
    // params = params && JSON.parse(params);
    // console.log(params);
    if (globalSocketData) {
      globalSocketData.emit("tm_approval_auction_event", {
        crop_id: selectedCropId,
        branch_id: selectedBranch,
        approval_status: 0,
      });
      // setIsLoading(true);
      globalSocketData.on("tm_approval_auctions", (data: any) => {
        const modifiedData = data.map((item: any) => {
          return {
            key: item.auction_id,
            id: item.auction_id,
            hub: item.hub_name,
            division: item.division,
            claim: item.claim_type,
            variety: item.variety_name,
            qtyReqd: item.qty_rqd,
            maxPrice: item.max_price,
            saudhaType: item.saudha_type,
            roleType: item.role_mapping,
            rebateName: item.rebate_name,
            startTime: item.start_time,
            endTime: item.end_time,
            rebateId: item.rebate_id,
            checked: false,
            bagType: item.bag_type,
            minPrice: item.min_price,
            materialCode: item.material_code,
            brokerage: item.brokerage,
          };
        });
        setUpcomingData(modifiedData);
        setIsLoading(false);
      });
    }
  };

  // useEffect(() => {
  //   if (auth && auth.access_token && globalSocketData) {
  //     clearInterval(ApproveAuctionsIntervalID);
  //     ApproveAuctionsDataSocketEmition(globalSocketData);
  //     ApproveAuctionsIntervalID = setInterval(() => {
  //       ApproveAuctionsDataSocketEmition(globalSocketData);
  //     }, 30000);
  //   }
  // }, [auth, globalSocketData, selectedCropId]);

  const onEditClicked = async (data: any) => {
    // getParamsMinQty({ branch_id: selectedBranch, crop_id: selectedCropId });
    if (
      moment(
        moment(new Date(), "HH:mm:ss").add(freezingTime, "minutes"),
        "HH:mm:ss"
      ).isAfter(moment(data.startTime, "HH:mm:ss"))
    ) {
      message.error("cannot edit the auction during freezing period");
    } else {
      setValueToEdit({
        qty_rqd: data.qtyReqd,
        rebate_matrix_id: data.rebateId,
        auction_id: data.id,
        claim: data.claim,
        maxPrice: data.maxPrice,
        division: data.division,
        bagType: data.bagType,
        variety: data.variety,
      });

      const rebateParams = {
        crop_id: selectedCropId,
        branch_id: selectedBranch,
        variety_id: varieties.filter(
          (item: any) => item.name === data.variety
        )[0].id,
        division: divisions.filter((item: any) => item.id === data.division)[0]
          .name,
      };
      getVarietyData(rebateParams);
      setIsEdit(true);
    }
  };
  const getVarietyData = async (params: any) => {
    try {
      const rebateListResponse = await getRebateService(params);
      if (rebateListResponse.data.status) {
        setRebateList(rebateListResponse.data.data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  // const getUpcomingData = async (branchId: number) => {
  //   setIsLoading(true);
  //   const params = {
  //     crop_id: localCropID,
  //     branch_id: branchId,
  //     approval_status: 0,
  //   };
  //   try {
  //     const response = await getUpcomingAuction(params);
  //     if (response.data.status) {
  //       const data = response.data.data.map((item: any) => {
  //         return {
  //           key: item.auction_id,
  //           id: item.auction_id,
  //           hub: item.hub_name,
  //           division: item.division,
  //           claim: item.claim_type,
  //           variety: item.variety_name,
  //           qtyReqd: item.qty_rqd,
  //           maxPrice: item.max_price,
  //           saudhaType: item.saudha_type,
  //           roleType: item.role_mapping,
  //           rebateName: item.rebate_name,
  //           startTime: item.start_time,
  //           endTime: item.end_time,
  //           rebateId: item.rebate_id,
  //           checked: false,
  //         };
  //       });
  //       setUpcomingData(data);
  //     } else {
  //       message.error("Something went wrong :(", 1);
  //       setUpcomingData([]);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     message.error("Something went wrong :(", 1);
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // const onCropChange = () => {
  //   console.log("YEt to implement");
  // };

  // const onBranchChange = async (value: string | number | any, option: any) => {
  //   sessionStorage.setItem(
  //     "upcomingTMParamsApproval",
  //     JSON.stringify({
  //       crop_id: localCropID,
  //       branch_id: value,
  //       approval_status: 0,
  //     })
  //   );
  //   setBranch(value);
  //   // await getRebate(value);
  //   // getParamsMinQty({ branch_id: value, crop_id: selectedCropId });
  //   // await getUpcomingData(value);
  //   ApproveAuctionsDataSocketEmition(globalSocketData);
  // };

  const cancelAuctionFn = async (auctionId: number, startTime: any) => {
    const dataToSend = {
      auction_id: auctionId,
    };

    if (
      moment(
        moment(new Date(), "HH:mm:ss").add(freezingTime, "minutes"),
        "HH:mm:ss"
      ).isAfter(moment(startTime, "HH:mm:ss"))
    ) {
      message.error("cannot cancel the auction during freezing period");
    } else {
      try {
        const response = await cancelAuction(dataToSend);
        if (response.data.status) {
          message.success("Auction cancelled successfully...!");
          // await getUpcomingData(selectedBranch);
          ApproveAuctionsDataSocketEmition();
        } else message.warning("Cannot cancel auction...!");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const approveAuctionFn = async (auctionId: number) => {
  //   const dataToSend = {
  //     auction_id_list: [auctionId],
  //   };

  //   try {
  //     const response = await approveAuction(dataToSend);
  //     if (response.data.status) {
  //       message.success(response.data.message);
  //       // await getUpcomingData(selectedBranch);
  //       ApproveAuctionsDataSocketEmition();
  //     } else {
  //       console.log("error while submit");
  //       message.warning("Cannot approve auction...!");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function showPromiseConfirm(auctionId: number, startTime: any) {
    confirm({
      title: "Cancel Auction",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel the auction ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        cancelAuctionFn(auctionId, startTime);
      },
      onCancel() {},
    });
  }

  // function showPromiseapprove(auctionId: number) {
  //   confirm({
  //     title: "Approve Auction",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "Are you sure you want to approve the auction ?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk() {
  //       approveAuctionFn(auctionId);
  //     },
  //     onCancel() {},
  //   });
  // }

  // const getRebate = async (branchId: number) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getRebateService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     setRebates(response.data.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  //   // setIsHubLoading(false);
  // };

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined
  ) => {
    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: selectedCropId,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setRebateModalVisible(true);
        setRebateTableData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rebateColumns = [
    {
      title: "Head",
      dataIndex: "head",
      key: "head",
      render(_: any, row: any) {
        return {
          children: row.head,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },
    {
      title: "Slab",
      dataIndex: "slab",
      key: "slab",
    },
    {
      title: "Rebate",
      dataIndex: "rate_display",
      key: "rate_display",
    },
  ];
  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };

  const updateAuction = async () => {
    try {
      // if (Number(valueToEdit.qty_rqd) >= Number(minQuantity)) {
      let matrix_id = {};
      if (valueToEdit.rebate_matrix_id) {
        matrix_id = { rebate_matrix_id: valueToEdit.rebate_matrix_id };
      }
      const maxPrice = varieties.filter(
        (item: any) => item.name === valueToEdit.variety
      )[0].maxPrice;
      const minPrice = varieties.filter(
        (item: any) => item.name === valueToEdit.variety
      )[0].minPrice;
      if (
        Number(valueToEdit.maxPrice) <= minPrice ||
        Number(valueToEdit.maxPrice) >= maxPrice
      ) {
        message.warning(
          `Max price should be between the range ${minPrice} (₹/MT) to ${maxPrice} (₹/MT)...`
        );
        return false;
      }
      const param = {
        qty_rqd:
          valueToEdit.qty_rqd !== null ||
          valueToEdit.qty_rqd !== undefined ||
          valueToEdit.qty_rqd !== ""
            ? Number(valueToEdit.qty_rqd)
            : 0,
        ...matrix_id,
        auction_id: valueToEdit.auction_id,
        max_price: Number(valueToEdit.maxPrice),
        bag_type: valueToEdit.bagType,
        division: valueToEdit.division,
      };
      const response = await editAuction(param);
      if (response.data.status) {
        message.success("Updated auction successfully..!");
        // await getUpcomingData(selectedBranch);
        ApproveAuctionsDataSocketEmition();
        setIsEdit(false);
        setError("");
      } else message.error("Could not update auction..!");
      /* } else {
        message.error(
          `Quantity can not be lower than minimum truckable quantity : ${minQuantity}`
        );
      } */
    } catch (error: any) {
      console.log(error);
      message.error(
        error.response.data.message || "Could not update auction..!"
      );
    }
  };

  // const getFreezeTime = async () => {
  //   // const params = {
  //   //   branch_id: branchId,
  //   //   // parameter_type: "auction_closing_duration",
  //   // };
  //   try {
  //     const response = await getCompanyParams({});
  //     if (response.data.status) {
  //       const time = moment(
  //         response.data.data[2].parameter_data_value,
  //         "HH:mm:ss"
  //       );
  //       setFreezingTime(response.data.data[2].parameter_data_value);
  //     } else {
  //       console.log("Could not fetch Freeze time !");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onCheckBoxChange = async (dataToPut: any, key: any) => {
    setIsLoading(true);
    // if (key === "multi") {
    dataToPut = dataToPut
      .map((element: any) => {
        if (selectedData.includes(element.id)) return element;
        else return undefined;
      })
      .filter((item: any) => item !== undefined);
    // }

    // if (validate(dataToPut)) {
    const list = dataToPut.map((eachRow: any) => {
      return eachRow.id;
    });

    if (list !== undefined && list !== null && list !== "" && list.length > 0) {
      const reqBody = {
        auction_id_list: list,
      };

      try {
        const response = await approveAuction(reqBody);
        if (response.data.status) {
          message.success(response.data.message);
          // await getUpcomingData(selectedBranch);
          setSelectedData([]);
          ApproveAuctionsDataSocketEmition();
        } else {
          console.log("error while submit");
          message.warning("Cannot approve auction...!");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      message.error("Select atleast one row.", 2);
    }
    setIsLoading(false);
  };

  return (
    <div className="upcoming-auction">
      <Modal
        className="edit-modal"
        visible={isEdit}
        onCancel={() => {
          setIsEdit(false);
          setValueToEdit(editDefault);
          setError("");
        }}
        footer={false}
        maskClosable={false}
      >
        <div className="header">Edit Auction</div>
        <div className="form-container">
          <div className={`form-items`}>
            <div className="label">Quantity (MT)</div>
            <Input
              value={valueToEdit.qty_rqd}
              type="number"
              placeholder="Slot Qty"
              onChange={(e) => {
                /* if (Number(e.target.value) < minQuantity) {
                  setError(`Minimum Truckable quantity is ${minQuantity} MT`);
                } else {
                  setError("");
                } */
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  qty_rqd: e.target.value,
                }));
              }}
            />
            <p style={{ color: "red" }} className="label">
              {error}
            </p>
          </div>

          {valueToEdit.claim === 1 && (
            <div className={`form-items`}>
              <div className="label help">
                Rebate Name {/* {rebateId && ( */}
                <div
                  className="info"
                  onClick={() => {
                    getRebateMatrix(
                      valueToEdit.rebate_matrix_id,
                      selectedBranch
                    );
                  }}
                >
                  ?
                </div>
                {/* )} */}
              </div>

              <Select
                placeholder="Rebate Name"
                showSearch
                filterOption={(inputValue, option: any) =>
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                value={valueToEdit.rebate_matrix_id}
                onChange={(value) =>
                  setValueToEdit((prevState: any) => ({
                    ...prevState,
                    rebate_matrix_id: value,
                  }))
                }
              >
                {rebateList &&
                  rebateList.map((item: any) => (
                    <Option
                      value={item.rebate_id}
                      id={item.rebate_id}
                      key={item.rebate_id}
                    >
                      {item.rebate_name}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
          <div className={`form-items`}>
            <div className="label">Max Price (₹/MT)</div>
            <Input
              value={valueToEdit.maxPrice}
              type="number"
              placeholder="Max Price"
              onChange={(e) => {
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  maxPrice: e.target.value,
                }));
              }}
            />
          </div>
          <div className={`form-items`}>
            <div className="label help">Division</div>
            <Select
              placeholder="Division"
              showSearch
              filterOption={(inputValue, option: any) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              value={valueToEdit.division}
              onChange={(value) =>
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  division: value,
                }))
              }
            >
              {divisions &&
                divisions.map((item: any) => (
                  <Option value={item.id} id={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className={`form-items`}>
            <div className="label help">Bag Type</div>
            <Select
              placeholder="Bag Type"
              showSearch
              filterOption={(inputValue, option: any) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              value={valueToEdit.bagType}
              onChange={(value) =>
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  bagType: value,
                }))
              }
            >
              {bagType &&
                bagType.map((item: any) => (
                  <Option value={item.id} id={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="update-btn-container">
          <Button onClick={updateAuction}>Update</Button>
        </div>
      </Modal>

      <Modal
        className="rebate-modal"
        visible={rebateModalVisible}
        onCancel={() => setRebateModalVisible(false)}
        footer={false}
        zIndex={1001}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={rebateColumns}
            // dataSource={tableData}
            dataSource={createNewArr(rebateTableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>
      <Modal
        className="temporal-modal"
        title="Alert !"
        visible={modalconfirmation}
        closable={false}
        maskClosable={false}
        okText={`Confirm ${countdown}s`}
        cancelText="Go Back"
        style={{ textAlign: "center" }}
        maskStyle={{ opacity: "1" }}
        centered
        okButtonProps={{
          style: { backgroundColor: "#20346a", border: 0 },
        }}
        cancelButtonProps={{
          danger: true,
          type: "ghost",
        }}
        onOk={() => {
          (async () => {
            setModalConfirmation(false);
            onCheckBoxChange(upcomingData, "multi");
          })();
        }}
        onCancel={() => setModalConfirmation(false)}
      >
        <div style={{ color: "black" }}>
          Are you sure that the rebate versions selected for auction creation
          are the latest available in SAP?
        </div>
      </Modal>
      <div className="filter-container">
        <CustomFilter
          type="single-select"
          value={selectedBranchId}
          options={branches}
          onChange={(value: any) => setBranch(value)}
          head="ITC Branch Office"
          returnId
        />
        {/* <CustomFilter
          type="single-select"
          value={selectedCrop}
          defaultValue={selectedCrop}
          options={crops}
          disabled={true}
          onChange={onCropChange}
          head="Selected Crop"
          returnId
        /> */}
        <div className="selected-wheat">
          <span className="selected-wheat-label">Selected Crop</span> <br />
          <span className="selected-wheat-value">
            <b>
              {crops &&
                crops.length &&
                crops.find(
                  (item: { id: number; name: string }) =>
                    item.id == selectedCropId
                ).name}
            </b>
          </span>
        </div>
        {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="Type"
          returnId
        /> */}
        <Button
          className="refresh"
          icon={<SyncOutlined spin={isLoading} />}
          onClick={
            () => ApproveAuctionsDataSocketEmition()
            // getUpcomingData(selectedBranch)
          }
        >
          Refresh
        </Button>
      </div>
      <div className="label noteText">
        <b>
          {parametersData !== undefined && parametersData !== null
            ? ` NOTE: Auction cannot be Edited or Approved ${parametersData.auction_freeze_period} minute(s) before the start time.`
            : ""}
        </b>
      </div>
      {/* {upcomingData.length === 0 && (
        <div className="time-data-container">
          <div>No Upcoming Auctions </div>
          <div></div>
        </div>
      )} */}

      <div className="upcoming-table-container">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={upcomingData}
          pagination={false}
          scroll={{ y: window.innerHeight - 355 }}
        />
        {upcomingData.length !== 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div>
              {(!canUserAccess(user, FeatureCodes.AUCTION_APPROVE_VIEW) ||
                canUserAccess(user, FeatureCodes.AUCTION_CHECKER)) && (
                <Button
                  className={
                    canUserAccess(user, FeatureCodes.AUCTION_CHECKER)
                      ? "submit-btn"
                      : "submit-btn disabled"
                  }
                  onClick={() => {
                    // onCheckBoxChange(upcomingData, "multi");
                    setModalConfirmation(true);
                    setCountdown(10);
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     setBranchIndex: (index: number) => dispatch(setBranchIndex(index)),
//   };
// };
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  crops: selectCrops,
  selectedCropId: selectSelectedCrop,
  selectedBranchId: selectSelectedBranch,
  user: selectUserData,
  auth: selectAuthData,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  freezingTime: selectFreezingTime,
  parametersData: selectDefaultParams,
  minQuantity: selectMinQty,
  rebates: selectRebates,
  bagType: selectBagtypeList,
  divisions: selectDivisionList,
  varieties: selectVarieties,
});

export default connect(mapStateToProps)(ApproveAuction);
